import React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { Editor, EditorState, SyntheticEvent } from 'react-draft-wysiwyg'
import '../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { CUSTOM_TOOLBAR_SETTINGS } from '../utils/htmlEditor'
import { Colors } from '../theme/colors'
import { Typography } from '@material-ui/core'
import HelpTip from './HelpTip'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      margin: '50px 0 10px 0',
    },
    title: {},
    infoText: {
      marginBottom: '12px',
    },
    editorWrapper: {},
    editor: {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
      border: `1px solid ${Colors.gray}`,
      borderBottomLeftRadius: 3,
      borderBottomRightRadius: 3,
      fontFamily: theme.typography.fontFamily,
      fontSize: '14px',
      padding: '0 10px',
      minHeight: 100,
      maxHeight: 180,
    },
    toolbar: {
      backgroundColor: Colors.white,
      border: `1px solid ${Colors.gray}`,
      borderRadius: 0,
      borderTopLeftRadius: 3,
      borderTopRightRadius: 3,
      borderBottomWidth: 0,
      fontFamily: theme.typography.fontFamily,
      marginBottom: 0,
    },
  })
)

interface Props {
  title: string
  titleSize?: 'h4' | 'h5' | 'h6'
  infoText?: string
  state: EditorState | undefined
  onStateChange: (editorState: EditorState) => void
  onFocusOut?: () => void
  helpTipKey?: string
}

const HtmlEditor: React.FC<Props> = ({ title, titleSize, infoText, state, onStateChange, onFocusOut, helpTipKey }) => {
  const c = useStyles()

  const handleOnBlur = (e: SyntheticEvent) => {
    if (onFocusOut) {
      onFocusOut()
    }
  }

  // TODO: Use i18n for localization:
  const localization = {
    locale: 'en',
    translations: {
      'components.controls.blocktype.h1': 'Heading 1',
      'components.controls.blocktype.h2': 'Heading 2',
    },
  }

  return (
    <div className={c.container}>
      <Typography variant={titleSize} className={c.title}>
        {title}
        {helpTipKey && <HelpTip helpTextKey={helpTipKey} />}
      </Typography>
      <Typography variant={'body2'} className={c.infoText}>
        {infoText}
      </Typography>
      <Editor
        wrapperClassName={c.editorWrapper}
        editorClassName={c.editor}
        toolbarClassName={c.toolbar}
        editorState={state}
        onEditorStateChange={onStateChange}
        toolbar={CUSTOM_TOOLBAR_SETTINGS}
        stripPastedStyles
        onBlur={handleOnBlur}
        localization={localization}
      />
    </div>
  )
}

export default HtmlEditor
