import React, { useEffect, useState, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import Chart from 'react-apexcharts'
import LoadingOverlay from '../components/Analytics/LoadingOverlay'
import styled from 'styled-components'

import { getEmbeddedChartDataByUuid } from '../services/publicApi'

import { EMBEDDED_CHART_TYPES, EMBEDDED_CHART_OPTIONS } from '../utils/embeddedChartConstants'

const ChartContainer = styled.div`
  display: inline-flex;
  flex-direction: row;

  .wrapper {
    width: 100%;
    margin: 1rem;
    background-color: #fff;
    border: 2px solid #ccc;
  }

  .title {
    display: block;
    text-align: center;
    padding-top: 1rem;
    font-weight: bold;
    color: #000;
  }
`
const EmbeddedChart: React.FC = () => {
  const { uuid } = useParams<{ uuid: string }>()
  const [embeddedChart, setEmbeddedChart] = useState<EmbeddedChart>()
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState('')

  const getChartData = useCallback(async (uuid: string) => {
    setLoading(true)
    setMessage('')
    const res: any = await getEmbeddedChartDataByUuid(uuid)

    if (!res.data || res.status === 400) {
      setMessage('Chart not found')
      setLoading(false)
      return
    }

    if (res.status !== 200) {
      setMessage('Error fetching chart')
      setLoading(false)
      return
    }

    setEmbeddedChart(res.data)
    setLoading(false)
  }, [])

  useEffect(() => {
    getChartData(uuid)
  }, [uuid, getChartData])

  return (
    <ChartContainer>
      {embeddedChart &&
        embeddedChart.settings.map((settings: EmbeddedChartSettings, index: number) => (
          <div className='wrapper' key={index}>
            <span className='title'>{settings.chartName}</span>
            <Chart
              options={EMBEDDED_CHART_OPTIONS[settings.chartType]}
              series={settings.dataSet}
              width='100%'
              type={EMBEDDED_CHART_TYPES[settings.chartType]}
              height='auto'
            />
          </div>
        ))}
      {message ? <span>{message}</span> : null}
      <LoadingOverlay visible={loading} />
    </ChartContainer>
  )
}

export default EmbeddedChart
