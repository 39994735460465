import { call, put, select } from 'redux-saga/effects'
import { AnyAction } from 'redux'
import PresentationsActions from '../redux/PresentationsRedux'
import PresentationActions from '../redux/PresentationRedux'
import ErrorActions from '../redux/ErrorRedux'
import {
  composeError,
  mapTranslationsToApi,
  mapAssetToApi,
  mapAssetsToApi,
  mapAssetFromApi,
  mapTranslationsFromApi,
  mapAssetsFromApi,
  mapAssetToAssetWithUrl,
  mapCapacityToApi,
  mapCapacityFromApi,
  mapLinkToApiAsset,
  mapLinkFromApiAsset,
  mapPermissionsFromApi,
} from '../utils/transforms'
import { DEFAULT_LANGUAGE } from '../utils/constants'

const transformPresentationFromApi = (data: any) => {
  const presentation: FullPresentation = {
    id: data.id,
    licenseId: data.licenseId,
    licenseName: data.licenseName,
    name: data.name,
    status: data.status,
    type: data.type,
    createdAt: data.createdAt,
    updatedAt: data.updatedAt,
    supportedLanguages: data.languages,
    defaultLanguage: data.languages.find((lang: any) => lang.isDefault)?.code,
    introTexts: mapTranslationsFromApi('introText', data.translations),
    floorplan: mapAssetsFromApi('floorplan', data.assets),
    capacityChart: mapAssetsFromApi('capacityChart', data.assets),
    mapMarker: mapAssetFromApi('mapMarker', data.assets),
    requestForProposal: mapTranslationsFromApi('requestForProposal', data.translations),
    videoLink: mapLinkFromApiAsset('videoLink', data.assets),
    hygieneGuidelines: mapAssetFromApi('hygieneGuidelines', data.assets),
    licenseImages: {
      count: data.assetCount.license,
      limit: data.assetCount.licenseLimit,
    },
    categories: data.categories
      .sort((a: any, b: any) => a.index - b.index || 0)
      .map((cat: any) => ({
        id: cat.id,
        index: cat.index,
        type: cat.type,
        name: mapTranslationsFromApi('name', cat.translations),
        description: mapTranslationsFromApi('description', cat.translations),
        capacity: mapCapacityFromApi(cat.capacity),
        images: mapAssetsFromApi('image', cat.assets),
        panoramaImages: mapAssetsFromApi('panorama', cat.assets),
      })),
    permissions: mapPermissionsFromApi(data.permissions),
  }
  return presentation
}

// TODO: Fix type!
export function* sendToVisualizerCms(api: any, action: AnyAction): any {
  const { token } = yield select((state) => state.user)
  const { payload, onSuccess } = action

  const response = yield call(api.sendToVisualizerCms, payload?.id, payload?.termsAccepted, token)

  if (response.ok) {
    yield put(PresentationActions.sendToVisualizerCmsFinished())
    yield put(ErrorActions.setSuccess('presentation_sent'))
    onSuccess && onSuccess()
  } else {
    const error = composeError(response, 'send_to_cms_error')
    yield put(ErrorActions.setError(error))
    yield put(PresentationActions.sendToVisualizerCmsFinished())
  }
}

/**
 * apiReadyPresentation => apiReadyPresentation
 *
 * Converts assets in an API-ready presentation JSON to assets that have urls
 * (API-ready presentation has all assets in one array for each category and presentation).
 * Returns the same, mutated presentation object with updated asset objects.
 * - Iterate over assets in `presentation.assets`
 * - Iterate over assets in `presentation.categories[].assets` for all category items in `presentation.categories[]`
 * -- If an asset has an associated File object, upload it and return an Asset object with an appropriate `url`
 * -- Otherwise return the asset as is
 *
 * MUTATES THE INPUT PRESENTATION!
 */
const uploadNewAssets = (api: any, token: string) => async (presentationInApiFormat: any) => {
  const assetHasUrl = (asset: any) => !!asset?.url

  const uploadPresentationAssets = presentationInApiFormat.assets.map(mapAssetToAssetWithUrl(api, token))

  const updatedAssets = await Promise.all(uploadPresentationAssets)
  presentationInApiFormat.assets = updatedAssets.filter(assetHasUrl)

  const categoriesWithUploadedAssets = presentationInApiFormat.categories.map(async (category: any) => {
    const uploadAssets = category.assets.map(mapAssetToAssetWithUrl(api, token))
    const updatedCategoryAssets = await Promise.all(uploadAssets)
    return {
      ...category,
      assets: updatedCategoryAssets.filter(assetHasUrl),
    }
  })

  presentationInApiFormat.categories = await Promise.all(categoriesWithUploadedAssets)
  return presentationInApiFormat
}

// TODO: Fix type!
export function* getPresentations(api: any): any {
  const { token } = yield select((state) => state.user)
  const response = yield call(api.getPresentations, token)

  if (response.ok) {
    const presentations: Presentation[] = response.data.map(
      ({ id, licenseId, licenseName, name, translations, status, createdAt, updatedAt, type, permissions }: any) => ({
        id,
        licenseId,
        licenseName,
        name,
        status,
        createdAt,
        updatedAt,
        type,
        permissions: permissions ? permissions.map((p: any) => p.name) : [],
        defaultIntroText: translations?.find(
          (translation: any) => translation.context === 'introText' && translation.lang === DEFAULT_LANGUAGE
        )?.text,
      })
    )
    yield put(PresentationsActions.getPresentationsSuccess(presentations))
  } else {
    const error = composeError(response, 'get_presentations_error')
    yield put(ErrorActions.setError(error))
    yield put(PresentationsActions.getPresentationsFailure())
  }
}

// TODO: Fix type!
export function* getPresentation(api: any, action: AnyAction): any {
  const { token } = yield select((state) => state.user)
  const { id } = action

  const response = yield call(api.getPresentation, id, token)

  if (response.ok) {
    const data: any = response.data
    const presentation: FullPresentation = transformPresentationFromApi({
      ...data,
      id,
    })

    yield put(PresentationActions.getPresentationSuccess(presentation))
  } else {
    const error = composeError(response, 'get_presentation_error')
    yield put(ErrorActions.setError(error))
    yield put(PresentationActions.getPresentationFailure())
  }
}

export function* setPresentation(api: any, action: AnyAction): any {
  const { presentation } = action
  const { token } = yield select((state) => state.user)

  const updatedPresentation = {
    name: presentation.name,
    status: presentation.status,
    languages: presentation.supportedLanguages,
    translations: [
      ...mapTranslationsToApi('introText', presentation.introTexts),
      ...mapTranslationsToApi('requestForProposal', presentation.requestForProposal),
    ],
    assets: [
      mapAssetToApi('mapMarker', presentation.mapMarker),
      mapLinkToApiAsset('videoLink', presentation.videoLink),
      mapAssetToApi('hygieneGuidelines', presentation.hygieneGuidelines),
      ...mapAssetsToApi('floorplan', presentation.floorplan),
      ...mapAssetsToApi('capacityChart', presentation.capacityChart),
    ].filter((asset) => asset != null),
    categories: presentation.categories.map((category: Category, index: number) => ({
      type: category.type,
      index,
      capacity: mapCapacityToApi(category.capacity),
      translations: [
        ...mapTranslationsToApi('name', category.name),
        ...mapTranslationsToApi('description', category.description),
      ].filter((asset) => asset != null),
      assets: [
        ...mapAssetsToApi('image', category.images),
        ...mapAssetsToApi('panorama', category.panoramaImages),
      ].filter((asset) => asset != null),
    })),
  }

  /**
   * New assets will have a File object, but no url.
   * - Upload File to get a public url
   * - Save presentation only with assets that have urls
   */
  yield uploadNewAssets(api, token)(updatedPresentation)

  const response = yield call(api.updatePresentation, presentation.id, updatedPresentation, token)

  if (response.ok) {
    yield put(PresentationActions.setPresentationFinished(transformPresentationFromApi(response.data)))
    yield put(ErrorActions.setSuccess('changes_saved'))
  } else {
    const error = composeError(response, 'update_presentation_error')
    yield put(ErrorActions.setError(error))
    yield put(PresentationActions.setPresentationFinished())
  }
}

export function* downloadPresentation(api: any, action: AnyAction): any {
  const { token } = yield select((state) => state.user)
  const { id } = action

  const response = yield call(api.getPresentation, id, token)

  if (response.ok) {
    const { data } = response

    const json = JSON.stringify(data, undefined, 2)
    const blob = new Blob([json], { type: 'text/json;charset=utf-8' })
    const filename = `${id}_${data.updatedAt.split('T')[0]}_${data.name}.json`

    const url = window.URL || window.webkitURL
    const link = url.createObjectURL(blob)
    const a = document.createElement('a')
    a.download = filename
    a.href = link
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    yield put(PresentationActions.downloadPresentationFinished())
  } else {
    const error = composeError(response, 'download_presentation_error')
    yield put(PresentationActions.downloadPresentationFinished())
    yield put(ErrorActions.setError(error))
  }
}

export function* restorePresentation(api: any, action: AnyAction): any {
  const { token } = yield select((state) => state.user)
  const { id } = action

  const response = yield call(api.restorePresentation, id, token)

  if (response?.ok) {
    yield put(PresentationsActions.getPresentations())
    yield put(PresentationActions.restorePresentationFinished())
  } else {
    const error = composeError(response, 'restore_presentation_error')
    yield put(ErrorActions.setError(error))
    yield put(PresentationActions.restorePresentationFinished())
  }
}

export function* revertPresentation(api: any, action: AnyAction): any {
  const { token } = yield select((state) => state.user)
  const { id } = action

  const response = yield call(api.revertPresentation, id, token)

  if (response?.ok) {
    yield put(PresentationsActions.getPresentations())
    yield put(PresentationActions.revertPresentationFinished())
  } else {
    const error = composeError(response, 'revert_presentation_error')
    yield put(ErrorActions.setError(error))
    yield put(PresentationActions.revertPresentationFinished())
  }
}

export function* deletePresentation(api: any, action: AnyAction): any {
  const { token } = yield select((state) => state.user)
  const { id } = action

  const response = yield call(api.deletePresentation, id, token)

  if (response?.ok) {
    yield put(PresentationsActions.getPresentations())
    yield put(PresentationActions.deletePresentationFinished())
  } else {
    const error = composeError(response, 'delete_presentation_error')
    yield put(ErrorActions.setError(error))
    yield put(PresentationActions.deletePresentationFinished())
  }
}
