import React from 'react'
import { connect } from 'react-redux'
import { Dispatch, AnyAction } from 'redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import CategoryActions from '../../redux/CategoryRedux'
import Button, { Props as ButtonProps } from '../Button'
import Dialog from '../Dialog'
import Storage from '../../utils/storage'

interface Props {
  triggerButtonProps: ButtonProps
  onTriggerButtonClick?: Function
  disableClose?: boolean
  hidePreviewButton?: boolean
  presentationId: string
  updateCategory: (categoryType: EditableCategory) => AnyAction
}

const AddNewDialog: React.FC<Props> = ({
  triggerButtonProps,
  disableClose = false,
  hidePreviewButton = false,
  presentationId,
  onTriggerButtonClick,
  updateCategory,
}) => {
  const { t } = useTranslation()
  const history = useHistory()

  const addAndGoToNewCategory = (id: string, category: CategoryType) => {
    if (category) {
      updateCategory({
        init: true,
        type: category,
      })

      // Clear session storage in case of an old draft
      Storage.sessionWrite(Storage.KEYS.CATEGORY_DRAFT, null)

      history.push(`/presentations/${id}/${category}`)
    }
  }

  const goToPreview = (id: string) => {
    history.push(`/presentations/${id}/preview`)
  }

  return (
    <Dialog
      maxWidth='xs'
      triggerButtonProps={triggerButtonProps}
      disableClose={disableClose}
      onTriggerButtonClick={onTriggerButtonClick}
      title={t('add-new-dialog__button--add-new')}
      infoText={hidePreviewButton ? `${t('add_new_info_text')}.` : `${t('add_new_info_text_preview')}.`}
    >
      <Button
        text={t('meeting_room')}
        color='primary'
        fullWidth
        shape='square'
        onClick={() => addAndGoToNewCategory(presentationId, 'meeting-room')}
      />
      <Button
        text={t('room')}
        color='primary'
        fullWidth
        shape='square'
        onClick={() => addAndGoToNewCategory(presentationId, 'room')}
      />
      <Button
        text={t('restaurant')}
        color='primary'
        fullWidth
        shape='square'
        onClick={() => addAndGoToNewCategory(presentationId, 'restaurant')}
      />
      <Button
        text={t('public_area')}
        color='primary'
        fullWidth
        shape='square'
        onClick={() => addAndGoToNewCategory(presentationId, 'public-area')}
      />
      {!hidePreviewButton && (
        <Button
          text={t('go_to_preview')}
          color='default'
          fullWidth
          shape='square'
          onClick={() => goToPreview(presentationId)}
        />
      )}
    </Dialog>
  )
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    updateCategory: (category: EditableCategory) => dispatch(CategoryActions.updateCategory(category)),
  }
}

export default connect(null, mapDispatchToProps)(AddNewDialog)
