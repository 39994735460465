import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { appStyles } from '../theme/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ...appStyles,
    container: {
      margin: '10px 0',
    },
  })
)

interface Props {
  subscription: string
}

const SubscriptionTag: React.FC<Props> = ({ subscription }) => {
  const c = useStyles()
  const { t } = useTranslation()

  return (
    <div className={c.container}>
      <Typography variant='body2' color='inherit'>
        <span className={c.isBold}>{`${t('subscription')}: `}</span>
        <span>{subscription}</span>
      </Typography>
    </div>
  )
}

export default SubscriptionTag
