import * as PDFJS from 'pdfjs-dist/webpack'
// import workerURL from '../pdf.worker.min.data' // See: https://github.com/mozilla/pdf.js/issues/10813#issuecomment-693935886
// PDFJS.GlobalWorkerOptions.workerSrc = workerURL

export const splitPdfToImages = (
  src: string,
  fileName: string,
  callback: (pageNum: number, file?: FileWithSrc) => void
) => {
  PDFJS.getDocument(src).promise.then((pdf: PDFJS.PDFDocumentProxy) => {
    for (let i = 0; i < pdf.numPages; i++) {
      const pageNum = i + 1
      getPdfPageAsPng(pdf, pageNum, fileName).then((result) => {
        callback(pageNum, result)
      })
    }
  })
}

export const getPdfPageAsPng = (
  pdf: PDFJS.PDFDocumentProxy,
  pageNum: number,
  fileName: string
): Promise<FileWithSrc> => {
  return new Promise((resolve, reject) => {
    pdf.getPage(pageNum).then((page) => {
      const scale = 1.3333334
      const viewport = page.getViewport({ scale })
      const canvas = document.createElement('canvas')
      const canvasContext = canvas.getContext('2d', { alpha: false })
      canvas.height = viewport.height
      canvas.width = viewport.width
      if (canvasContext) {
        page.render({ canvasContext, viewport }).promise.then((res) => {
          const mimeType = 'image/jpeg'
          canvas.toBlob((blob) => {
            if (blob) {
              const name = `${fileName.replace('.pdf', '')}_PAGE-${pageNum}.jpg`
              const file = new File([blob], name, { type: mimeType })
              const dataUrl = canvas.toDataURL(mimeType)
              resolve({ file, dataUrl })
            }
          }, mimeType)
        })
      }
    })
  })
}

export const scaleImage = (image: HTMLImageElement, ratio: number, fileName: string): Promise<FileWithSrc> => {
  return new Promise((resolve, reject) => {
    const { width, height } = getScaledDimensions(image, ratio)
    const canvas = document.createElement('canvas')
    canvas.width = width
    canvas.height = height
    const canvasContext = canvas.getContext('2d', { alpha: false })
    if (canvasContext) {
      const mimeType = `image/${getDataType(image.src, 'image')}`
      canvasContext.scale(ratio, ratio)
      canvasContext.drawImage(image, 0, 0)
      canvas.toBlob((blob) => {
        if (blob) {
          const file = new File([blob], fileName, { type: mimeType })
          const dataUrl = canvas.toDataURL(mimeType)
          resolve({ file, dataUrl })
        }
      }, mimeType)
    }
  })
}

export const getScaledDimensions = (image: HTMLImageElement, ratio: number) => ({
  width: Math.floor(image.width * ratio),
  height: Math.floor(image.height * ratio),
})

export const getDataType = (base64: string, dataType = 'image') =>
  base64.substring(`data/${dataType}/`.length, base64.indexOf(';base64'))
