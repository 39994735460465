import React, { useState } from 'react'
import styled from 'styled-components'
import { IconButton } from '@material-ui/core'
import { useDispatch } from 'react-redux'

import Icon from '../../theme/Icons'
import Button from '../../components/Button'
import NewEmbeddedChart from './NewEmbeddedChart'
import { AdminToolsTypes } from '../../redux/AdminToolsRedux'

const ChartRow = styled.div<{ visible: boolean }>`
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem 2rem;
  background-color: #e6e6e6;

  .preview-wrapper {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }

  .content-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
`

const EmbeddedChartRow: React.FC<{ chart: EmbeddedChart; folderUuid: string }> = ({ chart, folderUuid }) => {
  const [showEdit, setShowEdit] = useState(false)
  const dispatch = useDispatch()

  const handleChartEdit = (name: string, fileAsset?: FileAsset) => {
    const file = fileAsset && fileAsset.file
    dispatch({
      type: AdminToolsTypes.EDIT_EMBEDDED_CHART,
      name,
      file,
      folderUuid,
      chartUuid: chart.uuid,
    })
    setShowEdit(false)
  }

  const handleDelete = () => {
    if (window.confirm(`Delete ${chart.name} ?`)) {
      dispatch({
        type: AdminToolsTypes.DELETE_EMBEDDED_CHART,
        chartUuid: chart.uuid,
      })
    }
  }

  return (
    <>
      <ChartRow visible={!showEdit}>
        <div className='content-wrapper'>
          <div>
            <span>{chart.name}</span>
          </div>
          <div>
            <IconButton onClick={() => setShowEdit(true)}>
              <Icon.Edit color='secondary' />
            </IconButton>
            <IconButton onClick={handleDelete}>
              <Icon.Delete color='error' />
            </IconButton>
          </div>
        </div>
        <div className='preview-wrapper'>
          <a target='_blank' rel='noreferrer' href={`/embed/${chart.uuid}`}>
            <Button text='Preview' variant='outlined' />
          </a>
        </div>
      </ChartRow>
      <NewEmbeddedChart chart={chart} visible={showEdit} onSave={handleChartEdit} onCancel={() => setShowEdit(false)} />
    </>
  )
}

export default EmbeddedChartRow
