import { toast } from 'react-toastify'
import i18n from '../i18n'
import { CLIENT_ERROR, CLIENT_CONNECTION_ERRORS } from './constants'

const getErrorKey = (response: any): ErrorKey => {
  if (response.status === 401) {
    return 'unauthorized'
  } else if (response.status === 404) {
    return 'not_found'
  } else if (response.problem === CLIENT_ERROR) {
    return 'client_error'
  } else if (CLIENT_CONNECTION_ERRORS.includes(response.problem)) {
    return 'network_error'
  } else {
    return 'server_error'
  }
}

// Return error object based on API response
export const composeError = (response: any, message?: string, params?: { [key: string]: string }): ErrorType => {
  const cmsMessage = response.data?.message === 'CmsError' ? 'CMS: ' : ''
  const key = getErrorKey(response)
  return { key, message, params, cmsMessage }
}

export const getErrorMsg = ({ key, message, cmsMessage, params }: ErrorType) => {
  const reasonMsg = `${cmsMessage}${i18n.t(`error.${key}`)}`
  if (message) {
    const errorMsg = i18n.t(`error.${message}`, params)
    return `${errorMsg}. (${reasonMsg})`
  }
  return reasonMsg
}

export const removeHtmlTags = (text: string) => {
  const re = /<[^>]+>/g
  return text.replace(re, ' ').trim()
}

// Removes html-tags from translations
// and then returns count of unempty translations
export const getTranslationCount = (texts: Translation[]) => {
  if (texts) {
    return texts.reduce((count: number, translation: Translation) => {
      const cleanedInput = removeHtmlTags(translation.text)
      return cleanedInput.length > 0 ? count + 1 : count
    }, 0)
  }
}

export const bytesToMB = (bytes: number) => (bytes / 1000000).toFixed(3)

export const mapTranslationsToApi = (context: string, translations: Translation[] | undefined) => {
  if (!translations) return []

  return translations.map((translation) => ({
    context,
    text: translation.text,
    lang: translation.lang,
  }))
}

/**
 * Asset => Asset
 *
 * Conditionally maps an Asset to an asset with `url` pointing to a public resource.
 * - Conversion is done if a `file` property is defined, which marks a new file (prop contains a File object).
 * - Otherwise return the original asset.
 */
export const mapAssetToAssetWithUrl = (api: any, token: string) => async (asset: any = {}) => {
  const { file, ...assetData } = asset

  if (!file) return asset

  const response = await api.uploadImage(file, token)
  if (response.ok && response.data?.url) {
    return { ...assetData, url: response.data?.url }
  } else {
    const error = composeError(response, 'upload_image_error', { filename: asset.name })
    const errorMsg = getErrorMsg(error)
    toast.error(errorMsg)
  }
}

export const mapAssetsToApi = (context: string, files: FileAsset[] | undefined) => {
  if (!files) return []
  return files.map((file, index) => mapAssetToApi(context, file, index))
}

export const mapAssetToApi = (context: string, file?: FileAsset, index = 0) => {
  if (!file) return null

  return {
    id: file.id,
    context,
    index,
    name: file.name,
    size: file.size,
    width: file.width,
    height: file.height,
    url: file.src,
    file: file.file, // Keep file still around (will be uploaded after mapping)
  }
}

export const mapAssetFromApi = (context: string, assets: any): FileAsset | undefined => {
  // Todo: Type = ImageFromApi?
  if (!assets) return undefined

  const image = assets.find((asset: any) => asset.context === context)

  if (!image) return undefined

  return {
    id: image.id,
    name: image.name,
    size: image.size,
    width: image.width,
    height: image.height,
    src: image.url,
  }
}

export const mapAssetsFromApi = (context: string, assets: any): FileAsset[] => {
  // Todo: Type = AssetsFromApi?
  if (!assets) return []

  return assets
    .filter((asset: any) => asset.context === context)
    .sort((a: any, b: any) => a.index - b.index || 0)
    .map((asset: any) => ({
      id: asset.id,
      name: asset.name,
      size: asset.size,
      width: asset.width,
      height: asset.height,
      src: asset.url,
    }))
}

export const mapLinkToApiAsset = (context: string, link: ExternalLink) => {
  if (!link) return null

  return {
    id: link.id,
    url: link.url,
    name: link.url,
    context,
  }
}

export const mapLinkFromApiAsset = (context: string, assets: any): ExternalLink | undefined => {
  const link = assets?.find((asset: any) => asset.context === context)
  if (!link) return undefined

  return {
    id: link.id,
    url: link.url,
  }
}

export const mapTranslationsFromApi = (context: string, translations: any): Translation[] => {
  // Todo: Type = TranslationsFromApi?
  if (!translations) return []

  return translations
    .filter((translation: any) => translation.context === context)
    .map((translation: any) => ({
      lang: translation.lang,
      text: translation.text,
    }))
}

export const mapCapacityToApi = (capacity: CapacityInfo[] | undefined) => {
  if (!capacity) return undefined

  const capacityObj: { [key: string]: number | undefined } = {}
  capacity.forEach((capacity) => {
    const { name, value } = capacity
    const key = name === 'u-table' ? 'uTable' : name === 'round-tables' ? 'roundTables' : name
    capacityObj[key] = value
  })
  return capacityObj
}

export const mapCapacityFromApi = (capacity: any): CapacityInfo[] | undefined => {
  if (!capacity) return undefined

  const { theatre, classroom, boardroom, uTable, roundTables, banquet, coctail } = capacity

  return [
    { name: 'theatre', value: theatre },
    { name: 'classroom', value: classroom },
    { name: 'boardroom', value: boardroom },
    { name: 'u-table', value: uTable },
    { name: 'round-tables', value: roundTables },
    { name: 'banquet', value: banquet },
    { name: 'coctail', value: coctail },
  ]
}

export const mapPermissionsFromApi = (permissions: any) => {
  if (!permissions) return []
  return permissions.map((p: any) => p.name)
}
