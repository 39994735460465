import {
  ArrowDownward,
  ArrowUpward,
  CheckCircle as MuiCheck,
  ChevronRight,
  Close as MuiClose,
  CloudDownload,
  DeleteForever,
  Description,
  Edit as MuiEdit,
  ExpandMore,
  Help as MuiHelp,
  InfoOutlined,
  PhotoLibrary,
  SettingsBackupRestore,
  Undo as MuiUndo,
  ViewList,
} from '@material-ui/icons'

export const ArrowDown = ArrowDownward
export const ArrowUp = ArrowUpward
export const CaretDown = ExpandMore
export const CaretRight = ChevronRight
export const Check = MuiCheck
export const Close = MuiClose
export const Delete = DeleteForever
export const Download = CloudDownload
export const Edit = MuiEdit
export const File = Description
export const Help = MuiHelp
export const Images = PhotoLibrary
export const Info = InfoOutlined
export const List = ViewList
export const Restore = SettingsBackupRestore
export const Undo = MuiUndo

export default {
  ArrowDown,
  ArrowUp,
  CaretDown,
  CaretRight,
  Check,
  Close,
  Delete,
  Download,
  Edit,
  File,
  Help,
  Images,
  Info,
  List,
  Restore,
  Undo,
}
