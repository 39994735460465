import React from 'react'
import { useTranslation } from 'react-i18next'
import { Divider, makeStyles, Theme, createStyles, Typography } from '@material-ui/core'

import ListItem from './ListItem'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    presentationsList: {
      listStyleType: 'none',
      margin: '0',
      padding: '0',
    },
  })
)

interface Props {
  presentations: Presentation[]
}

const PresentationsList: React.FC<Props> = ({ presentations }) => {
  const c = useStyles()
  const { t } = useTranslation()

  if (!presentations.length) return <Typography variant='body1'>{t('presentations_not_found')}</Typography>

  return (
    <ul className={c.presentationsList}>
      <Divider />
      {presentations.map((presentation: Presentation) => (
        <li key={presentation.id}>
          <ListItem presentation={presentation} />
          <Divider />
        </li>
      ))}
    </ul>
  )
}

export default PresentationsList
