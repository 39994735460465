import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginBottom: '20px',
    },
    table: {
      borderSpacing: 'inherit',
      '& td': {
        '&:nth-child(1)': {
          paddingRight: '20px',
        },
        '&:nth-child(2)': {
          textAlign: 'center',
        },
      },
    },
  })
)

interface Props {
  capacity: CapacityInfo[]
}

const CapacityInfoPreview: React.FC<Props> = ({ capacity }) => {
  const { t } = useTranslation()
  const c = useStyles()

  return (
    <div className={c.container}>
      <Typography variant='subtitle2'>{t('capacity_information')}</Typography>
      <table className={c.table}>
        <tbody>
          {capacity.map((item) => (
            <tr key={item.name}>
              <td>
                <Typography variant='body2'>{t(item.name.replace('-', '_'))}</Typography>
              </td>
              <td>
                <Typography variant='body2'>{item.value || '-'}</Typography>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default CapacityInfoPreview
