import React, { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

import TranslationsDialog from '../TranslationsDialog'
import CapacityInfo from './CapacityInfo'

interface Props {
  type: CategoryType
  nameTexts?: Translation[]
  setNameTexts: Dispatch<SetStateAction<Translation[]>>
  descriptions?: Translation[]
  setDescriptions: Dispatch<SetStateAction<Translation[]>>
  capacity?: CapacityInfo[]
  setCapacity: Dispatch<SetStateAction<CapacityInfo[]>>
}

const BasicInformation: React.FC<Props> = ({
  type,
  nameTexts,
  setNameTexts,
  descriptions,
  setDescriptions,
  capacity,
  setCapacity,
}) => {
  const { t } = useTranslation()

  return (
    <div>
      <TranslationsDialog inputLabel={t('name')} texts={nameTexts} setTexts={setNameTexts} />

      <TranslationsDialog
        inputLabel={t('description_and_features')}
        texts={descriptions}
        setTexts={setDescriptions}
        editor
      />

      {/* TODO: CAPACITY INFO DISABLED -> UNCOMMENT WHEN READY FOR LIVE */}
      {/* {type === 'meeting-room' && capacity && <CapacityInfo capacity={capacity} setCapacity={setCapacity} />} */}
    </div>
  )
}

export default BasicInformation
