import React, { useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Paper, { PaperProps } from '@material-ui/core/Paper'
import { Typography, createStyles, makeStyles, Theme } from '@material-ui/core'
import Draggable from 'react-draggable'
import { useTranslation } from 'react-i18next'

import Button, { Props as ButtonProps } from './Button'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contentContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    actionsContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  })
)

interface Props {
  triggerButtonProps: ButtonProps
  onTriggerButtonClick?: Function
  disableClose?: boolean
  title?: string
  infoText?: string
  dialogActionButton?: React.ReactNode
  maxWidth?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | undefined
  draggable?: boolean
  children: React.ReactNode
}

const DraggableDialog: React.FC<Props> = ({
  triggerButtonProps,
  onTriggerButtonClick,
  disableClose = false,
  title,
  infoText,
  dialogActionButton,
  maxWidth,
  draggable = true,
  children,
}) => {
  const { t } = useTranslation()
  const c = useStyles()

  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    if (onTriggerButtonClick) {
      onTriggerButtonClick()
    }
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Button {...triggerButtonProps} onClick={handleClickOpen} />

      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={draggable ? DraggablePaperComponent : Paper}
        aria-labelledby='dialog-title'
        maxWidth={maxWidth}
        disableBackdropClick={disableClose}
        disableEscapeKeyDown={disableClose}
      >
        <DialogTitle style={{ cursor: draggable ? 'move' : 'default' }} id='dialog-title'>
          <Typography variant='h3'>{title}</Typography>
        </DialogTitle>

        <DialogContent className={c.contentContainer}>
          <DialogContentText>
            <Typography variant='body2'>{infoText}</Typography>
          </DialogContentText>
          {children}
        </DialogContent>

        <DialogActions className={c.actionsContainer}>
          {dialogActionButton}
          {!disableClose && <Button text={t('close')} onClick={handleClose} variant='outlined' />}
        </DialogActions>
      </Dialog>
    </>
  )
}

/**
 * Why is this PaperComponent here?
 *
 * See Material UI Docs (https://material-ui.com/components/dialogs/#draggable-dialog):
 *
 * You can create a draggable dialog by using react-draggable.
 * To do so, you can pass the the imported Draggable component as the
 * PaperComponent of the Dialog component. This will make the entire dialog draggable.
 */
const DraggablePaperComponent: React.FC = (props: PaperProps) => {
  return (
    <Draggable handle='#dialog-title' cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default DraggableDialog
