import { combineReducers, AnyAction } from 'redux'
import configureStore from './CreateStore'
import { UserTypes } from './UserRedux'
import rootSaga from '../sagas'

/* ------------- Assemble The Reducers ------------- */
export const reducers = combineReducers({
  user: require('./UserRedux').reducer,
  error: require('./ErrorRedux').reducer,
  presentations: require('./PresentationsRedux').reducer,
  presentation: require('./PresentationRedux').reducer,
  category: require('./CategoryRedux').reducer,
  analytics: require('./AnalyticsRedux').reducer,
  adminTools: require('./AdminToolsRedux').reducer,
})

const rootReducer = (state: RootStore, action: AnyAction) => {
  const newState = action.type === UserTypes.LOGOUT_SUCCESS ? undefined : state
  return reducers(newState, action)
}

export default () => {
  let { store, sagasManager, sagaMiddleware } = configureStore(rootReducer, rootSaga)

  if (module.hot) {
    module.hot.accept(() => {
      const nextRootReducer = require('./').reducers
      store.replaceReducer(nextRootReducer)

      const newYieldedSagas = require('../sagas').default
      sagasManager.cancel()
      sagasManager.toPromise().then(() => {
        sagasManager = sagaMiddleware.run(newYieldedSagas)
      })
    })
  }

  return store
}
