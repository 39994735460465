import React from 'react'
import { makeStyles, Theme, createStyles, Typography, IconButton } from '@material-ui/core'

import Icons, { Delete as DeleteIcon } from '../theme/Icons'
import { Colors } from '../theme/colors'
import { bytesToMB } from '../utils/transforms'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fileItem: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: '10px 0',
    },
    previewImage: {
      width: '70px',
      height: '70px',
      objectFit: 'cover',
      border: `1px solid ${Colors.grayLight}`,
      borderRadius: '5px',
    },
    fileName: {
      flex: 1,
      margin: '0 10px',
    },
    fileNameLink: {
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  })
)

interface Props {
  file: FileAsset
  onDelete?: (id: string) => void
}

const FileListItem: React.FC<Props> = ({ file, onDelete }) => {
  const c = useStyles()

  if (typeof file.src !== 'string') return null

  const isImage = !!file.width && !!file.height
  const fileSizeString = file.size ? ` (${bytesToMB(file.size)} MB)` : ''
  const fileDimensions = file.width && file.height ? `${file.width} x ${file.height} px` : ''

  return (
    <div className={c.fileItem}>
      {isImage ? (
        <img id={file.id} src={file.src} alt={file.name} className={c.previewImage} />
      ) : (
        <Icons.File fontSize='large' />
      )}

      <div className={c.fileName}>
        {!file.file ? (
          <a href={file.src} target='_blank' rel='noopener noreferrer' className={c.fileNameLink}>
            <Typography variant='body1'>{file.name}</Typography>
          </a>
        ) : (
          <Typography variant='body1'>{file.name}</Typography>
        )}
        <Typography variant='body2'>{`${fileDimensions}${fileSizeString}`}</Typography>
      </div>

      {onDelete && (
        <IconButton onClick={() => onDelete(file.id)}>
          <DeleteIcon fontSize='large' />
        </IconButton>
      )}
    </div>
  )
}

export default FileListItem
