import React from 'react'
import AnalyticsChart from './AnalyticsChart'
import { useSelector } from 'react-redux'
import cloneDeep from 'lodash.clonedeep'
import {
  TOTAL_IMAGE_VIEWS_OPTIONS,
  WITH_COMPARISON_COLORS,
  DEFAULT_COLORS,
  DEFAULT_DASH_ARRAY,
  WITH_COMPARISON_DASH_ARRAY,
} from '../../utils/chartSettings'

const emptyData: GraphData = [
  {
    name: '',
    data: [],
  },
]

const TotalViewsChart: React.FC<ChartWrapperProps> = (props) => {
  const storeData = useSelector((state: RootStore) => state.analytics.totalViews)
  const { results, compareResults } = storeData
  const opts = TOTAL_IMAGE_VIEWS_OPTIONS

  let graphData = emptyData
  let newData: object[] = []

  /**
   * Apex charts doesn't have a built in way to display comparison data,
   * so in order to achieve that, we push the actual data and comparison
   * data next to each other, and configure colors to achieve a comparison
   * look.
   */
  if (results && compareResults) {
    opts.common.colors = WITH_COMPARISON_COLORS
    if (opts.common.stroke) {
      opts.common.stroke.dashArray = WITH_COMPARISON_DASH_ARRAY
    }

    const properties = Object.keys(results)

    properties.forEach((property) => {
      newData.push(
        {
          name: property,
          data: results[property],
        },
        {
          name: property,
          data: compareResults[property],
        }
      )
    })
  }

  if (results && !compareResults) {
    opts.common.colors = DEFAULT_COLORS
    if (opts.common.stroke) {
      opts.common.stroke.dashArray = DEFAULT_DASH_ARRAY
    }
    newData = Object.entries(storeData.results).map(([id, values]) => ({
      name: id,
      data: values,
    }))
  }

  graphData = newData as GraphData

  const clonedData = cloneDeep(graphData)
  const clonedOpts = cloneDeep(opts)

  return <AnalyticsChart {...props} series={clonedData} options={clonedOpts} />
}

export default TotalViewsChart
