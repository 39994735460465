import Login from '../containers/Login'
import Presentations from '../containers/Presentations'
import AddCategory from '../containers/AddCategory'
import StartPresentation from '../containers/StartPresentation'
import PresentationPreview from '../containers/PresentationPreview'
import TermsOfUse from '../containers/TermsOfUse'
import Analytics from '../containers/Analytics'
import AdminTools from '../containers/AdminTools'
import EmbeddedChart from '../containers/EmbeddedChart'

const contentRoutes = [
  { path: '/login', component: Login, exact: true },
  { path: '/presentations/:id/preview', component: PresentationPreview, exact: true },
  { path: '/presentations/:id/:category', component: AddCategory, exact: true },
  { path: '/presentations/:id', component: StartPresentation, exact: true },
  { path: '/presentations', component: Presentations, exact: true },
  { path: '/analytics', component: Analytics, exact: true },
  { path: '/admin-tools', component: AdminTools, exact: true },
  { path: '/embed/:uuid', component: EmbeddedChart, exact: true },
]

const acceptTermsRoutes = [
  { path: '/login', component: Login, exact: true },
  { path: '/presentations', component: TermsOfUse, exact: true },
]

const loggedOutRoutes = [
  { path: '/login', component: Login, exact: true },
  { path: '/embed/:uuid', component: EmbeddedChart, exact: true },
]

const routes = {
  contentRoutes,
  acceptTermsRoutes,
  loggedOutRoutes,
}

export default routes
