import React, { useCallback, Dispatch, SetStateAction, useState, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { makeStyles, Theme, createStyles, Typography } from '@material-ui/core'
import { v4 as uuidv4 } from 'uuid'

import { File as FileIcon } from '../theme/Icons'
import { Colors } from '../theme/colors'
import FileList from './FileList'
import { BULLET_CHAR } from '../utils/constants'
import { splitPdfToImages } from '../utils/files'
import Spinner from './Spinner'
import HelpTip from './HelpTip'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: {},
    infoText: {
      marginBottom: '12px',
    },
    dropzoneContainer: {
      padding: '20px',
      backgroundColor: 'rgba(234, 234, 234, .5)',
      border: `2px dashed ${Colors.gray}`,
      borderRadius: '5px',
    },
    dragHover: {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: Colors.grayLight,
      },
    },
    dragActive: {
      backgroundColor: Colors.grayLight,
    },
    dropzoneContent: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    imageCountText: {
      marginBottom: '10px',
      textAlign: 'center',
      fontStyle: 'italic',
    },
  })
)

interface Props {
  label?: string
  labelSize?: 'h4' | 'h5' | 'h6'
  infoText?: string
  fileLimit?: number
  files: FileAsset[]
  setFiles?: Dispatch<SetStateAction<FileAsset[]>>
  setFile?: Dispatch<SetStateAction<FileAsset | undefined>>
  onDropAction?: Function
  acceptedFormats: string[]
  validate?: (src: string, file: any, callback: (acceptedFile?: FileAsset) => void) => void
  fileRestrictions?: string[]
  helpTipKey?: string
}

const FileDropzone: React.FC<Props> = ({
  label,
  labelSize,
  infoText,
  fileLimit,
  files,
  setFiles,
  setFile,
  onDropAction,
  acceptedFormats,
  validate,
  fileRestrictions,
  helpTipKey,
}) => {
  const c = useStyles()
  const { t } = useTranslation()

  const [uploadProgress, setUploadProgress] = useState({ count: 0, total: 0 })

  const loading = uploadProgress.count < uploadProgress.total
  const fileCount = files.length
  const allowManyFiles = fileLimit === undefined || fileLimit > 1
  const isLimitExceeded = fileLimit ? fileCount >= fileLimit : false

  useEffect(() => {
    const { count, total } = uploadProgress
    if (total > 0 && count === total) {
      // Upload progress finished, set defaults:
      setUploadProgress({ count: 0, total: 0 })
    }
  }, [uploadProgress])

  const setAcceptedFile = useCallback(
    (acceptedFile: FileAsset) => {
      if (allowManyFiles && setFiles) setFiles((prevState) => [...prevState, acceptedFile])
      else if (fileLimit === 1 && setFile) setFile(acceptedFile)
      setUploadProgress((prev) => ({
        ...prev,
        count: prev.count + 1,
      }))
    },
    [allowManyFiles, fileLimit, setFile, setFiles]
  )

  const handleReject = () => {
    setUploadProgress((prev) => ({
      ...prev,
      count: prev.count + 1,
    }))
  }

  const onDrop = useCallback(
    (acceptedFiles) => {
      if (onDropAction) onDropAction()

      // Take only as many files as are allowed concidering the limit:
      // TODO: Multi-page-PDF is counted as one file, which is wrong when considering imaga limits
      if (fileLimit !== undefined) acceptedFiles.splice(fileLimit - fileCount)

      acceptedFiles.forEach((file: any) => {
        const reader = new FileReader()
        reader.onload = (e: ProgressEvent<FileReader>) => {
          setUploadProgress((prev) => ({ ...prev, total: prev.total + 1 }))
          if (typeof reader.result === 'string') {
            if (!validate) {
              setAcceptedFile({
                id: uuidv4(),
                name: file.name,
                size: file.size,
                src: reader.result,
                file, // Keep File object around for uploading it later
              })
            } else {
              if (file.type.includes('pdf')) {
                splitPdfToImages(reader.result, file.name, (pageNum: number, response?: FileWithSrc) => {
                  if (response) {
                    if (pageNum > 1) setUploadProgress((prev) => ({ ...prev, total: prev.total + 1 }))
                    const { file, dataUrl } = response
                    validate(dataUrl, file, (acceptedFile?: FileAsset) => {
                      if (acceptedFile) setAcceptedFile(acceptedFile)
                      else handleReject()
                    })
                  } else handleReject()
                })
              } else {
                validate(reader.result, file, (acceptedFile?: FileAsset) => {
                  if (acceptedFile) setAcceptedFile(acceptedFile)
                  else handleReject()
                })
              }
            }
          }
        }
        reader.readAsDataURL(file)
      })
    },
    [fileCount, fileLimit, onDropAction, setAcceptedFile, validate]
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: fileLimit === undefined || fileLimit > 1,
    disabled: isLimitExceeded,
    accept: acceptedFormats,
  })

  const getContainerClass = () => {
    let className = c.dropzoneContainer
    if (isDragActive) className += ` ${c.dragActive}`
    if (!isLimitExceeded) className += ` ${c.dragHover}`
    return className
  }

  const getDragAreaText = (isLimitExceeded: boolean) => {
    const mainText = isLimitExceeded
      ? t('no_more_files_allowed')
      : t(`drag_your_${allowManyFiles ? 'files' : 'file'}`).toUpperCase()

    let additionalText = ''
    if (!isLimitExceeded) {
      additionalText = t('supported_file_formats', {
        formats: acceptedFormats.map((format) => `*.${format.split('/')[1]}`).join(', '),
      })
      if (fileRestrictions) additionalText += BULLET_CHAR + fileRestrictions.join(BULLET_CHAR)
    }

    return (
      <>
        <Typography variant='subtitle1'>{mainText}</Typography>
        <Typography variant='body2'>{additionalText}</Typography>
      </>
    )
  }

  const getProgressPercent = () => {
    const { count, total } = uploadProgress
    if (total === 0) return undefined
    const percent = (count / total) * 100
    return `${percent.toFixed(0)}%`
  }

  return (
    <div className={c.mainContainer}>
      {loading && <Spinner isFullScreen progressText={getProgressPercent()} />}
      <Typography variant={labelSize}>
        {label}
        {helpTipKey && <HelpTip helpTextKey={helpTipKey} />}
      </Typography>
      <Typography variant={'body2'} className={c.infoText}>
        {infoText}
      </Typography>
      {!isLimitExceeded && (
        <div {...getRootProps()} className={getContainerClass()}>
          <input {...getInputProps()} />
          <div className={c.dropzoneContent}>
            <FileIcon fontSize='large' />
            {getDragAreaText(isLimitExceeded)}
          </div>
        </div>
      )}
      <div>
        <FileList files={files} setFiles={setFiles} setFile={setFile}></FileList>
      </div>
    </div>
  )
}

export default FileDropzone
