import React, { useState, MouseEvent } from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { IconButton } from '@material-ui/core'

import Icon from '../../theme/Icons'
import Button from '../../components/Button'
import AdminToolsActions from '../../redux/AdminToolsRedux'
import NewEmbeddedChart from './NewEmbeddedChart'
import EmbeddedChartRow from './EmbeddedChartRow'

const Container = styled.div`
  .title {
    background-color: #53b8bc;
    color: #fff;
    padding: 1rem;
    font-size: 18px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
  }

  .embedded-graph {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem 2rem;
    background-color: #e6e6e6;
  }

  .preview-wrapper {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }

  .content-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
`

const ExpandableContent = styled.div<{ expanded: boolean }>`
  display: ${(props) => (props.expanded ? 'block' : 'none')};
`

interface EmbeddedChartFolderProps {
  folder: EmbeddedChartFolder
}

const EmbeddedChartFolder: React.FC<EmbeddedChartFolderProps> = ({ folder }) => {
  const dispatch = useDispatch()
  const [expanded, setExpanded] = useState(false)
  const [showAddNew, setShowAddNew] = useState(false)
  const { name, charts, uuid } = folder

  const handleExpandClick = () => {
    setShowAddNew(false)
    setExpanded(!expanded)
  }

  const handleAddNew = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setExpanded(true)
    setShowAddNew(true)
  }

  const handleNewChartSave = (name: string, fileAsset?: FileAsset) => {
    const file = fileAsset && fileAsset.file
    dispatch(AdminToolsActions.createEmbeddedChart({ name, file, folderUuid: uuid }))
    setShowAddNew(false)
  }

  const handleNewChartCancel = () => {
    setShowAddNew(false)
  }

  const handleDelete = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    if (window.confirm(`Delete ${name} ?`)) {
      dispatch(AdminToolsActions.deleteEmbeddedChartFolder({ folderUuid: uuid }))
    }
  }

  return (
    <Container>
      <div className='title' onClick={handleExpandClick}>
        <span>
          &rsaquo; {name} ({charts.length})
        </span>
        <div>
          <IconButton onClick={handleDelete}>
            <Icon.Delete color='error' />
          </IconButton>
          <Button text='Add new' onClick={handleAddNew} color='default' />
        </div>
      </div>
      <ExpandableContent expanded={expanded}>
        <NewEmbeddedChart visible={showAddNew} onSave={handleNewChartSave} onCancel={handleNewChartCancel} />

        {charts.map((chart: EmbeddedChart) => (
          <EmbeddedChartRow chart={chart} key={chart.uuid} folderUuid={folder.uuid} />
        ))}
      </ExpandableContent>
    </Container>
  )
}

export default EmbeddedChartFolder
