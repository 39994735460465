import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Typography, makeStyles, createStyles, Theme, Tooltip, IconButton } from '@material-ui/core'
import moment from 'moment-timezone'

import { PresentationTypes } from '../../redux/PresentationRedux'
import Button from '../Button'
import { useHistory } from 'react-router-dom'
import TextPreview from '../TextPreview'
import { appStyles } from '../../theme/styles'
import SubscriptionTag from '../SubscriptionTag'
import { ROLE, STATUS, PRESENTATION_TYPE, PRESENTATION_PERMISSIONS } from '../../utils/constants'
import Icon from '../../theme/Icons'

import { AnalyticsTypes } from '../../redux/AnalyticsRedux'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ...appStyles,
    container: {
      padding: '15px 0',
    },
    detailsContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    instructions: {
      maxWidth: '50%',
    },
    actionsContainer: {
      display: 'flex',
      alignItems: 'center',
    },
  })
)

interface Props {
  presentation: Presentation
}

const ListItem: React.FC<Props> = ({ presentation }) => {
  const c = useStyles()
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const userRole = useSelector((state: RootStore) => state.user.user?.role)
  const { downloading } = useSelector((state: RootStore) => state.presentation)

  const id = presentation.id
  const isStartStatus = presentation.status === STATUS.START
  const isDraftStatus = presentation.status === STATUS.DRAFT
  const isSentStatus = presentation.status === STATUS.SENT
  const isPublished = presentation.type === PRESENTATION_TYPE.PUBLISHED

  const hasAnalyticsAccess = presentation.permissions.includes(PRESENTATION_PERMISSIONS.ANALYTICS_ACCESS)
  const hasPresentationAccess = presentation.permissions.includes(PRESENTATION_PERMISSIONS.EDITOR_ACCESS)

  const downloadPresentation = () => {
    dispatch({ type: PresentationTypes.DOWNLOAD_PRESENTATION, id })
  }

  const restorePresentation = () => {
    const confirmationText = `${t('restore_presentation')}? (${t('restore_presentation_info')})`
    if (window.confirm(confirmationText)) dispatch({ type: PresentationTypes.RESTORE_PRESENTATION, id })
  }

  const revertToDraft = () => {
    const confirmationText = `${t('revert_to_draft')}? ${t('revert_to_draft_info')}.`
    if (window.confirm(confirmationText)) dispatch({ type: PresentationTypes.REVERT_PRESENTATION, id })
  }

  const deletePresentation = () => {
    const confirmationText = `${t('delete_presentation')}?`
    if (window.confirm(confirmationText)) dispatch({ type: PresentationTypes.DELETE_PRESENTATION, id })
  }

  const handleStart = () => history.push(`/presentations/${id}`)

  const handlePreview = () => history.push(`/presentations/${id}/preview`)

  const handleAnalyticsClick = (propertyId: string) => {
    const payload = { selectedProperties: [propertyId] }
    dispatch({ type: AnalyticsTypes.SEARCH_ANALYTICS, payload })
    history.push(`/analytics`)
  }

  /**
   * When presentations are fetched from CMS they might be returned as "published" but
   * the status in local database is "start". This is because depending on which
   * environment the presentation was started in, that env only has the info about the
   * real status. Here we check !isPublished mainly so that local & staging environments
   * reflect the production CMS data as well as possible.
   */
  const showStartResumeBtn = (isStartStatus || isDraftStatus) && hasPresentationAccess && !isPublished
  const showPreview = isDraftStatus && hasPresentationAccess
  const showViewAnalytics = isPublished && hasAnalyticsAccess

  const UserActions = () => (
    <div className={c.actionsContainer}>
      {showViewAnalytics && (
        <Button text={t('analytics.view_analytics')} variant='outlined' onClick={() => handleAnalyticsClick(id)} />
      )}
      {showStartResumeBtn && (
        <Button
          text={t(isDraftStatus ? 'presentation__button--resume' : 'presentation__button--start')}
          variant='outlined'
          onClick={handleStart}
        />
      )}
      {showPreview && <Button text={`${t('preview')} & ${t('send')}`} color='primary' onClick={handlePreview} />}
      {isSentStatus && (
        <>
          <Icon.Check color='primary' />
          <Typography variant='body2'>
            {`${t('submitted')} ${moment.utc(presentation.updatedAt).format('ll')}`}
          </Typography>
        </>
      )}
    </div>
  )

  const SuperuserActions: React.FC = () => (
    <div>
      {isSentStatus && (
        <Tooltip title={`${t('revert_to_draft')}`} placement='top'>
          <IconButton onClick={revertToDraft} size='medium' disabled={downloading}>
            <Icon.Undo color='inherit' fontSize='large' />
          </IconButton>
        </Tooltip>
      )}
      {isDraftStatus && (
        <Tooltip title={`${t('restore_presentation')}`} placement='top'>
          <IconButton onClick={restorePresentation} size='medium' disabled={downloading}>
            <Icon.Restore color='error' fontSize='large' />
          </IconButton>
        </Tooltip>
      )}
      <Tooltip title={`${t('download_presentation')} (JSON)`} placement='top'>
        <IconButton onClick={downloadPresentation} size='medium' disabled={downloading}>
          <Icon.Download color='inherit' fontSize='large' />
        </IconButton>
      </Tooltip>
      <Tooltip title={`${t('delete_presentation')}`} placement='top'>
        <IconButton onClick={deletePresentation} size='medium' disabled={downloading}>
          <Icon.Delete color='error' fontSize='large' />
        </IconButton>
      </Tooltip>
    </div>
  )

  return (
    <div className={c.container}>
      <div className={c.detailsContainer}>
        <Typography variant='h5'>{presentation.name}</Typography>
        {userRole === ROLE.SUPERUSER && <SuperuserActions />}
      </div>

      <div className={c.detailsContainer}>
        <div className={c.instructions}>
          {presentation.licenseName && <SubscriptionTag subscription={presentation.licenseName} />}
          <Typography variant='body2'>
            {presentation.defaultIntroText && <TextPreview text={presentation.defaultIntroText} />}
          </Typography>
        </div>
        <UserActions />
      </div>
    </div>
  )
}

export default ListItem
