import { createActions, createReducer } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  getPresentation: ['id'],
  getPresentationSuccess: ['presentation'],
  getPresentationFailure: null,
  setPresentation: ['presentation'],
  setPresentationFinished: ['presentation'],
  sendToVisualizerCms: ['payload', 'onSuccess'],
  sendToVisualizerCmsFinished: null,
  downloadPresentation: ['id'],
  downloadPresentationFinished: null,
  restorePresentation: ['id'],
  restorePresentationFinished: null,
  revertPresentation: ['id'],
  revertPresentationFinished: null,
  deletePresentation: ['id'],
  deletePresentationFinished: null,
  clearPresentation: null,
})

export const PresentationTypes = Types
export default Creators

/* ------------- Initial State ------------- */
export const INITIAL_STATE: PresentationStore = Immutable({
  fetching: false,
  updating: false,
  downloading: false,
  content: null,
})

/* ------------- Reducers ------------- */
const getPresentation = (state: any, { id }: { id: number }) => state.merge({ fetching: true })

const getPresentationSuccess = (state: any, { presentation }: { presentation: FullPresentation }) =>
  state.merge({ content: presentation, fetching: false })

const getPresentationFailure = (state: any) => state.merge({ fetching: false })

const setPresentation = (state: any, { presentation }: { presentation: FullPresentation }) =>
  state.merge({ content: presentation, fetching: true, updating: true })

const setPresentationFinished = (state: any, { presentation }: { presentation: FullPresentation | undefined }) =>
  state.merge({
    fetching: false,
    updating: false,
    ...(presentation ? { content: presentation } : {}),
  })

const sendToVisualizerCms = (
  state: any,
  action: {
    payload: { id: string; termsAccepted: boolean }
    onSuccess: Function | undefined
  }
) => state.merge({})

const sendToVisualizerCmsFinished = (state: any) => state.merge({ fetching: false })

const downloadPresentation = (state: any, { id }: { id: number }) => state.merge({ downloading: true })
const downloadPresentationFinished = (state: any) => state.merge({ downloading: false })

const restorePresentation = (state: any, { id }: { id: number }) => state.merge({ fetching: true })
const restorePresentationFinished = (state: any) => state.merge({ fetching: false })

const revertPresentation = (state: any, { id }: { id: number }) => state.merge({ fetching: true })
const revertPresentationFinished = (state: any) => state.merge({ fetching: false })

const deletePresentation = (state: any, { id }: { id: number }) => state.merge({ fetching: true })
const deletePresentationFinished = (state: any) => state.merge({ fetching: false })

const clearPresentation = (state: any) => INITIAL_STATE

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_PRESENTATION]: getPresentation,
  [Types.GET_PRESENTATION_SUCCESS]: getPresentationSuccess,
  [Types.GET_PRESENTATION_FAILURE]: getPresentationFailure,
  [Types.SET_PRESENTATION]: setPresentation,
  [Types.SET_PRESENTATION_FINISHED]: setPresentationFinished,
  [Types.SEND_TO_VISUALIZER_CMS]: sendToVisualizerCms,
  [Types.SEND_TO_VISUALIZER_CMS_FINISHED]: sendToVisualizerCmsFinished,
  [Types.DOWNLOAD_PRESENTATION]: downloadPresentation,
  [Types.DOWNLOAD_PRESENTATION_FINISHED]: downloadPresentationFinished,
  [Types.RESTORE_PRESENTATION]: restorePresentation,
  [Types.RESTORE_PRESENTATION_FINISHED]: restorePresentationFinished,
  [Types.REVERT_PRESENTATION]: revertPresentation,
  [Types.REVERT_PRESENTATION_FINISHED]: revertPresentationFinished,
  [Types.DELETE_PRESENTATION]: deletePresentation,
  [Types.DELETE_PRESENTATION_FINISHED]: deletePresentationFinished,
  [Types.CLEAR_PRESENTATION]: clearPresentation,
})
