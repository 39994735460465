import { createMuiTheme } from '@material-ui/core/styles'
import { Colors } from './colors'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: Colors.primary,
    },
    secondary: {
      main: Colors.secondary,
    },
  },
  typography: {
    fontFamily: 'Lato',
    h1: {
      fontStyle: 'normal',
      fontWeight: 900,
      fontSize: '64px',
      lineHeight: '77px',
      textTransform: 'uppercase',
      color: Colors.black,
    },
    h2: {
      fontStyle: 'normal',
      fontWeight: 900,
      fontSize: '48px',
      lineHeight: '58px',
      textTransform: 'uppercase',
      color: Colors.black,
    },
    h3: {
      fontStyle: 'normal',
      fontWeight: 900,
      fontSize: '36px',
      lineHeight: '43px',
      textTransform: 'uppercase',
      textAlign: 'center',
      color: Colors.black,
    },
    h4: {
      fontStyle: 'normal',
      fontWeight: 900,
      fontSize: '24px',
      lineHeight: '29px',
      textTransform: 'uppercase',
      color: Colors.black,
    },
    h5: {
      fontStyle: 'normal',
      fontWeight: 900,
      fontSize: '22px',
      lineHeight: '26px',
      color: Colors.black,
    },
    h6: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '22px',
      lineHeight: '40px',
      color: Colors.black,
      textAlign: 'center',
    },
    subtitle1: {
      fontStyle: 'normal',
      fontWeight: 900,
      fontSize: '18px',
      lineHeight: '30px',
      color: Colors.black,
      textAlign: 'center',
    },
    subtitle2: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '18px',
      lineHeight: '30px',
      textTransform: 'uppercase',
      color: Colors.black,
    },
    body1: {
      fontStyle: 'normal',
      fontWeight: 300,
      fontSize: '20px',
      lineHeight: '24px',
      color: Colors.text,
    },
    body2: {
      fontStyle: 'normal',
      fontWeight: 300,
      fontSize: '16px',
      lineHeight: '19px',
      color: Colors.text,
    },
  },
})

export default theme
