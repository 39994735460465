import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core'
import { Colors } from '../theme/colors'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fullScreenContainer: {
      position: 'fixed',
      top: 0,
      left: 0,
      zIndex: 9999,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: Colors.white,
      height: '100vh',
      width: '100vw',
      opacity: 0.7,
    },
    container: {
      display: 'flex',
      flex: 'auto',
      justifyContent: 'center',
    },
    spinner: {
      display: 'inline',
      animation: `$rotating 1s linear infinite`,
      borderRadius: '50%',
      border: '8px solid transparent',
      borderTop: '8px solid #ccc',
      borderBottom: '8px solid #ccc',
      width: '60px',
      height: '60px',
    },
    progressText: {
      color: Colors.primary,
      fontWeight: 'bold',
      fontSize: '1.5rem',
    },
    primaryColor: {
      borderTop: `8px solid ${Colors.primary}`,
      borderBottom: `8px solid ${Colors.primary}`,
    },
    sm: {
      borderWidth: '3px',
      width: '24px',
      height: '24px',
    },
    md: {
      borderWidth: '5px',
      width: '32px',
      height: '32px',
    },
    '@keyframes rotating': {
      from: {
        transform: 'rotate(0deg)',
      },
      to: {
        transform: 'rotate(360deg)',
      },
    },
  })
)

interface Props {
  size?: 'small' | 'medium' | 'large'
  isFullScreen?: boolean
  progressText?: string
}

const Spinner: React.FC<Props> = ({ size = 'large', isFullScreen = false, progressText }) => {
  const c = useStyles()
  const spinnerSize = size === 'small' ? c.sm : size === 'medium' ? c.md : ''
  const spinnerColor = isFullScreen ? c.primaryColor : ''

  return (
    <div className={isFullScreen ? c.fullScreenContainer : c.container}>
      <div className={`${c.spinner} ${spinnerSize} ${spinnerColor}`}></div>&nbsp;
      {progressText && <div className={c.progressText}>{progressText}</div>}
    </div>
  )
}

export default Spinner
