import React, { Dispatch, SetStateAction } from 'react'
import {
  makeStyles,
  Theme,
  createStyles,
  Typography,
  IconButton,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Colors } from '../theme/colors'
import { Close } from '../theme/Icons'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: 'relative',
      padding: '10px',
      backgroundColor: 'rgba(255, 0, 0, .06)',
      border: `1px solid ${Colors.error}`,
      borderRadius: '5px',
    },
    closeButton: {
      position: 'absolute',
      top: '5px',
      right: '5px',
    },
    imageList: {
      listStyleType: 'none',
      margin: '10px 0',
      padding: '0',
    },
    reasonText: {
      color: Colors.error,
    },
  })
)

interface Props {
  images: UploadFailImage[]
  setImages: Dispatch<SetStateAction<UploadFailImage[]>>
}

const ImageFailList: React.FC<Props> = ({ images, setImages }) => {
  const c = useStyles()
  const { t } = useTranslation()

  const handleClose = () => {
    setImages([])
  }

  if (!images.length) return null

  return (
    <div className={c.container}>
      <IconButton className={c.closeButton} onClick={handleClose}>
        <Close fontSize='small' />
      </IconButton>
      <Typography variant='subtitle2'>{t('upload_failed')}:</Typography>
      <ul className={c.imageList}>
        {images.map((image, i) => (
          <li key={`image-${i}`}>
            <Typography variant='body2'>{image.name}</Typography>
            <ul>
              {image.reasons.map((reason, i) => (
                <li key={`reason-${i}`} className={c.reasonText}>
                  {reason}
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default ImageFailList
