import { createActions, createReducer } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  getPresentations: null,
  getPresentationsSuccess: ['presentations'],
  getPresentationsFailure: null,
})

export const PresentationsTypes = Types
export default Creators

/* ------------- Initial State ------------- */
export const INITIAL_STATE: PresentationsStore = Immutable({
  fetching: false,
  content: null,
})

/* ------------- Reducers ------------- */

const getPresentations = (state: any) => state.merge({ fetching: true })

const getPresentationsSuccess = (
  state: any,
  { presentations }: { presentations: Presentation[] }
) => state.merge({ content: presentations, fetching: false })

const getPresentationsFailure = (state: any) => state.merge({ fetching: false })

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_PRESENTATIONS]: getPresentations,
  [Types.GET_PRESENTATIONS_SUCCESS]: getPresentationsSuccess,
  [Types.GET_PRESENTATIONS_FAILURE]: getPresentationsFailure,
})
