import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Button from '../../components/Button'

import FileDropzone from '../FileDropzone'

const Container = styled.div`
  padding: 1rem 2rem;

  .title {
    background-color: #53b8bc;
    color: #fff;
    padding: 1rem;
    font-size: 18px;
    font-weight: bold;
  }

  .content {
    display: flex;
    padding: 1rem 2rem;
    justify-content: space-between;
    background-color: #e6e6e6;
  }

  .actions-wrapper {
    display: flex;
    justify-content: flex-end;
  }
`

interface NewEmbeddedGraphProps {
  visible?: boolean
  chart?: EmbeddedChart
  onSave: (name: string, file?: FileAsset) => void
  onCancel: () => void
}

const NewEmbeddedChart: React.FC<NewEmbeddedGraphProps> = ({ visible = false, onSave, onCancel, chart }) => {
  const [chartName, setChartName] = useState('')
  const [file, setFile] = useState<FileAsset>()

  useEffect(() => {
    if (chart) {
      setChartName(chart.name)
    }
  }, [chart])

  if (!visible) return null

  const handleSave = () => {
    //TODO: verify file when creating
    onSave(chartName, file)
    if (chart) {
      setChartName(chart.name)
    } else {
      setChartName('')
    }
    setFile(undefined)
  }

  const handleCancel = () => {
    // when editing, reset name to chart name instead of clearing
    if (chart) {
      setChartName(chart.name)
    } else {
      setChartName('')
    }

    setFile(undefined)
    onCancel()
  }

  return (
    <Container>
      <div>
        <input type='text' placeholder='Chart name' value={chartName} onChange={(e) => setChartName(e.target.value)} />
        <FileDropzone
          fileLimit={1}
          files={file ? [file] : []}
          acceptedFormats={[
            '.csv',
            'application/vnd.ms-excel',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          ]}
          setFile={setFile}
        />
      </div>
      <div className='actions-wrapper'>
        <Button text='Cancel' onClick={handleCancel} />
        <Button color='primary' text='Save' onClick={handleSave} />
      </div>
    </Container>
  )
}

export default NewEmbeddedChart
