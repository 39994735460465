import React, { useState } from 'react'
import styled from 'styled-components'
import Button from '../Button'

const Container = styled.div`
  .title {
    background-color: #53b8bc;
    color: #fff;
    padding: 1rem;
    font-size: 18px;
    font-weight: bold;
  }

  .content {
    display: flex;
    padding: 1rem 2rem;
    justify-content: space-between;
    background-color: #e6e6e6;
  }

  .name-input {
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    border: 1px solid #555;
  }
`

interface NewEmbeddedGraphFolderProps {
  visible?: boolean
  onSave: (folderName: string) => void
  onCancel: () => void
}

const NewEmbeddedChartFolder: React.FC<NewEmbeddedGraphFolderProps> = ({ visible = false, onSave, onCancel }) => {
  const [folderName, setFolderName] = useState('')

  if (!visible) return null

  const displayName = folderName ? folderName : 'New embedded graph folder'

  const handleSave = () => {
    setFolderName('')
    onSave(folderName)
  }

  const handleCancel = () => {
    setFolderName('')
    onCancel()
  }

  return (
    <Container>
      <div className='title'>{displayName}</div>
      <div className='content'>
        <input
          className='name-input'
          type='text'
          placeholder='Folder name'
          value={folderName}
          onChange={(e) => setFolderName(e.target.value)}
        />
        <div>
          <Button text='Cancel' onClick={handleCancel} />
          <Button color='primary' text='Save' onClick={handleSave} />
        </div>
      </div>
    </Container>
  )
}

export default NewEmbeddedChartFolder
