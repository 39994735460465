import React from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core'
import { removeHtmlTags } from '../utils/transforms'
import { EN_DASH_CHAR } from '../utils/constants'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    text: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      '-webkit-line-clamp': 3,
      '-webkit-box-orient': 'vertical',
    },
  })
)

interface Props {
  text: string
}

const TextPreview: React.FC<Props> = ({ text, children }) => {
  const c = useStyles()
  const plainText = removeHtmlTags(text)

  // Preview text as a plain text (without html) and limit to 3 lines of it
  return (
    <span className={c.text}>
      {children}
      {` ${plainText.length ? plainText : EN_DASH_CHAR}`}
    </span>
  )
}

export default TextPreview
