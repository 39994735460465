import React, { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import PropertyPicker from './PropertyPicker'
import SelectedProperties from './SelectedProperties'

const BreadCrumbs = styled.div`
  font-weight: bold;
  padding: 1.5rem 0;
  display: flex;
  align-items: center;

  .names {
    display: flex;
    flex-grow: 1;
  }

  .separator {
    height: 1rem;
    width: 1rem;
    margin: 0 1rem;
  }

  .addproperty {
    margin-left: 1.5rem;
    color: #d0d1d2;
    border: none;
    background-color: transparent;
    font-weight: bold;
    font-size: 16px;
    cursor: pointer;
  }
`

interface BreadcrumbsProps {
  onPropertySelect: (properties: string[]) => void
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ onPropertySelect }) => {
  const [propertyPickerVisible, setPropertyPickerVisible] = useState(false)
  const { t } = useTranslation()

  const handlePropertyPickerApply = (properties: string[]) => {
    setPropertyPickerVisible(false)
    onPropertySelect(properties)
  }

  const handlePropertyPickerClose = () => {
    setPropertyPickerVisible(false)
  }

  return (
    <BreadCrumbs>
      <div className='names'>
        <SelectedProperties />
        <button className='addproperty' onClick={() => setPropertyPickerVisible(true)}>
          + {t('analytics.select_properties')}
        </button>
      </div>
      <PropertyPicker
        onClose={handlePropertyPickerClose}
        onApply={handlePropertyPickerApply}
        visible={propertyPickerVisible}
      />
    </BreadCrumbs>
  )
}

export default Breadcrumbs
