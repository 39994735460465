// Storage keys
export const KEYS = {
  AUTH_TOKEN: 'AUTH_TOKEN',
  USER: 'USER',
  CATEGORY_DRAFT: 'CATEGORY_DRAFT',
}

const read = (key: string) => {
  if (window.localStorage) {
    const value = window.localStorage.getItem(key)
    if (value) return JSON.parse(value)
  }
  return null
}

const write = (key: string, value: Object | null) => {
  if (window.localStorage) {
    window.localStorage.setItem(key, JSON.stringify(value))
  }
}

const sessionRead = (key: string) => {
  if (window.sessionStorage) {
    const value = window.sessionStorage.getItem(key)
    if (value) return JSON.parse(value)
  }
  return null
}

const sessionWrite = (key: string, value: Object | null) => {
  if (window.sessionStorage) {
    window.sessionStorage.setItem(key, JSON.stringify(value))
  }
}

export default {
  KEYS,
  read,
  write,
  sessionRead,
  sessionWrite,
}
