import React from 'react'
import styled from 'styled-components'

import Spinner from '../Spinner'

const LoadingOverlayContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.35);
  z-index: 30;
`

type LoadingOverlayProps = {
  visible: boolean
}

const LoadingOverlay: React.FC<LoadingOverlayProps> = ({ visible }) => {
  if (!visible) return null

  return (
    <LoadingOverlayContainer>
      <Spinner />
    </LoadingOverlayContainer>
  )
}

export default LoadingOverlay
