import React from 'react'
import useWindowDimensions from '../../utils/hooks/useWindowDimensions'
import InstructionsButton from './InstructionsButton'
import Sidebar from './Sidebar'
import styled from 'styled-components'

const Container = styled.div<{ showScrollBar: boolean }>`
  display: flex;
  justify-content: space-between;
  height: 100vh;
  overflow-y: ${(p) => (p.showScrollBar ? 'scroll' : 'auto')};
`

const MainContentArea = styled.main<{ fullWidth: boolean }>`
  flex: 1;
  display: flex;
  justify-content: center;
  margin-top: 40px;
  margin-left: 300px; // Same as sidebar's width!

  > div {
    position: ${(p) => !p.fullWidth && 'relative'};
    width: ${(p) => (p.fullWidth ? '95%' : '60%')};
  }
`

interface Props {
  fullWidth?: boolean
  showScrollBar?: boolean
  children: React.ReactNode
}

const Layout: React.FC<Props> = ({ fullWidth = false, showScrollBar = false, children }) => {
  const { windowWidth } = useWindowDimensions()

  return (
    <Container showScrollBar={showScrollBar || fullWidth}>
      <Sidebar />

      <MainContentArea fullWidth={fullWidth || windowWidth < 1200}>
        <div>
          {children}
          <InstructionsButton isInsideContainer={fullWidth || windowWidth < 1200} />
        </div>
      </MainContentArea>

      <div></div>
    </Container>
  )
}

export default Layout
