import { call, put, select } from 'redux-saga/effects'
import { AnyAction } from 'redux'
import UserActions from '../redux/UserRedux'
import ErrorActions from '../redux/ErrorRedux'
import Storage from '../utils/storage'
import { composeError } from '../utils/transforms'

// TODO: Fix type!
export function* login(api: any, action: AnyAction): any {
  const { username, password } = action
  const response = yield call(api.login, username, password)

  if (response.ok) {
    const { token, role, termsAccepted } = response.data
    const user = { username, role, termsAccepted }
    yield put(UserActions.loginSuccess(user, token))
    Storage.write(Storage.KEYS.AUTH_TOKEN, token)
    Storage.write(Storage.KEYS.USER, { username, termsAccepted })
  } else {
    const error = composeError(response, 'login_error')
    yield put(ErrorActions.setError(error))
    yield put(UserActions.loginFailure())
  }
}

export function* getRole(api: any): any {
  const { token } = yield select((state) => state.user)
  const response = yield call(api.getRole, token)

  if (response.ok) {
    const { role } = response.data
    yield put(UserActions.getRoleSuccess(role))
  } else {
    const error = composeError(response, 'get_role_error')
    yield put(ErrorActions.setError(error))
  }
}

// TODO: Fix type!
export function* logout(api: any): any {
  Storage.write(Storage.KEYS.AUTH_TOKEN, null)
  Storage.write(Storage.KEYS.USER, null)

  const { token } = yield select((state) => state.user)
  const response = yield call(api.logout, token)
  if (response.ok) {
    yield put(UserActions.logoutSuccess())
  } else {
    // Unauthorized error means that token is not found from api (means that user's not logged in)
    if (response.status === 401) yield put(UserActions.logoutSuccess())
    else {
      const error = composeError(response, 'logout_error')
      yield put(ErrorActions.setError(error))
      yield put(UserActions.logoutFailure())
    }
  }
}

export function* acceptTerms(api: any): any {
  const { token } = yield select((state) => state.user)

  const response = yield call(api.acceptTerms, token)
  if (response.ok) {
    yield put(UserActions.acceptTermsSuccess())
    const user = Storage.read(Storage.KEYS.USER)
    Storage.write(Storage.KEYS.USER, { ...user, termsAccepted: true })
  } else {
    const error = composeError(response, 'accept_terms_error')
    yield put(ErrorActions.setError(error))
    yield put(UserActions.acceptTermsFailure())
  }
}
