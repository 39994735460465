import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import CheckIcon from '@material-ui/icons/Check'
import AddIcon from '@material-ui/icons/Add'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { PRESENTATION_PERMISSIONS } from '../utils/constants'

const PropertyPickerContainer = styled.div`
  .backdrop {
    position: absolute;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.25);
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal {
    display: flex;
    flex-direction: column;
    margin: 1rem;
    width: 400px;
    min-height: 300px;
    max-width: 100%;
    background-color: white;
    box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.1);
    border-radius: 1rem;
    padding: 1rem;
    max-height: 100%;

    @media (min-width: 1024px) {
      max-height: 600px;
    }
  }

  .search-wrapper {
    margin-bottom: 1rem;
  }

  .search-input {
    border: 1px solid #e8e8e8;
    width: 100%;
    padding: 0.5rem;
    border-radius: 0.5rem;
    margin-top: 1rem;
  }

  .unselect-wrapper {
    display: flex;
    justify-content: end;
    margin-bottom: 1rem;
  }

  .property-list-container {
    flex: 1;
    overflow-y: auto;
  }

  .property-list-wrapper {
    border: 1px solid #e8e8e8;
    border-radius: 0.5rem;
  }

  .actions {
    margin-top: 1rem;
    display: flex;
    justify-content: end;
  }

  .button {
    border-radius: 0.25rem;
    border: none;
    font-size: 14px;
    padding: 0.5rem 0.75rem;
    cursor: pointer;
    text-transform: uppercase;
  }

  .cancel-button {
    background: #fff;
    margin-right: 0.5rem;

    &:hover {
      background-color: #ececec;
    }
  }

  .apply-button {
    background-color: #34d399;
    color: #fff;

    &:hover {
      background-color: #1eb77f;
    }
  }
`

const Property = styled.div<{ selected: boolean }>`
  display: flex;
  padding: 0.25rem;
  cursor: pointer;
  background-color: #fff;
  justify-content: space-between;
  align-items: center;
  background-color: ${(props) => (props.selected ? '#34d399' : '#fff')};
  color: ${(props) => (props.selected ? '#fff' : 'inherit')};
  border-bottom: 1px solid #e8e8e8;
  min-height: 2.5rem;

  &:last-of-type {
    border: none;
    border-radius: 0 0 0.5rem 0.5rem;
  }

  &:first-of-type {
    border-radius: 0.5rem 0.5rem 0 0;
  }

  &:hover {
    background-color: ${(props) => (props.selected ? '#34d399' : '#ececec')};

    .add {
      display: ${(props) => (props.selected ? 'none' : 'block')};
    }
  }

  .check {
    display: ${(props) => (props.selected ? 'block' : 'none')};
  }

  .add {
    display: none;
  }
`

type PropertyListProps = {
  onToggle: (propertyId: string) => void
  searchTerm?: string
  allProperties: Presentation[]
  selectedProperties: string[]
}

type PropertyPickerProps = {
  onClose: () => void
  onApply: (properties: string[]) => void
  visible: boolean
}

const sortByName = (a: Presentation, b: Presentation) => {
  const nameA = a.name.toLowerCase()
  const nameB = b.name.toLowerCase()

  if (nameA > nameB) {
    return 1
  }

  if (nameA < nameB) {
    return -1
  }

  return 0
}

const PropertyList: React.FC<PropertyListProps> = ({ searchTerm, allProperties, onToggle, selectedProperties }) => {
  const propertiesWithAccess = [...allProperties]
    .sort(sortByName)
    .filter((property) => property.permissions.includes(PRESENTATION_PERMISSIONS.ANALYTICS_ACCESS))

  const mapProperties = (property: Presentation) => {
    return (
      <Property
        key={property.id}
        onClick={() => onToggle(property.id)}
        selected={selectedProperties.includes(property.id)}
      >
        <div>{property.name}</div>
        <div className='icon-wrapper'>
          <CheckIcon className='check' />
          <AddIcon className='add' />
        </div>
      </Property>
    )
  }

  if (!searchTerm) return <>{propertiesWithAccess.map(mapProperties)}</>

  return (
    <div>
      {propertiesWithAccess
        .filter((property) => property.name.toLowerCase().includes(searchTerm.trim().toLowerCase()))
        .map(mapProperties)}
    </div>
  )
}

const PropertyPicker: React.FC<PropertyPickerProps> = ({ onClose, onApply, visible }) => {
  const storedSelectedProperties = useSelector((state: RootStore) => state.analytics.selectedProperties)
  const presentations = useSelector((state: RootStore) => state.presentations.content)

  const [searchTerm, setSearchTerm] = useState('')
  const [selectedIds, setSelectedIds] = useState<string[]>([])

  const { t } = useTranslation()

  useEffect(() => {
    setSelectedIds(storedSelectedProperties)
  }, [storedSelectedProperties])

  const handlePropertyToggle = (selectedId: string) => {
    if (selectedIds.includes(selectedId)) {
      const cleared = selectedIds.filter((id) => id !== selectedId)
      setSelectedIds(cleared)
    } else {
      setSelectedIds([...selectedIds, selectedId])
    }
  }

  if (!visible) return null

  const handleApply = () => {
    onApply(selectedIds)
  }

  const handleClose = () => {
    setSelectedIds(storedSelectedProperties)
    onClose()
  }

  const handleUnselect = () => {
    setSelectedIds([])
  }

  return (
    <PropertyPickerContainer>
      <div className='backdrop' onClick={handleClose}>
        <div className='modal' onClick={(e) => e.stopPropagation()}>
          <div className='header'>{t('analytics.select_properties')}</div>
          <div className='search-wrapper'>
            <input
              className='search-input'
              type='text'
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder={t('analytics.filter_properties')}
            ></input>
          </div>
          <div className='unselect-wrapper'>
            <button onClick={handleUnselect} className='button unselect-button'>
              {t('analytics.unselect_all')}
            </button>
          </div>
          <div className='property-list-container'>
            <div className='property-list-wrapper'>
              <PropertyList
                searchTerm={searchTerm}
                allProperties={presentations || []}
                onToggle={handlePropertyToggle}
                selectedProperties={selectedIds}
              />
            </div>
          </div>
          <div className='actions'>
            <button onClick={handleClose} className='button cancel-button'>
              {t('analytics.cancel')}
            </button>
            <button onClick={handleApply} className='button apply-button'>
              {t('analytics.apply')}
            </button>
          </div>
        </div>
      </div>
    </PropertyPickerContainer>
  )
}

export default PropertyPicker
