import React, { Dispatch, SetStateAction } from 'react'
import {
  Typography,
  makeStyles,
  Theme,
  createStyles,
  FormControl,
  Select,
  Chip,
  MenuItem,
  Input,
  InputLabel,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Colors } from '../../theme/colors'
import HelpTip from '../HelpTip'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      margin: '50px 0',
    },
    formControl: {
      display: 'block',
      marginTop: '15px',
      borderRadius: '5px',
      minWidth: '200px',
    },
    inputField: {
      '&:before': {
        borderBottom: `2px solid ${Colors.gray}`,
      },
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      '&:first-child': {
        backgroundColor: Colors.primary,
      },
      margin: 2,
      backgroundColor: Colors.secondary,
      color: Colors.white,
      textTransform: 'uppercase',
      fontWeight: 400,
    },
    menuItem: {},
    selectedMenuItem: {
      backgroundColor: `${Colors.secondary} !important`,
      color: Colors.white,
      fontWeight: 400,
    },
  })
)

interface Props {
  languages: SelectableLanguage[]
  setLanguages: Dispatch<SetStateAction<SelectableLanguage[]>>
}

const LanguageSelector: React.FC<Props> = ({ languages, setLanguages }) => {
  const { t } = useTranslation()
  const c = useStyles()

  const handleChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    const selectedValues = e.target.value as Language[]
    setLanguages(
      languages.map((lang) => ({
        ...lang,
        isSelected: selectedValues.includes(lang.code),
      }))
    )
  }

  return (
    <div className={c.container}>
      <Typography variant='h5'>
        {languages.length > 1 ? t('language_selection') : t('supported_language')}
        <HelpTip helpTextKey='languageSelection' />
      </Typography>

      {/* Nothing to select if only one language is supported: */}
      {languages.length === 1 && (
        <div className={c.chips}>
          <Chip key={languages[0].code} label={languages[0].code} className={c.chip} />
        </div>
      )}

      {/* Select supported languages: */}
      {languages.length > 1 && (
        <FormControl className={c.formControl}>
          <InputLabel id='language-selector-label'>{t('supported_languages')}</InputLabel>

          <Select
            labelId='language-selector-label'
            id='language-selector'
            multiple
            value={languages.filter((l) => l.isSelected).map((l) => l.code)}
            onChange={handleChange}
            input={<Input id='select-languages' className={c.inputField} />}
            renderValue={(selected) => (
              <div className={c.chips}>
                {(selected as string[]).map((value) => (
                  <Chip key={value} label={value} className={c.chip} />
                ))}
              </div>
            )}
          >
            {languages
              .filter(({ isDefault }) => !isDefault)
              .map((lang) => (
                <MenuItem
                  key={lang.code}
                  value={lang.code}
                  className={!lang.isSelected ? c.menuItem : c.selectedMenuItem}
                >
                  {t(`languages.${lang.code}`)}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      )}
    </div>
  )
}

export default LanguageSelector
