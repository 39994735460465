import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import htmlToDraft from 'html-to-draftjs'
import { ContentState, convertToRaw, EditorState } from 'draft-js'
import HtmlEditor from './HtmlEditor'
import draftToHtml from 'draftjs-to-html'
import Button from './Button'
import Input from './Input'

const ToggleButton = styled.div`
  :hover {
    text-decoration: underline;
    cursor: pointer;
  }
`

interface Props {
  texts: Translation[]
  onSave: (texts: Translation[]) => void
  title?: string
  isHtmlEditor?: boolean
}

const TranslationsEditDialog: React.FC<Props> = ({ texts, onSave, title = '', isHtmlEditor = false, children }) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)

  const [plainTexts, setPlainTexts] = useState<Translation[]>(texts)
  const [editorTexts, setEditorTexts] = useState<EditorTranslation[]>([])
  const [unsavedChanges, setUnsavedChanges] = useState(false)

  // Set initial editor texts:
  useEffect(() => {
    if (isHtmlEditor && texts && open) {
      setEditorTexts(
        [...texts].map((translation: Translation) => {
          const draftText = htmlToDraft(translation.text)
          const contentState = ContentState.createFromBlockArray(draftText.contentBlocks, draftText.entityMap)
          return {
            lang: translation.lang,
            text: EditorState.createWithContent(contentState),
          }
        })
      )
    } else if (texts && open) {
      setPlainTexts(texts)
    }
  }, [isHtmlEditor, open, texts])

  const toggleDialog = () => {
    setOpen(!open)
  }

  const handleTextChance = (lang: Language) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const text = e.currentTarget.value
    setUnsavedChanges(true)
    setPlainTexts(
      plainTexts.map((translation: Translation) => (lang !== translation.lang ? translation : { lang, text }))
    )
  }

  const handleEditorTextChange = (lang: string) => (text: EditorState) => {
    setUnsavedChanges(true)
    setEditorTexts(
      editorTexts.map((editorText: EditorTranslation) => (lang !== editorText.lang ? editorText : { lang, text }))
    )
  }

  const handleSave = () => {
    const texts = !isHtmlEditor
      ? plainTexts
      : editorTexts.map((translation: EditorTranslation) => ({
          lang: translation.lang,
          text: draftToHtml(convertToRaw(translation.text.getCurrentContent())).replace(/\n/g, ''), // Remove newline characters
        }))

    onSave(texts)
    setUnsavedChanges(false)
  }

  const renderPlainInputs = () => (
    <>
      {plainTexts.map((translation: Translation) => (
        <Input
          key={translation.lang}
          infoText={t(`languages.${translation.lang}`)}
          value={translation.text}
          onChange={handleTextChance(translation.lang)}
          data-id={translation.lang}
          id={`${translation.lang}`}
        />
      ))}
    </>
  )

  const renderEditorInputs = () => (
    <>
      {editorTexts.map((editorText: EditorTranslation) => (
        <HtmlEditor
          key={editorText.lang}
          title={t(`languages.${editorText.lang}`)}
          state={editorText.text}
          onStateChange={handleEditorTextChange(editorText.lang)}
        />
      ))}
    </>
  )

  return (
    <div>
      <ToggleButton onClick={toggleDialog}>{children}</ToggleButton>

      <Dialog
        open={open}
        onClose={toggleDialog}
        aria-labelledby={`${title}-dialog`}
        maxWidth={'md'}
        disableBackdropClick={unsavedChanges}
        disableEscapeKeyDown={unsavedChanges}
      >
        <DialogTitle id={`${title}-dialog-title`}>
          <Typography variant='h5'>{`${t('edit').toUpperCase()}: ${title}`}</Typography>
        </DialogTitle>

        <DialogContent>
          <DialogContentText>{!isHtmlEditor ? renderPlainInputs() : renderEditorInputs()}</DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button text={unsavedChanges ? t('cancel') : t('close')} onClick={toggleDialog} variant='outlined' />
          <Button text={t('save')} onClick={handleSave} color='primary' disabled={!unsavedChanges} />
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default TranslationsEditDialog
