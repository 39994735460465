import React, { useEffect, useState, SetStateAction } from 'react'
import { Dispatch, AnyAction } from 'redux'
import { connect } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { Typography, makeStyles, Theme, createStyles, Divider } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { v4 as uuidv4 } from 'uuid'

import PresentationActions from '../redux/PresentationRedux'
import AddNewDialog from '../components/Presentations/AddNewDialog'
import Layout from '../components/Layout'
import Spinner from '../components/Spinner'
import LanguageSelector from '../components/Presentation/LanguageSelector'
import MapMarkerSelector from '../components/Presentation/MapMarkerSelector'
import Button from '../components/Button'
import TranslationsDialog from '../components/TranslationsDialog'
import ImageUploadDialog from '../components/ImageUploadDialog'
import { DEFAULT_LANGUAGE, STATUS } from '../utils/constants'
import CONSTANT from '../utils/constants'
import ImageUploadZone from '../components/ImageUploadZone'
import Icons from '../theme/Icons'
import Input from '../components/Input'
import FileDropzone from '../components/FileDropzone'
import HContainer from '../components/Layout/HContainer'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainWrapper: {
      backgroundColor: '#fff',
      borderRadius: '1rem',
      padding: '1rem',
      margin: '1.5rem 0',
    },
    blockContainer: {
      margin: '30px 0',
    },
    blockBody: {
      marginLeft: '37px',
      '& > div': {
        marginTop: '40px',
      },
    },
    blockHeaderContainer: {
      display: 'flex',
      flexDirection: 'row',
      cursor: 'pointer',
      userSelect: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    instructions: {
      margin: '30px 0',
    },
    actionButtons: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: '50px 0 100px 0',
      paddingBottom: '100px',
    },
  })
)

interface Props {
  loading: boolean
  presentation: FullPresentation | null
  getPresentation: (id: string) => AnyAction
  setPresentation: (presentation: FullPresentation) => AnyAction
}

const StartPresentation: React.FC<Props> = ({ loading, presentation, getPresentation, setPresentation }) => {
  const c = useStyles()
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const { t } = useTranslation()

  const [languages, setLanguages] = useState<SelectableLanguage[]>([])
  const [introTexts, setIntroTexts] = useState<Translation[]>([])
  const [mapMarker, setMapMarker] = useState<FileAsset>()
  const [requestForProposals, setRequestForProposals] = useState<Translation[]>([])
  const [floorplan, setFloorplan] = useState<FileAsset[]>([])
  const [capacityChart, setCapacityChart] = useState<FileAsset[]>([])
  const [videoLink, setVideoLink] = useState<ExternalLink>()
  const [hygieneGuidelines, setHygieneGuidelines] = useState<FileAsset>()

  const [unsavedChanges, setUnsavedChanges] = useState(false)

  const [initialLoad, setInitialLoad] = useState(true)
  const [basicIsOpen, setBasicIsOpen] = useState(true)
  const [meetingRoomIsOpen, setMeetingRoomIsOpen] = useState(false)
  const [additionalIsOpen, setAdditionalIsOpen] = useState(false)

  const toggleBasic = () => setBasicIsOpen(!basicIsOpen)
  const toggleMeetingRoom = () => setMeetingRoomIsOpen(!meetingRoomIsOpen)
  const toggleAdditional = () => setAdditionalIsOpen(!additionalIsOpen)

  useEffect(() => {
    if (id) getPresentation(id)
  }, [getPresentation, id])

  useEffect(() => {
    setUnsavedChanges(true)
  }, [hygieneGuidelines])

  // Try to populate inputs when presentation was fetched:
  useEffect(() => {
    if (presentation) {
      if (presentation.supportedLanguages.length > 0) setLanguages([...presentation.supportedLanguages])
      else
        setLanguages([
          {
            code: DEFAULT_LANGUAGE,
            isSelected: true,
            isDefault: true,
          },
        ])
      setMapMarker(presentation.mapMarker)
      setFloorplan(presentation.floorplan)
      setCapacityChart(presentation.capacityChart)
      setVideoLink(presentation.videoLink)
      setHygieneGuidelines(presentation.hygieneGuidelines)
      if (initialLoad) {
        setBasicIsOpen(presentation.status === STATUS.START)
        setInitialLoad(false)
      }
    }
    setUnsavedChanges(false)
  }, [initialLoad, presentation])

  const getTextsFromStore = (texts: Translation[] | undefined, languages: SelectableLanguage[]) => {
    return languages.map(({ code }: SelectableLanguage) => {
      let text = ''
      if (texts?.length) {
        // Get text from redux store (if available):
        const textsFromStore = texts.filter((translation: Translation) => code === translation.lang)
        if (textsFromStore.length) text = textsFromStore[0].text
      }
      return {
        lang: code,
        text,
      }
    })
  }

  // Set initial texts
  useEffect(() => {
    if (languages) {
      const selectedLanguages = languages.filter((l) => l.isSelected)
      setIntroTexts(getTextsFromStore(presentation?.introTexts, selectedLanguages))
      setRequestForProposals(getTextsFromStore(presentation?.requestForProposal, selectedLanguages))
    }
    setUnsavedChanges(false)
  }, [languages, presentation])

  const handleSave = () => {
    const translationsInSelectedLanguages = (t: Translation) =>
      languages
        .filter((l) => l.isSelected)
        .map((l) => l.code)
        .includes(t.lang)

    if (presentation) {
      setPresentation({
        ...presentation,
        status: STATUS.DRAFT,
        supportedLanguages: languages,
        defaultLanguage: languages[0].code,
        introTexts,
        mapMarker,
        floorplan,
        capacityChart,
        requestForProposal: requestForProposals,
        videoLink,
        hygieneGuidelines,

        // Remove unused translations from categories in case languages changed
        categories: presentation.categories.map((category) => ({
          ...category,
          name: category.name.filter(translationsInSelectedLanguages),
          description: category.description.filter(translationsInSelectedLanguages),
        })),
      })
      setUnsavedChanges(false)
    }
  }

  const handleCancel = () => {
    history.goBack()
  }

  const setState = (setter: React.Dispatch<SetStateAction<any>>) => (value: any) => {
    setUnsavedChanges(true)
    setter(value)
  }

  const handleVideoLinkChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUnsavedChanges(true)
    setVideoLink({
      id: videoLink?.id ?? uuidv4(),
      url: event.currentTarget.value ?? '',
    })
  }

  const BlockHeader: React.FC<{ onClick: () => void; isOpen: boolean; title?: string; infoText?: string }> = ({
    onClick,
    isOpen,
    title = '',
    infoText = '',
  }) => (
    <div onClick={onClick}>
      <div className={c.blockHeaderContainer}>
        {isOpen ? <Icons.CaretDown fontSize='large' /> : <Icons.CaretRight fontSize='large' />}
        <div>
          <Typography variant='h4'>{title}</Typography>
          <Typography variant='body2'>{infoText}</Typography>
        </div>
      </div>
    </div>
  )

  const ActionButtons = () => (
    <div className={c.actionButtons}>
      <Button text={t(unsavedChanges ? 'cancel' : 'go_back')} size='large' onClick={handleCancel} />

      <div>
        <Button text={t('continue')} color='secondary' size='large' onClick={handleSave} disabled={!unsavedChanges} />
        <AddNewDialog
          presentationId={id}
          triggerButtonProps={{
            text: t('next'),
            color: 'primary',
            size: 'large',
            disabled: !!unsavedChanges,
          }}
        />
      </div>
    </div>
  )

  if (!presentation) {
    return <Spinner />
  }

  return (
    <HContainer>
      <div className={c.mainWrapper}>
        {loading && <Spinner isFullScreen progressText={t('saving')} />}

        <Typography variant='h3'>{presentation.name}</Typography>
        <Typography variant='body1' className={c.instructions}>
          {t('presentation_instructions')}
        </Typography>

        {/* BLOCK FOR PRESENTATION'S BASIC INFORMATION */}
        <Divider />
        <div className={c.blockContainer}>
          <BlockHeader
            onClick={toggleBasic}
            isOpen={basicIsOpen}
            title={t('basic_information')}
            infoText={t('basic_info_content')}
          />
          {basicIsOpen && (
            <div className={c.blockBody}>
              <LanguageSelector languages={languages} setLanguages={setState(setLanguages)} />
              <TranslationsDialog
                inputLabel={t('intro_text')}
                labelSize='h5'
                texts={introTexts}
                setTexts={setState(setIntroTexts)}
                helpTipKey='venueDescription'
                editor
              />
              <MapMarkerSelector marker={mapMarker} setMarker={setState(setMapMarker)} />
            </div>
          )}
        </div>

        {/* BLOCK FOR PRESENTATION'S MEETING ROOM RELATED INFORMATION */}
        <Divider />
        <div className={c.blockContainer}>
          <BlockHeader
            onClick={toggleMeetingRoom}
            isOpen={meetingRoomIsOpen}
            title={t('meetings_and_events_details')}
            infoText={t('meeting_room_info_content')}
          />
          {meetingRoomIsOpen && (
            <div className={c.blockBody}>
              <ImageUploadDialog
                title={t('floorplans')}
                titleSize='h5'
                imageCount={floorplan.length}
                helpTipKey='floorplans'
              >
                <ImageUploadZone
                  minWidth={CONSTANT.FLOORPLAN_MIN_WIDTH}
                  images={floorplan}
                  setImages={setState(setFloorplan)}
                />
              </ImageUploadDialog>
              <ImageUploadDialog
                title={t('capacity_charts')}
                titleSize='h5'
                imageCount={capacityChart.length}
                helpTipKey='capacityCharts'
              >
                <ImageUploadZone
                  minWidth={CONSTANT.CAPACITY_CHART_MIN_WIDTH}
                  images={capacityChart}
                  setImages={setState(setCapacityChart)}
                />
              </ImageUploadDialog>
              <TranslationsDialog
                inputLabel={t('request_for_proposal')}
                infoText={t('request_for_proposal_info')}
                labelSize='h5'
                texts={requestForProposals}
                setTexts={setState(setRequestForProposals)}
                helpTipKey='requestForProposal'
                editor
              />
            </div>
          )}
        </div>

        {/* BLOCK FOR ADDITIONAL INFORMATION */}
        <Divider />
        <div className={c.blockContainer}>
          <BlockHeader
            onClick={toggleAdditional}
            isOpen={additionalIsOpen}
            title={t('additional_information')}
            infoText={t('additional_information_content')}
          />
          {additionalIsOpen && (
            <div className={c.blockBody}>
              <div>
                <Input
                  label={t('video_link')}
                  infoText={t('video_link_info')}
                  labelSize='h5'
                  value={videoLink?.url ?? ''}
                  onChange={handleVideoLinkChange}
                  helpTipKey='videoLink'
                />
              </div>
              <div>
                <FileDropzone
                  label={t('hygiene_guidelines')}
                  labelSize='h5'
                  infoText={t('hygiene_guidelines_info')}
                  fileLimit={1}
                  files={hygieneGuidelines ? [hygieneGuidelines] : []}
                  acceptedFormats={['application/pdf', 'image/jpeg']}
                  setFile={setHygieneGuidelines}
                  helpTipKey='hygieneGuidelines'
                />
              </div>
            </div>
          )}
        </div>

        <Divider />
        <ActionButtons />
      </div>
    </HContainer>
  )
}

const mapStateToProps = (state: RootStore) => {
  return {
    loading: state.presentation.updating,
    presentation: state.presentation.content,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    getPresentation: (id: string) => dispatch(PresentationActions.getPresentation(id)),
    setPresentation: (presentation: FullPresentation) => dispatch(PresentationActions.setPresentation(presentation)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StartPresentation)
