import React from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles, Theme, createStyles, Typography } from '@material-ui/core'

import Dialog from './Dialog'
import { Colors } from '../theme/colors'
import HelpTip from './HelpTip'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      margin: '50px 0 10px 0',
    },
    inputsContainer: {
      minWidth: '50vw',
      maxHeight: '50vh',
      overflowX: 'auto',
      borderTop: `1px solid ${Colors.grayLight}`,
      borderBottom: `1px solid ${Colors.grayLight}`,
    },
    title: {},
    infoText: {
      marginBottom: '12px',
    },
    itemsAddedText: {
      fontWeight: 400,
      display: 'inline',
    },
  })
)

interface Props {
  onTriggerButtonClick?: Function
  title?: string
  titleSize?: 'h4' | 'h5' | 'h6'
  infoText?: string
  imageCount?: number
  helpTipKey?: string
}

const ImageUploadDialog: React.FC<Props> = ({
  onTriggerButtonClick,
  title,
  titleSize = 'h4',
  infoText,
  imageCount,
  children,
  helpTipKey,
}) => {
  const c = useStyles()
  const { t } = useTranslation()

  return (
    <div className={c.container}>
      {title && (
        <Typography variant={titleSize} className={c.title}>
          {title}
          {helpTipKey && <HelpTip helpTextKey={helpTipKey} />}
        </Typography>
      )}
      {infoText && (
        <Typography variant={'body2'} className={c.infoText}>
          {infoText}
        </Typography>
      )}
      <>
        <Dialog
          maxWidth='xl'
          triggerButtonProps={{
            text: t('add_images'),
            variant: imageCount ? 'contained' : 'outlined',
            color: 'secondary',
          }}
          onTriggerButtonClick={onTriggerButtonClick}
          title={title}
          // Dialog itself can't be draggable because image list inside it will be draggable.
          // This would result in conflict with these two draggable components,
          // where dragging would not work as intented.
          draggable={false}
        >
          <div className={c.inputsContainer}>
            <Typography variant='h6'>{infoText}</Typography>
            {children}
          </div>
        </Dialog>

        {!!imageCount && (
          <Typography variant='body2' color='secondary' className={c.itemsAddedText}>
            {imageCount > 1 ? t('images_added', { count: imageCount }) : t('image_added')}
          </Typography>
        )}
      </>
    </div>
  )
}

export default ImageUploadDialog
