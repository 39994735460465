import React from 'react'
import MuiButton, { ButtonProps } from '@material-ui/core/Button'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Colors } from '../theme/colors'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      margin: '2px 8px',
      minWidth: '120px',
      textTransform: 'none',
      fontWeight: 400,
      fontSize: '16px',
      boxShadow: 'none',
      '&:hover': {
        boxShadow: 'none',
      },
    },
    round: {
      borderRadius: '50px',
    },
    square: {
      borderRadius: '5px',
    },
    negativeText: {
      color: Colors.white,
    },
    whiteBorder: {
      border: `1px solid ${Colors.white}`,
    },
    large: {
      fontSize: '20px',
      fontWeight: 900,
      minWidth: '150px',
    },
    fullWidth: {
      width: '100%',
    },
  })
)

export interface Props extends ButtonProps {
  text: string | JSX.Element
  shape?: 'round' | 'square'
  onClick?: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined
}

const Button: React.FC<Props> = ({
  text,
  shape = 'round',
  color = 'default',
  variant = 'contained',
  size = 'small',
  fullWidth = false,
  disabled,
  onClick,
}) => {
  const c = useStyles()

  let classes = c.button

  if (shape === 'square') classes += ' ' + c.square
  else classes += ' ' + c.round

  if (['primary', 'secondary'].includes(color) && variant !== 'outlined')
    classes += ` ${c.negativeText} ${c.whiteBorder}`

  if (size === 'large') classes += ' ' + c.large

  if (fullWidth) classes += ' ' + c.fullWidth

  return (
    <MuiButton variant={variant} color={color} className={classes} onClick={onClick} disabled={disabled}>
      {text}
    </MuiButton>
  )
}

export default Button
