import { Typography } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { Colors } from '../../theme/colors'
import Icons from '../../theme/Icons'

const NAV_ITEMS = [
  {
    to: '/presentations',
    labelKey: 'presentations',
    icon: <Icons.List fontSize='large' />,
  },
  {
    to: '/analytics',
    labelKey: 'analytics',
    icon: <Icons.List fontSize='large' />,
  },
]

const NavList = styled.div`
  margin: 40px 0 20px 0;
  width: 100%;
`

const NavItem = styled(NavLink)`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${Colors.white};
  width: 100%;
  padding: 5px;
  border-top: 1px solid ${Colors.white};
  border-bottom: 1px solid ${Colors.white};
  color: ${Colors.white};
  text-decoration: none;
  cursor: pointer;
  opacity: 0.6;

  :hover {
    opacity: 0.8;
  }

  svg {
    margin-right: 10px;
  }
`

const Nav = () => {
  const { t } = useTranslation()

  return (
    <NavList>
      {NAV_ITEMS.map((item) => (
        <NavItem key={item.labelKey} to={item.to}>
          {item.icon}
          <Typography variant='subtitle2' color='inherit'>
            {t(item.labelKey)}
          </Typography>
        </NavItem>
      ))}
    </NavList>
  )
}

export default Nav
