import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import en from './locales/en.json'

export const languages = [{ name: 'English', value: 'en' }]

i18n.use(initReactI18next).init({
  fallbackLng: languages[0].value,
  lng: 'en',
  resources: {
    en: { translation: en },
  },
})

export default i18n
