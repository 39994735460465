import { call, put, select } from 'redux-saga/effects'
import { AnyAction } from 'redux'
import AdminToolsActions from '../redux/AdminToolsRedux'

// TODO: Fix type!
export function* getEmbeddedCharts(api: any) {
  const { token } = yield select((state) => state.user)
  // @ts-expect-error
  const response = yield call(api.getEmbeddedCharts, token)

  if (response.ok) {
    yield put(AdminToolsActions.getEmbeddedChartsSuccess(response.data))
  } else {
    // TODO: handle
  }
}

export function* createEmbeddedChartFolder(api: any, action: AnyAction) {
  const { folderName } = action
  const { token } = yield select((state) => state.user)
  // @ts-expect-error
  const response = yield call(api.createEmbeddedChartFolder, token, folderName)

  if (response.ok) {
    yield put(AdminToolsActions.createEmbeddedChartFolderSuccess(response.data))
    yield put(AdminToolsActions.getEmbeddedCharts())
  } else {
    // TODO: handle
  }
}

export function* createEmbeddedChart(api: any, action: AnyAction) {
  const { name, file, folderUuid } = action.payload

  const { token } = yield select((state) => state.user)
  // @ts-expect-error
  const response = yield call(api.createEmbeddedChart, file, name, folderUuid, token)

  if (response.ok) {
    yield put(AdminToolsActions.createEmbeddedChartSuccess(response.data))
    yield put(AdminToolsActions.getEmbeddedCharts())
  } else {
    // TODO: handle
  }
}

export function* editEmbeddedChart(api: any, action: AnyAction) {
  const { name, file, folderUuid, chartUuid } = action

  const { token } = yield select((state) => state.user)
  // @ts-expect-error
  const response = yield call(api.editEmbeddedChart, file, name, folderUuid, chartUuid, token)

  if (response.ok) {
    yield put(AdminToolsActions.editEmbeddedChartSuccess(response.data))
    yield put(AdminToolsActions.getEmbeddedCharts())
  } else {
    // TODO: handle
  }
}

export function* deleteEmbeddedChart(api: any, action: AnyAction) {
  const { chartUuid } = action

  const { token } = yield select((state) => state.user)
  // @ts-expect-error
  const response = yield call(api.deleteEmbeddedChart, chartUuid, token)

  if (response.ok) {
    yield put(AdminToolsActions.deleteEmbeddedChartSuccess(response.data))
    yield put(AdminToolsActions.getEmbeddedCharts())
  } else {
    // TODO: handle
  }
}

export function* deleteEmbeddedChartFolder(api: any, action: AnyAction) {
  const { folderUuid } = action.payload

  const { token } = yield select((state) => state.user)
  // @ts-expect-error
  const response = yield call(api.deleteEmbeddedChartFolder, folderUuid, token)

  if (response.ok) {
    yield put(AdminToolsActions.deleteEmbeddedChartFolderSuccess(response.data))
    yield put(AdminToolsActions.getEmbeddedCharts())
  } else {
    // TODO: handle
  }
}
