import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import createSagaMiddleware from 'redux-saga'
// import logger from 'redux-logger'

// creates the store
// Todo: Fix types!
export default (rootReducer: any, rootSaga: any) => {
  /* ------------- Redux Configuration ------------- */

  const middleware = []
  const enhancers = []

  /* ------------- Saga Middleware ------------- */

  const sagaMiddleware = createSagaMiddleware()
  middleware.push(sagaMiddleware)

  /* ------------- Assemble Middleware ------------- */

  enhancers.push(applyMiddleware(...middleware))

  const composedEnhancers = (enhancers: any[]) => {
    if (process.env.NODE_ENV !== 'production')
      return [composeWithDevTools(...enhancers)]
    return enhancers
  }

  const createAppropriateStore = createStore
  const store = createAppropriateStore(
    rootReducer,
    ...composedEnhancers(enhancers)
  )

  // kick off root saga
  let sagasManager = sagaMiddleware.run(rootSaga)

  return {
    store,
    sagasManager,
    sagaMiddleware,
  }
}
