import { createActions, createReducer } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  updateCategory: ['fields'],
  clearCategory: null,
})

export const CategoryTypes = Types
export default Creators

/* ------------- Initial State ------------- */
export const INITIAL_STATE: CategoryStore = Immutable({
  init: undefined,
  id: undefined,
  type: undefined,
  name: undefined,
  description: undefined,
  capacity: undefined,
  images: undefined,
  panoramaImages: undefined,
  floorplan: undefined,
  capacityChart: undefined,
  requestForProposal: undefined,
})

/* ------------- Reducers ------------- */
const updateCategory = (state: any, { fields }: { fields: EditableCategory }) => {
  return state.merge({
    ...fields,
  }) // Todo: Fix Type!
}

const clearCategory = (state: any) => INITIAL_STATE

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.UPDATE_CATEGORY]: updateCategory,
  [Types.CLEAR_CATEGORY]: clearCategory,
})
