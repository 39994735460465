import React, { Dispatch, SetStateAction } from 'react'
import { makeStyles, Theme, createStyles, Typography } from '@material-ui/core'
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  DroppableProvided,
  DraggableProvided,
} from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'

import FileListItem from './FileListItem'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: '100%',
      overflowY: 'auto',
    },
    fileList: {
      listStyleType: 'none',
      margin: '20px 0 0 0',
      padding: '0',
    },
    infoText: {
      textAlign: 'center',
      fontStyle: 'italic',
    },
  })
)

const reorder = (list: FileAsset[], startIndex: number, endIndex: number) => {
  const result = [...list]
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result
}

interface Props {
  files: FileAsset[]
  setFiles?: Dispatch<SetStateAction<FileAsset[]>>
  setFile?: Dispatch<SetStateAction<FileAsset | undefined>>
}

const FileList: React.FC<Props> = ({ files, setFiles, setFile, children }) => {
  const { t } = useTranslation()
  const c = useStyles()

  const handleDelete = (id: string) => {
    if (setFiles) setFiles((prevState) => prevState.filter((img) => img.id !== id))
    if (setFile) setFile(undefined)
  }

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return null
    if (result.destination.index === result.source.index) return null
    if (files && setFiles) setFiles(reorder(files, result.source.index, result.destination.index))
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className={c.container}>
        {children}

        {/* List can't be draggable/editable if setter is not available! */}
        {!setFiles && (
          <ul className={c.fileList}>
            {files.map((image, i) => (
              <FileListItem key={image.id} file={image} onDelete={setFile ? handleDelete : undefined} />
            ))}
          </ul>
        )}

        {/* Items order can be changed by dragging them around */}
        {setFiles && (
          <Droppable droppableId='droppable'>
            {(provided: DroppableProvided) => (
              <div>
                {files.length > 1 && (
                  <Typography variant='body2' className={c.infoText}>
                    {t('arrange_images_by_dnd')}
                  </Typography>
                )}
                <ul ref={provided.innerRef} className={c.fileList} {...provided.droppableProps}>
                  {files.map((item, index) => (
                    <Draggable key={item.id} draggableId={item.id} index={index}>
                      {(provided: DraggableProvided, snapshot) => (
                        <li key={item.id}>
                          {/* 
                              // @ts-ignore */}
                          <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                            <FileListItem file={item} onDelete={handleDelete} />
                          </div>
                        </li>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </ul>
              </div>
            )}
          </Droppable>
        )}
      </div>
    </DragDropContext>
  )
}

export default FileList
