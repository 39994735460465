import React, { useState, useEffect, Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles, Theme, createStyles, Typography } from '@material-ui/core'
import { EditorState, ContentState, convertToRaw } from 'draft-js'
import htmlToDraft from 'html-to-draftjs'
import draftToHtml from 'draftjs-to-html'

import Dialog from './Dialog'
import Input from './Input'
import HtmlEditor from './HtmlEditor'
import TranslationsCount from './TranslationsCount'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      margin: '50px 0',
    },
    inputsContainer: {
      minWidth: '50vw',
      maxHeight: '50vh',
      overflowX: 'auto',
    },
  })
)

interface Props {
  onTriggerButtonClick?: Function
  inputLabel: string
  labelSize?: 'h4' | 'h5' | 'h6'
  infoText?: string
  texts?: Translation[]
  setTexts: Dispatch<SetStateAction<Translation[]>>
  helpTipKey?: string
  editor?: boolean
}

const TranslationsDialog: React.FC<Props> = ({
  onTriggerButtonClick,
  inputLabel,
  labelSize,
  infoText,
  texts,
  setTexts,
  helpTipKey,
  editor = false,
}) => {
  const c = useStyles()
  const { t } = useTranslation()

  const [editorTexts, setEditorTexts] = useState<EditorTranslation[]>([])

  // Set initial editor texts:
  useEffect(() => {
    if (editor && texts) {
      setEditorTexts(
        [...texts].map((translation: Translation) => {
          const draftText = htmlToDraft(translation.text)
          const contentState = ContentState.createFromBlockArray(draftText.contentBlocks, draftText.entityMap)
          return {
            lang: translation.lang,
            text: EditorState.createWithContent(contentState),
          }
        })
      )
    }
  }, [editor, texts])

  const handleTextChance = (lang: Language) => (e: React.ChangeEvent<HTMLInputElement>) => {
    if (texts && setTexts) {
      setTexts(
        texts.map((translation: Translation, i: number) => {
          if (lang === translation.lang)
            return {
              lang: translation.lang,
              text: e.currentTarget.value,
            }
          return translation
        })
      )
    }
  }

  const handleEditorTextChange = (lang: string) => (editorState: EditorState) => {
    if (editorTexts) {
      setEditorTexts(
        editorTexts.map((editorText: EditorTranslation) => {
          if (lang === editorText.lang)
            return {
              lang,
              text: editorState,
            }
          return editorText
        })
      )
    }
  }

  const saveEditorStateToTexts = () => {
    setTexts(
      editorTexts.map((translation: EditorTranslation) => {
        const text = draftToHtml(convertToRaw(translation.text.getCurrentContent())).replace(/\n/g, '') // Remove newline characters

        return {
          lang: translation.lang,
          text,
        }
      })
    )
  }

  const getInputLabel = (isDefault: boolean, label: string, lang: Language) =>
    `${isDefault ? `${label} / ` : ''}${t(`languages.${lang}`)}`

  const basicInput = (translation: Translation, defaultLang = false) => {
    if (translation)
      return (
        <Input
          key={translation.lang}
          label={getInputLabel(defaultLang, inputLabel, translation.lang)}
          infoText={infoText}
          value={translation.text}
          onChange={handleTextChance(translation.lang)}
          data-id={translation.lang}
          id={`${translation.lang}`}
          helpTipKey={helpTipKey}
        />
      )
  }

  const editorInput = (editorText: EditorTranslation, defaultLang = false) => {
    if (editorText) {
      return (
        <HtmlEditor
          key={editorText.lang}
          title={getInputLabel(defaultLang, inputLabel, editorText.lang)}
          titleSize={labelSize}
          infoText={infoText}
          state={editorText.text}
          onStateChange={handleEditorTextChange(editorText.lang)}
          onFocusOut={saveEditorStateToTexts}
          helpTipKey={helpTipKey}
        />
      )
    }
  }

  if (!texts || texts.length === 0) return null

  // Renders input for default language (i.e. first item in languages array)
  const defaultLanguageInput = () => {
    if (editor && editorTexts) return editorInput(editorTexts[0], true)
    return basicInput(texts[0], true)
  }

  // Renders rest of the translation inputs excluding default
  // language (which is the first item in languages array)
  const restOfTheInputs = () => {
    // When using WYSIWYG-editor:
    if (editor) {
      return editorTexts.filter((t: Translation, i: number) => i !== 0).map((t: Translation) => editorInput(t))
    }

    // When using basic input:
    return texts.filter((t: Translation, i: number) => i !== 0).map((t: Translation) => basicInput(t))
  }

  return (
    <div className={c.container}>
      {/* First (=default) language is visible outside dialog: */}
      {defaultLanguageInput()}

      {/* Modal dialog for showing & editing rest of translations: */}
      {texts.length > 1 && (
        <>
          <Dialog
            maxWidth='xl'
            triggerButtonProps={{
              text: t('add_translations'),
              variant: 'outlined',
            }}
            onTriggerButtonClick={onTriggerButtonClick}
            title={t('add_translations')}
          >
            <div className={c.inputsContainer}>
              <Typography variant='h6'>{inputLabel}</Typography>
              {restOfTheInputs()}
            </div>
          </Dialog>

          <TranslationsCount translations={texts} limit={texts.length} inline />
        </>
      )}
    </div>
  )
}

export default TranslationsDialog
