import { createActions, createReducer } from 'reduxsauce'
import Immutable from 'seamless-immutable'

const { Types, Creators } = createActions({
  getEmbeddedCharts: null,
  getEmbeddedChartsSuccess: ['charts'],

  getEmbeddedChart: null,
  getEmbeddedChartSuccess: ['embedded'],

  createEmbeddedChart: ['payload'],
  createEmbeddedChartSuccess: null,
  createEmbeddedChartFailure: null,

  editEmbeddedChart: ['payload'],
  editEmbeddedChartSuccess: null,
  editEmbeddedChartFailure: null,

  deleteEmbeddedChart: ['payload'],
  deleteEmbeddedChartSuccess: null,

  deleteEmbeddedChartFolder: ['payload'],
  deleteEmbeddedChartFolderSuccess: null,

  createEmbeddedChartFolder: ['folderName'],
  createEmbeddedChartFolderSuccess: null,
})

export const AdminToolsTypes = Types
export default Creators

export const INITIAL_STATE: AdminToolsStore = Immutable({
  fetching: false,
  loading: false,
  charts: null,
})

const getEmbeddedCharts = (state: any) => state.merge({ fetching: true })
const getEmbeddedChart = (state: any) => state.merge({ fetching: true })

const getEmbeddedChartsSuccess = (state: any, { charts }: { charts: any }) =>
  state.merge({ charts: charts, fetching: false })

const getEmbeddedChartSuccess = (state: any) => state.merge({ fetching: false })

const createEmbeddedChart = (state: any) => state.merge({ loading: true })
const createEmbeddedChartSuccess = (state: any) => state.merge({ loading: false })

const editEmbeddedChart = (state: any) => state.merge({ loading: true })
const editEmbeddedChartSuccess = (state: any) => state.merge({ loading: false })

const deleteEmbeddedChart = (state: any) => state.merge({ loading: true })
const deleteEmbeddedChartSuccess = (state: any) => state.merge({ loading: false })

const deleteEmbeddedChartFolder = (state: any) => state.merge({ loading: true })
const deleteEmbeddedChartFolderSuccess = (state: any) => state.merge({ loading: false })

const createEmbeddedChartFolder = (state: any) => state.merge({ loading: true })
const createEmbeddedChartFolderSuccess = (state: any) => state.merge({ loading: false })

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_EMBEDDED_CHARTS]: getEmbeddedCharts,
  [Types.GET_EMBEDDED_CHARTS_SUCCESS]: getEmbeddedChartsSuccess,

  [Types.GET_EMBEDDED_CHART]: getEmbeddedChart,
  [Types.GET_EMBEDDED_CHART_SUCCESS]: getEmbeddedChartSuccess,

  [Types.CREATE_EMBEDDED_CHART]: createEmbeddedChart,
  [Types.CREATE_EMBEDDED_CHART_SUCCESS]: createEmbeddedChartSuccess,

  [Types.EDIT_EMBEDDED_CHART]: editEmbeddedChart,
  [Types.EDIT_EMBEDDED_CHART_SUCCESS]: editEmbeddedChartSuccess,

  [Types.DELETE_EMBEDDED_CHART]: deleteEmbeddedChart,
  [Types.DELETE_EMBEDDED_CHART_SUCCESS]: deleteEmbeddedChartSuccess,

  [Types.DELETE_EMBEDDED_CHART_FOLDER]: deleteEmbeddedChartFolder,
  [Types.DELETE_EMBEDDED_CHART_FOLDER_SUCCESS]: deleteEmbeddedChartFolderSuccess,

  [Types.CREATE_EMBEDDED_CHART_FOLDER]: createEmbeddedChartFolder,
  [Types.CREATE_EMBEDDED_CHART_FOLDER_SUCCESS]: createEmbeddedChartFolderSuccess,
})
