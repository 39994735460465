import { createActions, createReducer } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  setError: ['error'],
  setSuccess: ['success'],
  clearError: null,
})

export const ErrorTypes = Types
export default Creators

/* ------------- Initial State ------------- */
export const INITIAL_STATE: ErrorStore = Immutable({
  error: null,
  success: null,
})

/* ------------- Reducers ------------- */

const setError = (state: any, { error }: { error: ErrorType }) =>
  state.merge({ error, success: null })
const setSuccess = (state: any, { success }: { success: ErrorSuccess }) =>
  state.merge({ success, error: null })
const clearError = (state: any) => state.merge({ error: null, success: null })

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_ERROR]: setError,
  [Types.SET_SUCCESS]: setSuccess,
  [Types.CLEAR_ERROR]: clearError,
})
