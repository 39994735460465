import React, { useCallback, useEffect } from 'react'
import Typography from '@material-ui/core/Typography'
import { ROLE } from '../../utils/constants'
import { useTranslation } from 'react-i18next'
import Button from '../Button'
import styled from 'styled-components'
import { Colors } from '../../theme/colors'
import { useDispatch, useSelector } from 'react-redux'
import { UserTypes } from '../../redux/UserRedux'
import { useHistory } from 'react-router-dom'

const StyledTypography = styled(Typography)`
  color: ${Colors.white};
  opacity: 80%;
`

const LogoutContainer = styled.div`
  margin-top: 20px;
`

const UserArea: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()

  const userRole = useSelector((state: RootStore) => state.user.user?.role)
  const username = useSelector((state: RootStore) => state.user.user?.username)

  const logout = useCallback(() => {
    dispatch({ type: UserTypes.LOGOUT })
  }, [dispatch])

  const getRole = useCallback(() => {
    dispatch({ type: UserTypes.GET_ROLE })
  }, [dispatch])

  useEffect(() => {
    getRole()
  }, [getRole])

  const handleLogOut = () => {
    logout()
    history.push('/')
  }

  const role = userRole === ROLE.SUPERUSER ? ` ${userRole.toUpperCase()}` : ''

  return (
    <>
      <StyledTypography variant='subtitle1'>{`${t('logged_in_as')}${role}:`}</StyledTypography>
      <StyledTypography variant='body2'>{username}</StyledTypography>
      <LogoutContainer>
        <Button text={t('log_out')} onClick={handleLogOut} color='secondary' />
      </LogoutContainer>
    </>
  )
}

export default UserArea
