import { ApexOptions } from 'apexcharts'

interface ChartOptions {
  small: ApexOptions
  hero: ApexOptions
  common: ApexOptions
}

export const DEFAULT_COLORS = ['#EF476F', '#06D6A0', '#8338ec', '#FFD166', '#118AB2', '#073b4c', '#eb5e28', '#403d39']

/**
 * Default colors but doubled so that comparison
 * data has the same color
 */
export const WITH_COMPARISON_COLORS = [
  '#EF476F',
  '#EF476F',

  '#06D6A0',
  '#06D6A0',

  '#8338ec',
  '#8338ec',

  '#FFD166',
  '#FFD166',

  '#118AB2',
  '#118AB2',

  '#073b4c',
  '#073b4c',

  '#eb5e28',
  '#eb5e28',

  '#403d39',
  '#403d39',
]

export const DEFAULT_DASH_ARRAY = []

/**
 * Dash array config makes it possible to display
 * comparison data graph line as a dashed line and
 * actual data as a solid line.
 *
 * Every pair represents one property and its comparison.
 * 20 values should be enough for edgecases since
 * the data is often unreadable even when comparing
 * more than 5 properties.
 */
export const WITH_COMPARISON_DASH_ARRAY = [
  0,
  5,
  0,
  5,
  0,
  5,
  0,
  5,
  0,
  5,
  0,
  5,
  0,
  5,
  0,
  5,
  0,
  5,
  0,
  5,
  0,
  5,
  0,
  5,
  0,
  5,
  0,
  5,
  0,
  5,
  0,
  5,
  0,
  5,
  0,
  5,
  0,
  5,
  0,
  5,
]

export const TOTAL_IMAGE_VIEWS_OPTIONS: ChartOptions = {
  small: {
    xaxis: {
      axisBorder: {
        show: false,
      },
      labels: {
        show: false,
      },
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
      labels: {
        show: false,
      },
    },
    grid: {
      show: false,
      padding: {
        left: -15,
        top: -15,
      },
    },
    chart: {
      toolbar: {
        show: false,
      },
    },
  },
  hero: {
    title: {
      text: 'Total image views',
      align: 'left',
      style: {
        fontSize: '18px',
      },
      offsetX: 14,
    },
    chart: {
      toolbar: {
        show: true,
      },
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
      offsetX: -10,
      fontSize: '14px',
      fontWeight: 600,
      labels: {
        useSeriesColors: true,
      },
      markers: {
        offsetX: -5,
      },
      itemMargin: {
        horizontal: 15,
        vertical: 0,
      },
    },
    xaxis: {
      labels: {
        style: {
          colors: '#8e8da4',
          fontSize: '14px',
        },
        formatter: function (dateString) {
          const date = new Date(dateString)
          return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: '#8e8da4',
          fontSize: '14px',
        },
      },
    },
  },
  common: {
    chart: {
      id: 'total-image-views',
      // Currently with these settings, having dropShadow causes
      // the chart to shift right when toggling from small to large?
      dropShadow: {
        enabled: true,
        top: 5,
        left: 0,
        blur: 2,
        opacity: 0.1,
      },
      zoom: {
        enabled: false,
      },
    },
    stroke: {}, // dash array will be applied here
    dataLabels: {
      enabled: false,
    },
    fill: {
      opacity: 0.4,
    },
  },
}

export const IMAGE_VIEWS_PER_COUNTRY_OPTIONS: ChartOptions = {
  common: {
    chart: {
      id: 'image-views-per-country',
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      stacked: true,
    },
    colors: DEFAULT_COLORS,
    plotOptions: {
      bar: {
        borderRadius: 0,
        barHeight: '100%',
      },
    },
    dataLabels: {
      enabled: true,
      textAnchor: 'middle',
      style: {
        colors: ['#fff'],
      },
    },
    stroke: {
      width: 1,
      colors: ['#fff'],
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
      labels: {
        show: false,
      },
    },
    xaxis: {
      axisBorder: {
        show: false,
      },
    },
  },
  small: {
    grid: {
      padding: {
        left: -15,
        top: -15,
      },
    },
    xaxis: {
      labels: {
        show: false,
      },
    },
  },
  hero: {
    title: {
      text: 'Sessions by country (Top 5)',
      align: 'left',
      style: {
        fontSize: '18px',
      },
      offsetX: 14,
    },
    xaxis: {
      labels: {
        show: true,
      },
    },
    chart: {
      toolbar: {
        show: true,
      },
      zoom: {
        enabled: true,
      },
    },
  },
}

export const IMAGE_VIEWS_PER_CATEGORY_OPTIONS: ChartOptions = {
  common: {
    chart: {
      id: 'image-views-per-category',
      zoom: {
        enabled: false,
      },
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    colors: DEFAULT_COLORS,
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 0,
      },
    },
  },
  hero: {
    title: {
      text: 'Image views per category',
      align: 'left',
      style: {
        fontSize: '18px',
      },
      offsetX: 14,
    },
    chart: {
      toolbar: {
        show: true,
      },
      zoom: {
        enabled: true,
      },
    },
    xaxis: {
      labels: {
        style: {
          colors: '#8e8da4',
          fontSize: '14px',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: '#8e8da4',
          fontSize: '14px',
        },
      },
    },
  },
  small: {
    xaxis: {
      axisBorder: {
        show: false,
      },
      labels: {
        show: false,
      },
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
      labels: {
        show: false,
      },
    },
    grid: {
      show: false,
      padding: {
        left: -15,
        top: -15,
      },
    },
  },
}

export const SMART_TAG_CLICKS_PER_CATEGORY_OPTIONS: ChartOptions = {
  common: {
    chart: {
      id: 'smart-tag-clicks-per-category',
      zoom: {
        enabled: false,
      },
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    colors: DEFAULT_COLORS,
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 0,
      },
    },
  },
  hero: {
    title: {
      text: 'Smart tag clicks per category',
      align: 'left',
      style: {
        fontSize: '18px',
      },
      offsetX: 14,
    },
    chart: {
      toolbar: {
        show: true,
      },
      zoom: {
        enabled: true,
      },
    },
    xaxis: {
      labels: {
        style: {
          colors: '#8e8da4',
          fontSize: '14px',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: '#8e8da4',
          fontSize: '14px',
        },
      },
    },
  },
  small: {
    xaxis: {
      axisBorder: {
        show: false,
      },
      labels: {
        show: false,
      },
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
      labels: {
        show: false,
      },
    },
    grid: {
      show: false,
      padding: {
        left: -15,
        top: -15,
      },
    },
  },
}
