import React from 'react'
import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core'
import { Colors } from '../../theme/colors'
import Icons from '../../theme/Icons'
import { INSTRUCTIONS_URL } from '../../utils/constants'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    instructionsButton: {
      position: 'absolute',
      top: '0px',
      right: '-160px',
    },
    instructionsButtonInside: {
      top: '20px',
      right: '40px',
    },
    instructionsButtonOutside: {
      top: '0px',
      right: '-160px',
    },
    instructionsButtonLink: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: Colors.text,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    instructionsButtonText: {
      marginLeft: '5px',
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
  })
)

interface Props {
  isInsideContainer?: boolean
}

const InstructionsButton: React.FC<Props> = ({ isInsideContainer = false }) => {
  const { t } = useTranslation()
  const c = useStyles()

  const buttonPositionClass = isInsideContainer ? c.instructionsButtonInside : c.instructionsButtonOutside

  return (
    <div className={`${c.instructionsButton} ${buttonPositionClass}`}>
      <a className={c.instructionsButtonLink} href={INSTRUCTIONS_URL} target='blanc'>
        <Icons.Info color='primary' fontSize='large' />
        <Typography variant='body2' className={c.instructionsButtonText}>
          {t('instructions_button')}
        </Typography>
      </a>
    </div>
  )
}

export default InstructionsButton
