export const CLIENT_ERROR = 'CLIENT_ERROR'
export const NETWORK_ERROR = 'NETWORK_ERROR'
export const TIMEOUT_ERROR = 'TIMEOUT_ERROR'
export const CONNECTION_ERROR = 'CONNECTION_ERROR'

export const CLIENT_CONNECTION_ERRORS = [NETWORK_ERROR, TIMEOUT_ERROR, CONNECTION_ERROR]

export const PRESENTATION_TYPE = {
  PUBLISHED: 'published',
}

export const ROLE = {
  SUPERUSER: 'superuser',
  USER: 'user',
}

export const STATUS: { [key: string]: PresentationStatus } = {
  START: 'start',
  DRAFT: 'draft',
  SENT: 'sent',
}

export const PRESENTATION_PERMISSIONS: { [key: string]: Permission } = {
  ANALYTICS_ACCESS: 'analytics_access',
  EDITOR_ACCESS: 'editor_access',
}

export const PRICING_URL = 'https://www.visualizergroup.com/pricing/'
export const PASSWORD_RESET_URL = 'https://admin.360visualizer.com/passwordreset/'
export const DEFAULT_MAP_MARKER_URL = process.env.PUBLIC_URL + '/map-marker.png'
export const INSTRUCTIONS_URL = 'https://hotelvisualizer.com/Visualizer-Editor-User-Guide.pdf'
export const TERMS_AND_CONDITIONS_URL = 'https://www.visualizervisit.com/terms-conditions/'
export const PRIVACY_POLICY_URL = 'https://www.visualizergroup.com/privacy-policy/'

export const STILL_IMAGE_MIN_WIDTH = 1000
export const STILL_IMAGE_MAX_WIDTH = 2500
export const STILL_IMAGE_RECOMMENDED_WIDTH = 2000

export const PANORAMA_IMAGE_MIN_WIDTH = 5000
export const PANORAMA_IMAGE_MAX_WIDTH = 10000
export const PANORAMA_IMAGE_RATIO: ImageRatio = [2, 1] // 2:1

export const FLOORPLAN_MIN_WIDTH = 2000

export const CAPACITY_CHART_MIN_WIDTH = 2000

export const SUPPORTED_IMAGE_FORMATS = ['jpeg']

export const DEFAULT_LANGUAGE: Language = 'eng'
export const ADDITIONAL_LANGUAGES: Language[] = [
  // Note: Don't add default language ('eng') here!
  'swe',
  'spa',
  'rus',
  'dan',
  'ger',
  'fra',
  'ita',
  'est',
  'nor',
  'fin',
  'chi',
  'jpn',
  'tur',
  'ara',
  'por',
  'ces',
  'hrv',
  'nld',
]

export const EN_DASH_CHAR = '\u2013'
export const BULLET_CHAR = ' \u2022 '

export const FEEDBACK_FORM_TYPEFORM_ID = 'M1c1UTzr'

export default {
  ROLE,
  STILL_IMAGE_MIN_WIDTH,
  STILL_IMAGE_MAX_WIDTH,
  STILL_IMAGE_RECOMMENDED_WIDTH,
  PANORAMA_IMAGE_MIN_WIDTH,
  PANORAMA_IMAGE_MAX_WIDTH,
  PANORAMA_IMAGE_RATIO,
  FLOORPLAN_MIN_WIDTH,
  CAPACITY_CHART_MIN_WIDTH,
  SUPPORTED_IMAGE_FORMATS,
  DEFAULT_LANGUAGE,
  ADDITIONAL_LANGUAGES,
  PRESENTATION_PERMISSIONS,
  FEEDBACK_FORM_TYPEFORM_ID,
}
