import React, { ChangeEvent, ReactNode, useCallback, useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { AnyAction, Dispatch } from 'redux'
import { useTranslation } from 'react-i18next'
import {
  Typography,
  createStyles,
  makeStyles,
  Theme,
  FormControlLabel,
  Checkbox,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  IconButton,
  Tooltip,
} from '@material-ui/core'

import PresentationsActions from '../redux/PresentationsRedux'
import PresentationActions from '../redux/PresentationRedux'
import Spinner from '../components/Spinner'
import PresentationsList from '../components/Presentations/List'
import Input from '../components/Input'
import Error from './Error'
import { ROLE } from '../utils/constants'
import { appStyles } from '../theme/styles'
import Icon from '../theme/Icons'
import HContainer from '../components/Layout/HContainer'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ...appStyles,
    presentationsListContainer: {
      margin: '30px 0',
    },
    toolbar: {
      paddingTop: '20px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexWrap: 'wrap',
    },
    searchContainer: {
      width: '40%',
      minWidth: '250px',
    },
    presentationsContainer: {
      backgroundColor: '#fff',
      borderRadius: '1rem',
      padding: '1rem',
      marginTop: '1.5rem',
    },
  })
)

interface StatusFilters {
  [key: string]: boolean
}
const initialStatusFilters: StatusFilters = {
  start: true,
  draft: true,
  sent: true,
}

const SORT_BY_OPTIONS = ['name', 'createdAt', 'updatedAt']

enum SortOrder {
  ASC = 1,
  DESC = -1,
}

interface Props {
  presentations?: Presentation[] | null
  fetching: boolean
  getPresentations: () => AnyAction
  clearPresentation: () => AnyAction
  error: ErrorType | null
}

const Presentations: React.FC<Props> = ({ presentations, fetching, getPresentations, clearPresentation, error }) => {
  const { t } = useTranslation()
  const c = useStyles()
  const userRole = useSelector((state: RootStore) => state.user.user?.role)

  const [searchTerm, setSearchTerm] = useState('')
  const [filterBy, setFilterBy] = useState<StatusFilters>(initialStatusFilters)
  const [sortBy, setSortBy] = useState('name')
  const [sortOrder, setSortOrder] = useState(SortOrder.ASC)
  const [filteredPresentations, setFilteredPresentations] = useState<Presentation[]>([])

  const sortPresentations = useCallback(
    (a: Presentation, b: Presentation) => {
      const compareDates = (date1: string, date2: string) => Date.parse(date1) - Date.parse(date2)
      switch (sortBy) {
        case 'createdAt':
          return compareDates(a.createdAt, b.createdAt) * sortOrder || 0
        case 'updatedAt':
          return compareDates(a.updatedAt, b.updatedAt) * sortOrder || 0
        default:
          const nameA = a.name.toLowerCase()
          const nameB = b.name.toLowerCase()
          return nameA > nameB ? 1 * sortOrder : nameA < nameB ? -1 * sortOrder : 0
      }
    },
    [sortBy, sortOrder]
  )

  const filterPresentation = useCallback(
    (presentation: Presentation) => {
      const matchSearchTerm = (name: string) => name.toLowerCase().includes(searchTerm.toLowerCase())

      if (searchTerm.length > 0) return matchSearchTerm(presentation.name) && filterBy[presentation.status]
      return filterBy[presentation.status]
    },
    [filterBy, searchTerm]
  )

  // Fetch presentations from api:
  useEffect(() => {
    getPresentations()
    clearPresentation() // Clear presentation from redux
  }, [clearPresentation, getPresentations])

  // Filter & sort presentations by user's selections:
  useEffect(() => {
    if (presentations) {
      setFilteredPresentations([...presentations].filter(filterPresentation).sort(sortPresentations))
    } else {
      setFilteredPresentations([])
    }
  }, [presentations, filterPresentation, sortPresentations])

  const handleFiltersChange = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const key = event.currentTarget.name
    const updatedFilterBy = { ...filterBy }
    updatedFilterBy[key] = checked
    setFilterBy(updatedFilterBy)
  }

  const handleSortByChange = (event: ChangeEvent<{ value: unknown }>, child: ReactNode) => {
    setSortBy(event.target.value as string)
  }

  const handleSortOrderChange = () => {
    setSortOrder(sortOrder === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC)
  }

  const FilterCheckboxes = () => (
    <div>
      <Typography variant='body2' className={c.isBold}>
        {t('filter_by_status')}:
      </Typography>
      <div style={{ whiteSpace: 'nowrap' }}>
        {Object.keys(filterBy)
          .filter((key) => key !== 'sent' || userRole === ROLE.SUPERUSER)
          .map((key) => (
            <FormControlLabel
              key={key}
              control={
                <Checkbox
                  checked={filterBy[key]}
                  onChange={handleFiltersChange}
                  name={key}
                  color='primary'
                  size='small'
                />
              }
              label={<Typography variant='body2'>{t(`filter_${key}`)}</Typography>}
            />
          ))}
      </div>
    </div>
  )

  const SortOptions = () => (
    <div style={{ whiteSpace: 'nowrap' }}>
      <FormControl variant='outlined' size='small' style={{ minWidth: 110 }}>
        <InputLabel id='sort-by-select-label' className={c.isBold}>
          {t('sort_by')}
        </InputLabel>
        <Select
          labelId='sort-by-select'
          id='sort-by-select'
          value={sortBy}
          onChange={handleSortByChange}
          label={t('sort_by')}
        >
          {SORT_BY_OPTIONS.map((option) => (
            <MenuItem key={option} value={option}>
              <Typography variant='body2'>{t(option)}</Typography>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Tooltip title={`${sortOrder === SortOrder.ASC ? t('ascending') : t('descending')}`} placement='top'>
        <IconButton onClick={handleSortOrderChange} color='primary'>
          {sortOrder === SortOrder.DESC ? <Icon.ArrowUp /> : <Icon.ArrowDown />}
        </IconButton>
      </Tooltip>
    </div>
  )

  if (!presentations && !fetching && error) return <Error error={error} />

  return (
    <HContainer>
      <div className={c.presentationsContainer}>
        <Typography variant='h4'>
          {userRole === ROLE.SUPERUSER ? t('all_presentations') : t('your_presentations')}
        </Typography>

        {/* Show toolbar bar only when there's at least two items in the list */}
        {presentations && presentations.length >= 2 && (
          <div className={c.toolbar}>
            <div className={c.searchContainer}>
              <Input
                value={searchTerm}
                setValue={setSearchTerm}
                placeholder={t('search_by_name')}
                highlight={searchTerm.length > 0}
              />
            </div>
            <FilterCheckboxes />
            <SortOptions />
          </div>
        )}

        <div className={c.presentationsListContainer}>
          {fetching && <Spinner />}
          {!fetching && <PresentationsList presentations={filteredPresentations} />}
        </div>
      </div>
    </HContainer>
  )
}

const mapStateToProps = (state: RootStore) => {
  return {
    presentations: state.presentations.content,
    fetching: state.presentations.fetching,
    error: state.error.error,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    getPresentations: () => dispatch(PresentationsActions.getPresentations()),
    clearPresentation: () => dispatch(PresentationActions.clearPresentation()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Presentations)
