import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

const SelectedProperties: React.FC = () => {
  const selectedProperties = useSelector((state: RootStore) => state.analytics.selectedProperties)
  const presentations = useSelector((state: RootStore) => state.presentations.content)
  const { t } = useTranslation()

  if (!presentations) return null

  const selectedPresentations = presentations.filter((p) => selectedProperties.includes(p.id))
  const amount = selectedPresentations.length

  if (amount > 1) {
    return <div>{t('analytics.properties_selected', { amount })}</div>
  }

  return (
    <>
      {selectedPresentations.map((p) => (
        <div key={p.name}>{p.name}</div>
      ))}
    </>
  )
}

export default SelectedProperties
