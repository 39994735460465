import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ClickAwayListener, Fade, Popper, PopperPlacementType, Typography } from '@material-ui/core'
import styled from 'styled-components'
import Icons from '../theme/Icons'
import { Colors } from '../theme/colors'

const Container = styled.span`
  display: inline-block;
  padding: 0 8px;
`

const Trigger = styled.div<{ isOpen: boolean; color?: string }>`
  cursor: pointer;
  opacity: ${(p) => (p.isOpen ? 1 : 0.8)};
  color: ${(p) => (p.color ? p.color : Colors.secondary)};

  :hover {
    opacity: 1;
  }
`

const HelptipContainer = styled.div`
  position: relative;
  padding: 16px 24px;
  max-width: 400px;
  color: ${Colors.white};
  background-color: ${Colors.secondary};
  border-radius: 6px;
  box-shadow: 0px 1px 10px 1px rgba(0, 0, 0, 0.3);
  cursor: default;

  .help-tip-close-btn {
    position: absolute;
    top: 4px;
    right: 4px;
    color: ${Colors.white};
    font-size: 16px;
    opacity: 0.5;
    cursor: pointer;

    :hover {
      opacity: 1;
    }
  }
`

interface Props {
  helpTextKey?: string
  placement?: PopperPlacementType
  color?: string
}

const HelpTip: React.FC<Props> = ({ helpTextKey, placement = 'top', color, children }) => {
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const toggleHelpTip = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  const closeHelpTip = () => {
    if (anchorEl) setAnchorEl(null)
  }

  const isOpen = Boolean(anchorEl)
  const id = isOpen ? `help-tip-${helpTextKey ?? ''}` : undefined

  const closeButton = (
    <div className='help-tip-close-btn' onClick={closeHelpTip}>
      <Icons.Close color='inherit' fontSize='inherit' />
    </div>
  )

  const content = (
    <>
      {helpTextKey && (
        <Typography variant='body2' color='inherit'>
          {t(`helptip.${helpTextKey}`)}
        </Typography>
      )}
      {children}
    </>
  )

  return (
    <Container>
      <Trigger onClick={toggleHelpTip} isOpen={isOpen} color={color}>
        <Icons.Help color='inherit' fontSize='inherit' />
      </Trigger>

      <Popper id={id} open={isOpen} anchorEl={anchorEl} transition placement={placement}>
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={closeHelpTip}>
            <Fade {...TransitionProps} timeout={350}>
              <HelptipContainer>
                {closeButton}
                {content}
              </HelptipContainer>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    </Container>
  )
}

export default HelpTip
