import React from 'react'
import { makeStyles, Theme, createStyles, Typography } from '@material-ui/core'
import { getTranslationCount } from '../utils/transforms'
import { Colors } from '../theme/colors'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    text: {
      color: Colors.primary,
      fontWeight: 400,
      marginBottom: '15px',
    },
    inline: {
      display: 'inline',
    },
  })
)

interface Props {
  translations?: Translation[] | null
  limit: number
  inline?: boolean
  hideOnZero?: boolean
}

const TranslationsCount: React.FC<Props> = ({
  translations,
  limit,
  inline = false,
  hideOnZero = false,
}) => {
  const c = useStyles()
  const { t } = useTranslation()

  const count = translations ? getTranslationCount(translations) : 0

  if (count === 0) return null

  return (
    <Typography
      variant='body2'
      className={`${c.text} ${inline ? c.inline : ''}`}
    >
      {t('translations_added', { count, limit })}
    </Typography>
  )
}

export default TranslationsCount
