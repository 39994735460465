import React from 'react'
import { useTranslation } from 'react-i18next'

import Dialog from './Dialog'
import FileList from './FileList'

interface Props {
  images: FileAsset[]
  type: 'still-images' | 'panoramas' | 'floorplans' | 'capacity-charts' | 'map-marker'
  buttonColor?: 'default' | 'primary' | 'secondary'
}

const PreviewImagesDialog: React.FC<Props> = ({ images, type, buttonColor = 'default' }) => {
  const { t } = useTranslation()

  if (!images || !images.length) return null

  const imagesTranslationKey = () => {
    switch (type) {
      case 'panoramas':
        return 'panoramas'
      case 'floorplans':
        return 'floorplans'
      case 'capacity-charts':
        return 'capacity_charts'
      case 'map-marker':
        return 'map_marker'
      default:
        return 'images'
    }
  }

  return (
    <Dialog
      title={t(imagesTranslationKey())}
      triggerButtonProps={{
        text: `${images.length} ${t(imagesTranslationKey()).toLowerCase()}`,
        variant: 'outlined',
        color: buttonColor,
        disabled: images.length === 0,
      }}
    >
      <FileList files={images} />
    </Dialog>
  )
}

export default PreviewImagesDialog
