import apisauce from 'apisauce'

const API_URLS = {
  local: 'http://localhost:1337/',
  stage: 'https://visualizer-api.stage.geniem.io/',
  prod: 'https://visualizer-api.production.geniem.io/',
}

let baseURL = API_URLS.prod
if (process.env.NODE_ENV === 'development') baseURL = API_URLS.local
if (window.location.hostname.includes('.stage.geniem.io')) baseURL = API_URLS.stage

const api = apisauce.create({
  baseURL,
  timeout: 30000,
})

export const getEmbeddedChartDataByUuid = async (uuid: string) => {
  return await api.get(`admin-tools/embedded-chart/${uuid}`)
}
