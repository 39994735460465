import React from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { makeStyles, Theme, createStyles, Typography } from '@material-ui/core'

import { Colors } from '../../theme/colors'
import TextPreview from '../TextPreview'
import TranslationsCount from '../TranslationsCount'
import CapacityInfoPreview from '../CapacityInfoPreview'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: '100%',
      overflowY: 'auto',
    },
    previewBlock: {
      margin: '0 0 20px 0',
    },
    translationsText: {
      color: Colors.primary,
      fontWeight: 400,
    },
  })
)

interface Props {
  category: EditableCategory | null
  supportedLanguages: SelectableLanguage[] | undefined
}

const Preview: React.FC<Props> = ({ category, supportedLanguages }) => {
  const c = useStyles()
  const { t } = useTranslation()

  if (!category) return null

  const TranslationPreview: React.FC<{
    title: string
    translations: Translation[] | null | undefined
    isHtml?: boolean
  }> = ({ title, translations, isHtml = false }) => {
    const limit = supportedLanguages ? supportedLanguages.filter((l) => l.isSelected).length : 0
    return (
      <div className={c.previewBlock}>
        <Typography variant='subtitle2'>{t(title)}</Typography>

        {/* Check if there is any translations: */}
        {translations && translations.length && (
          <Typography variant='body2'>
            <TextPreview text={translations[0].text} />
          </Typography>
        )}
        <TranslationsCount translations={translations} limit={limit} />
      </div>
    )
  }

  const ImagesPreview: React.FC<{ title: string; images?: FileAsset[] }> = ({ title, images }) => {
    const count = images ? images.length : 0
    return (
      <div className={c.previewBlock}>
        <Typography variant='subtitle2'>{`${t(title)} (${count})`}</Typography>
        {images &&
          images.map((image) => (
            <Typography key={image.id} variant='body2'>
              {image.name}
            </Typography>
          ))}
      </div>
    )
  }

  return (
    <div className={c.container}>
      <TranslationPreview title={'name'} translations={category.name} />
      <TranslationPreview title={'description'} translations={category.description} isHtml />
      {/* TODO: CAPACITY INFO DISABLED -> UNCOMMENT WHEN READY FOR LIVE */}
      {/* {category.type === 'meeting-room' && <CapacityInfoPreview capacity={category.capacity || []} />} */}

      <ImagesPreview title={'still_images'} images={category.images} />
      <ImagesPreview title={'panorama_images'} images={category.panoramaImages} />
    </div>
  )
}

const mapStateToProps = (state: RootStore) => {
  return {
    supportedLanguages: state.presentation.content?.supportedLanguages,
    category: state.category,
  }
}

export default connect(mapStateToProps)(Preview)
