import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import 'react-datepicker/dist/react-datepicker.css'

import { AnalyticsTypes } from '../redux/AnalyticsRedux'
import PresentationsActions from '../redux/PresentationsRedux'

import Breadcrumbs from '../components/Breadcrumbs'
import HContainer from '../components/Layout/HContainer'
import DateRangeSelector from '../components/DateRangeSelector'
import LoadingOverlay from '../components/Analytics/LoadingOverlay'

import breakpoints from '../theme/breakpoints'

import TotalViewsChart from '../components/Analytics/TotalViewsChart'
import ViewsByCategoryChart from '../components/Analytics/ViewsByCategoryChart'
import ViewsByCountryChart from '../components/Analytics/ViewsByCountryChart'
import SmartTagClicksByCategoryChart from '../components/Analytics/SmartTagClicksByCategoryChart'

import { SUBSCRIPTION_TIERS, MAX_HISTORY_LENGTH } from '../utils/analyticsConstants'

const Page = styled.div`
  background-color: #f2f2f7;
  height: 100%;
`

const Toolbar = styled.div`
  flex-direction: column;
  display: flex;

  @media ${breakpoints.md} {
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }
`

const ChartContainer = styled.div`
  background-color: #fff;
  padding: 1rem;
  border-radius: 1rem;
  width: 100%;
  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.1);

  .header {
    display: flex;
    justify-content: space-between;
  }
`

const SmallChartsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
  max-width: 100vw;
  margin: 1.5rem 0;

  @media ${breakpoints.lg} {
    display: inline-flex;
    flex-direction: row;
  }

  .header {
    display: flex;
    justify-content: space-between;
  }

  .icon-wrapper {
    width: 1.25rem;
    height: 1.25rem;
  }
`

interface VisibleChart {
  name: string
  type: any
  title: string
}

type VisibleCharts = VisibleChart[]

const availableCharts: any = {
  totalImageViewsChart: TotalViewsChart,
  imageViewsByCountryChart: ViewsByCountryChart,
  imageViewsByCategoryChart: ViewsByCategoryChart,
  smartTagClicksByCategoryChart: SmartTagClicksByCategoryChart,
}

const SmallCharts: React.FC<{ visibleCharts: VisibleCharts; onSwap: (index: number) => void }> = ({
  visibleCharts,
  onSwap,
}) => {
  return (
    <>
      {visibleCharts.map((chart: VisibleChart, index: number) => {
        // Skip first hero element.
        if (index < 1) return null

        const ChartElement = availableCharts[chart.name]

        return (
          <ChartContainer key={chart.name}>
            <ChartElement onSwap={() => onSwap(index)} size='small' type={chart.type} title={chart.title} />
          </ChartContainer>
        )
      })}
    </>
  )
}

const determineLowestSubscription = (presentations: Presentation[]) => {
  const tierNumbers = presentations.map((p: Presentation) => SUBSCRIPTION_TIERS[p.licenseName])

  const lowestTierNumber = Math.min(...tierNumbers)

  const [tierName] = Object.entries(SUBSCRIPTION_TIERS)
    .filter(([_, value]) => value === lowestTierNumber)
    .map(([name, _]) => name)

  return tierName
}

const Analytics: React.FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const dateRange = useSelector((state: RootStore) => state.analytics.dateRange)
  const fetching = useSelector((state: RootStore) => state.analytics.fetching)
  const comparisonRange = useSelector((state: RootStore) => state.analytics.comparisonRange)
  const selectedProperties = useSelector((state: RootStore) => state.analytics.selectedProperties)
  const presentations = useSelector((state: RootStore) => state.presentations.content)
  const [lowestSubscriptionType, setLowestSubscriptionType] = useState<string>('')

  const defaultChartConfiguration: VisibleChart[] = [
    {
      name: 'totalImageViewsChart',
      type: 'area',
      title: t('analytics.total_image_views'),
    },
    {
      name: 'imageViewsByCountryChart',
      type: 'bar',
      title: t('analytics.sessions_by_country'),
    },
    {
      name: 'smartTagClicksByCategoryChart',
      type: 'bar',
      title: t('analytics.smart_tag_clicks_per_category'),
    },
    {
      name: 'imageViewsByCategoryChart',
      type: 'bar',
      title: t('analytics.image_views_per_category'),
    },
  ]

  const [visibleCharts, setVisibleCharts] = useState<VisibleChart[]>(defaultChartConfiguration)

  // If user access analytics directly, fetch presentations
  useEffect(() => {
    if (!presentations) {
      dispatch(PresentationsActions.getPresentations())
    }
  }, [dispatch, presentations])

  useEffect(() => {
    if (!presentations) return

    const selectedPresentations = presentations.filter((p) => selectedProperties.includes(p.id))
    const lowestSubscriptionType = determineLowestSubscription(selectedPresentations)
    setLowestSubscriptionType(lowestSubscriptionType)
  }, [presentations, selectedProperties])

  // automatically fetch analytics for first presentation at first
  useEffect(() => {
    if (selectedProperties.length === 0 && presentations) {
      const [firstPresentation] = presentations.filter((p) => p.type === 'published')

      if (!firstPresentation) return

      const payload = {
        dateRange,
        selectedProperties: [firstPresentation.id],
      }
      dispatch({ type: AnalyticsTypes.SEARCH_ANALYTICS, payload })
    }
  }, [selectedProperties, presentations, dateRange, dispatch])

  const handleDateRangeSelect = (newRange: DateRangeSelection) => {
    const payload = {
      ...newRange,
      selectedProperties,
    }
    dispatch({ type: AnalyticsTypes.SEARCH_ANALYTICS, payload })
  }

  const handlePropertySelect = (selectedProperties: string[]) => {
    const payload = {
      dateRange,
      ...comparisonRange,
      selectedProperties,
    }
    dispatch({ type: AnalyticsTypes.SEARCH_ANALYTICS, payload })
  }

  const swapHeroChart = (currentIndex: number) => {
    const newCharts = [...visibleCharts]
    const previousHero = newCharts[0]

    // Swap chart to hero and previous hero to where chart was
    newCharts[0] = newCharts[currentIndex]
    newCharts[currentIndex] = previousHero

    setVisibleCharts(newCharts)
  }

  // Hero chart is always the first element
  const HeroChart = availableCharts[visibleCharts[0].name]

  return (
    <Page>
      <HContainer>
        <Toolbar>
          <Breadcrumbs onPropertySelect={handlePropertySelect} />
          <DateRangeSelector
            onSelect={handleDateRangeSelect}
            dateRange={dateRange}
            comparisonRange={comparisonRange}
            maxAccessableHistoryLength={MAX_HISTORY_LENGTH[lowestSubscriptionType]}
          />
        </Toolbar>
      </HContainer>
      <HContainer>
        <ChartContainer>
          <HeroChart onSwap={() => {}} size='hero' type={visibleCharts[0].type} title={visibleCharts[0].title} />
        </ChartContainer>
      </HContainer>
      <HContainer>
        <SmallChartsContainer>
          <SmallCharts visibleCharts={visibleCharts} onSwap={swapHeroChart} />
        </SmallChartsContainer>
      </HContainer>
      <LoadingOverlay visible={fetching} />
    </Page>
  )
}

export default Analytics
