import React from 'react'
import { useTranslation } from 'react-i18next'
import { Typography } from '@material-ui/core'

import Layout from '../components/Layout'

interface Props {
  error: ErrorType | null
}

const Error: React.FC<Props> = ({ error }) => {
  const { t } = useTranslation()

  return <Layout>{error && <Typography variant='h6'>{t(`error.${error.key}`)}</Typography>}</Layout>
}

export default Error
