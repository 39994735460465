export const CUSTOM_TOOLBAR_SETTINGS = {
  options: ['blockType', 'inline', 'list', 'link'],
  inline: {
    options: ['bold', 'italic', 'underline'],
  },
  list: {
    options: ['ordered', 'unordered'],
  },
  blockType: {
    inDropdown: true,
    options: ['Normal', 'H1', 'H2'],
  },
}
