import React, { useState, useRef, useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { NavLink, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { ROLE } from '../../utils/constants'
import { PopupButton } from '@typeform/embed-react'
import { FEEDBACK_FORM_TYPEFORM_ID } from '../../utils/constants'

import UserActions, { UserTypes } from '../../redux/UserRedux'

import KeyboardArrowDownOutlinedIcon from '@material-ui/icons/KeyboardArrowDownOutlined'
import { Menu as MenuIcon } from '@material-ui/icons'

import { PRESENTATION_PERMISSIONS } from '../../utils/constants'
import useOnClickOutside from '../../utils/hooks/useOnClickOutside'
import breakpoints from '../../theme/breakpoints'

import Logo from '../Logo'

const TopBar = styled.div`
  padding: 1.5rem 0;
  background-color: #fff;
  width: 100%;
  position: relative;

  .wrapper {
    max-width: 1536px;
    display: flex;
    align-items: center;
    margin: 0 auto;
    padding: 0 2rem;
  }

  .desktop-nav {
    display: none;
    width: 100%;

    @media ${breakpoints.md} {
      display: flex;
    }
  }

  .mobile-nav-toggle-wrapper {
    width: 100%;
    display: flex;
    justify-content: end;

    @media ${breakpoints.md} {
      display: none;
    }
  }
`

const Navigation = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 2rem;
  flex-grow: 1;
  align-items: center;

  @media ${breakpoints.md} {
    flex-direction: row;
  }

  .link {
    margin-right: 1rem;
    font-weight: bold;
    color: #d0d1d2;
    color: #383838;
  }

  .active {
    color: #383838;
  }
`

const UserMenu = styled.div`
  align-items: center;
  display: flex;
  position: relative;

  .name {
    font-weight: bold;
    color: #383838;
    margin-right: 2rem;
  }

  .icon {
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 1rem;
    background-color: #e5e5e5;
    margin-right: 1rem;
  }

  .open-btn {
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .feedback-button {
    background: none;
    border: none;
    font-size: inherit;
    color: inherit;
    padding: 0;
    font-family: inherit;
  }
`

const UserDropdown = styled.div<{ visible: boolean }>`
  display: ${(props) => (props.visible ? 'block' : 'none')};

  position: absolute;
  top: 100%;
  background-color: #fff;
  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  right: 0;
  z-index: 10;
  min-width: 192px;
  border: 1px solid #ececec;

  .link-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li {
    padding: 0.5rem 1rem;

    &:first-child {
      border-radius: 0.5rem 0.5rem 0 0;
    }

    &:last-child {
      border-radius: 0 0 0.5rem 0.5rem;
    }

    &:hover {
      background-color: #ececec;
    }

    cursor: pointer;
  }
`

const MobileMenu = styled.div<{ visible: boolean }>`
  z-index: 20;
  position: absolute;
  top: 100%;
  background-color: #fff;
  width: 100%;
  padding: 0 2rem 1rem 2rem;
  display: ${(props) => (props.visible ? 'block' : 'none')};

  @media ${breakpoints.md} {
    display: none;
  }
`

const NavigationItem = styled(NavLink)`
  margin-right: 1rem;
  font-weight: bold;
  text-decoration: none;
  display: block;
  margin-top: 1rem;
  color: #d0d1d2;

  @media ${breakpoints.md} {
    margin-top: 0;
  }
`

const TopNavigation: React.FC = () => {
  const username = useSelector((state: RootStore) => state.user.user?.username)
  const userRole = useSelector((state: RootStore) => state.user.user?.role)
  const presentations = useSelector((state: RootStore) => state.presentations.content)

  const [mobileMenuVisible, setMobileMenuVisible] = useState(false)
  const [userDropdownVisible, setUserDropdownVisible] = useState(false)
  const clickOutsideRef = useRef(null)
  const dispatch = useDispatch()
  const location = useLocation()

  const getRole = useCallback(() => {
    dispatch(UserActions.getRole())
  }, [dispatch])

  useEffect(() => {
    getRole()
  }, [getRole])

  /**
   * Previously there was only one situation where navigation was hidden
   * (login view). Now that there are multiple cases, consider moving
   * navigation as part of a page template and defined it on a per
   * page level instead of outside of router for all pages.
   */
  const hideNavigation = !username || location.pathname.includes('embed')

  /**
   * If user doens't have any presentations with analytics addess,
   * we hide the navigation element
   */
  const hasPresentationsWithAnalytics =
    presentations && presentations.some((pres) => pres.permissions.includes(PRESENTATION_PERMISSIONS.ANALYTICS_ACCESS))

  const hasAdminAccess = userRole === ROLE.SUPERUSER

  const { t } = useTranslation()

  const logout = useCallback(() => {
    dispatch({ type: UserTypes.LOGOUT })
    setUserDropdownVisible(false)
  }, [dispatch])

  const handleClickOutside = (e: any) => {
    setUserDropdownVisible(false)
  }

  useOnClickOutside(clickOutsideRef, handleClickOutside)

  // Topbar is not needed when the user is not logged in
  if (hideNavigation) return null

  return (
    <TopBar>
      <div className='wrapper'>
        <Logo fill='#3900EB' width='170px' />
        <div className='desktop-nav'>
          <Navigation>
            <div key='presentations'>
              <NavigationItem to='/presentations'>{t('presentations')}</NavigationItem>
            </div>
            {hasPresentationsWithAnalytics && (
              <div key='analytics'>
                <NavigationItem to='/analytics'>{t('analytics.title')}</NavigationItem>
              </div>
            )}
            {hasAdminAccess && (
              <div key='admin-tools'>
                <NavigationItem to='/admin-tools'>{t('admin_tools.title')}</NavigationItem>
              </div>
            )}
          </Navigation>
          <UserMenu>
            <div className='name'>{username}</div>
            <div ref={clickOutsideRef}>
              <div className='open-btn' onClick={() => setUserDropdownVisible(!userDropdownVisible)}>
                <KeyboardArrowDownOutlinedIcon />
              </div>
              <UserDropdown visible={userDropdownVisible}>
                <ul className='link-list'>
                  <li onClick={logout}>{t('log_out')}</li>
                  <li>
                    <PopupButton id={FEEDBACK_FORM_TYPEFORM_ID} className='feedback-button'>
                      {t('give_feedback')}
                    </PopupButton>
                  </li>
                </ul>
              </UserDropdown>
            </div>
          </UserMenu>
        </div>
        <div className='mobile-nav-toggle-wrapper'>
          <MenuIcon onClick={() => setMobileMenuVisible(!mobileMenuVisible)} />
        </div>
      </div>
      <MobileMenu visible={mobileMenuVisible}>
        <Navigation>
          <div key='presentations'>
            <NavigationItem to='/presentations'>{t('presentations')}</NavigationItem>
          </div>
          {hasPresentationsWithAnalytics && (
            <div key='analytics'>
              <NavigationItem to='/analytics'>{t('analytics.title')}</NavigationItem>
            </div>
          )}
          {hasAdminAccess && (
            <div key='admin-tools'>
              <NavigationItem to='/admin-tools'>{t('admin_tools.title')}</NavigationItem>
            </div>
          )}
        </Navigation>
      </MobileMenu>
    </TopBar>
  )
}

export default TopNavigation
