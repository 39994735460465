import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { AnyAction, Dispatch } from 'redux'
import { useTranslation } from 'react-i18next'
import { ThemeProvider } from '@material-ui/core'
import { Switch, Route, BrowserRouter as Router, Redirect, RouteProps } from 'react-router-dom'
import './i18n'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'

import TopNavigation from './components/Layout/TopNavigation'

import routes from './routes/routes'
import theme from './theme/theme'
import ErrorActions from './redux/ErrorRedux'
import UserActions from './redux/UserRedux'
import { getErrorMsg } from './utils/transforms'

interface Props {
  error: ErrorType | null
  success: ErrorSuccess | null
  user?: any
  clearError: () => AnyAction
  logout: () => AnyAction
}

const selectRoutes = (routes: any, user: any) => {
  if (user && user.token && user.user?.termsAccepted) {
    return routes.contentRoutes
  }
  if (user && user.token && !user.user?.termsAccepted) {
    return routes.acceptTermsRoutes
  }
  return routes.loggedOutRoutes
}

const App: React.FC<Props> = ({ error, success, user, clearError, logout }) => {
  const { t } = useTranslation()

  // Show notifications
  useEffect(() => {
    if (error) {
      toast.error(getErrorMsg(error))
      if (error.key === 'unauthorized') logout()
    }
    if (success) toast.success(t(success))
    clearError()
  }, [clearError, error, logout, success, t])

  const unauthorizedRedirect = () => {
    // Handle unauthorized errors
    if (error && error.key === 'unauthorized') return <Redirect to='/logout' />
    return null
  }

  const useRoutes = selectRoutes(routes, user)

  return (
    <ThemeProvider theme={theme}>
      <ToastContainer />
      <Router>
        <TopNavigation />
        <Switch>
          {unauthorizedRedirect()}
          {(useRoutes as RouteProps[]).map((route) => {
            return <Route key={`route__${route.path}`} {...route} />
          })}
          <Redirect to='/login' />
        </Switch>
      </Router>
    </ThemeProvider>
  )
}

const mapStateToProps = (state: RootStore) => {
  return {
    error: state.error.error,
    success: state.error.success,
    user: state.user,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    clearError: () => dispatch(ErrorActions.clearError()),
    logout: () => dispatch(UserActions.logout()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
