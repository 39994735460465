import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Typography, Box, makeStyles, createStyles, Theme, Checkbox, FormControlLabel } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import Button from '../components/Button'
import { UserTypes } from '../redux/UserRedux'
import { TERMS_AND_CONDITIONS_URL, PRIVACY_POLICY_URL } from '../utils/constants'

interface Props {
  acceptTerms: (_: any) => any
  logout: (_: any) => any
  t: Function
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    controlContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    },
    centeredContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    controls: {
      paddingTop: '25px',
      display: 'flex',
    },
    privacyPolicy: {
      display: 'flex',
    },
    actions: {
      paddingTop: '25px',
      display: 'flex',
      justifyContent: 'center',
    },
    mainContainer: {
      display: 'flex',
      flexDirection: 'column',
      margin: '25px 50px',
      flex: 1,
    },
    termsContainer: {
      flexGrow: 1,
    },
    termsEmbed: {
      width: '100%',
      height: '100%',
    },
  })
)

const PureTermsOfUse: React.FC<Props> = ({ t, acceptTerms: _acceptTerms, logout: _logout }) => {
  const [acceptTermsChecked, setAcceptTermsChecked] = useState(false)
  const c = useStyles()
  return (
    <Box className={c.mainContainer}>
      <Box className={c.termsContainer}>
        <iframe src={TERMS_AND_CONDITIONS_URL} className={c.termsEmbed} title={t('terms-of-use')}></iframe>
      </Box>
      <Box className={c.controlContainer}>
        <Box className={c.centeredContainer}>
          <Box className={c.controls}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={acceptTermsChecked}
                  onChange={(e) => setAcceptTermsChecked(e.target.checked)}
                  color='primary'
                />
              }
              label={<Typography variant='body2'>{t('terms-of-use__accept-checkbox-label')}</Typography>}
            />
          </Box>
          <Box className={c.privacyPolicy}>
            <Typography>
              {t('terms-of-use__privacy-policy--view')}
              <a target='_blank' href={PRIVACY_POLICY_URL} rel='noopener noreferrer'>
                {t('terms-of-use__privacy-policy--link')}
              </a>
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box className={c.actions}>
        <Button
          text={t('terms-of-use__action--accept')}
          size='large'
          onClick={_acceptTerms}
          disabled={!acceptTermsChecked}
          color='primary'
        />
        <Button text={t('log_out')} size='large' onClick={_logout} />
      </Box>
    </Box>
  )
}

const acceptTerms = (dispatch: any) => () => dispatch({ type: UserTypes.ACCEPT_TERMS })
const logout = (dispatch: any, history: any) => () => {
  dispatch({ type: UserTypes.LOGOUT })
  history.push('/')
}

const TermsOfUse: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()

  return <PureTermsOfUse t={t} acceptTerms={acceptTerms(dispatch)} logout={logout(dispatch, history)} />
}

export default TermsOfUse
