import { createActions, createReducer } from 'reduxsauce'
import Immutable from 'seamless-immutable'
import Storage from '../utils/storage'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  login: ['username', 'password'],
  loginSuccess: ['user', 'token'],
  loginFailure: null,
  getRole: null,
  getRoleSuccess: ['role'],
  logout: null,
  logoutSuccess: null,
  logoutFailure: null,
  acceptTerms: null,
  acceptTermsSuccess: null,
  acceptTermsFailure: null,
})

export const UserTypes = Types
export default Creators

/* ------------- Initial State ------------- */
export const INITIAL_STATE: UserStore = Immutable({
  fetching: false,
  token: Storage.read(Storage.KEYS.AUTH_TOKEN),
  user: {
    username: '',
    role: 'user',
    termsAccepted: false,
    ...Storage.read(Storage.KEYS.USER),
  },
})

/* ------------- Reducers ------------- */
const login = (state: any, { username, password }: { username: string; password: string }) =>
  state.merge({ fetching: true })

const loginSuccess = (state: any, { user, token }: { user: User; token: string }) =>
  state.merge({ user, token, fetching: false })

const loginFailure = (state: any) => state.merge({ fetching: false })

const getRole = (state: any) => state.merge({})

const getRoleSuccess = (state: any, { role }: { role: string }) =>
  state.merge({
    user: {
      ...state.user,
      role: role,
    },
  })

const logout = (state: any) => state.merge({ fetching: true })

const logoutSuccess = (state: any) => state.merge({ user: null, token: null, fetching: false })

const logoutFailure = (state: any) => state.merge({ fetching: false })

const acceptTerms = (state: any) => state.merge({ fetching: true })

const acceptTermsSuccess = (state: any) =>
  state.merge({ user: { ...state.user, termsAccepted: true }, fetching: false })

const acceptTermsFailure = (state: any) => state.merge({ fetching: false })

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.LOGIN]: login,
  [Types.LOGIN_SUCCESS]: loginSuccess,
  [Types.GET_ROLE]: getRole,
  [Types.GET_ROLE_SUCCESS]: getRoleSuccess,
  [Types.LOGIN_FAILURE]: loginFailure,
  [Types.LOGOUT]: logout,
  [Types.LOGOUT_SUCCESS]: logoutSuccess,
  [Types.LOGOUT_FAILURE]: logoutFailure,
  [Types.ACCEPT_TERMS]: acceptTerms,
  [Types.ACCEPT_TERMS_SUCCESS]: acceptTermsSuccess,
  [Types.ACCEPT_TERMS_FAILURE]: acceptTermsFailure,
})
