import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import HContainer from '../components/Layout/HContainer'
import styled from 'styled-components'
import LoadingOverlay from '../components/Analytics/LoadingOverlay'
import { useTranslation } from 'react-i18next'
import Button from '../components/Button'

import { AdminToolsTypes } from '../redux/AdminToolsRedux'
import AdminToolsActions from '../redux/AdminToolsRedux'

import NewEmbeddedChartFolder from '../components/AdminTools/NewEmbeddedChartFolder'
import EmbeddedChartFolder from '../components/AdminTools/EmbeddedChartFolder'

const Page = styled.div`
  background-color: #f2f2f7;
  height: 100%;
`

const Container = styled.div`
  background-color: #fff;
  margin-top: 1rem;
  padding: 1rem;
  border-radius: 1rem;
  width: 100%;
  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.1);

  .toolbar {
    display: flex;
    justify-content: space-between;
  }

  .button-container {
    display: flex;
    align-items: center;
  }

  .new-folder-wrapper {
    margin-bottom: 1rem;
  }
`

const Tools: React.FC = () => {
  const dispatch = useDispatch()
  const [newFolderAddVisible, setNewFolderAddVisible] = useState(false)
  const embeddedCharts = useSelector((state: RootStore) => state.adminTools.charts)
  const fetching = useSelector((state: RootStore) => state.adminTools.fetching)
  const { t } = useTranslation()

  useEffect(() => {
    if (!embeddedCharts) {
      dispatch(AdminToolsActions.getEmbeddedCharts())
    }
  }, [dispatch, embeddedCharts])

  const handleNewFolderSave = (folderName: string) => {
    dispatch(AdminToolsActions.createEmbeddedChartFolder(folderName))
    setNewFolderAddVisible(false)
  }

  const handleNewFolderCancel = () => {
    setNewFolderAddVisible(false)
  }
  return (
    <Page>
      <HContainer>
        <Container>
          <div className='toolbar'>
            <h2>{t('admin_tools.embedded_charts')}</h2>
            <div className='button-container'>
              <Button text='Add new folder' color='primary' onClick={() => setNewFolderAddVisible(true)} />
            </div>
          </div>
          <div className='new-folder-wrapper'>
            <NewEmbeddedChartFolder
              visible={newFolderAddVisible}
              onSave={handleNewFolderSave}
              onCancel={handleNewFolderCancel}
            />
          </div>
          {embeddedCharts &&
            embeddedCharts.map((folder: any) => <EmbeddedChartFolder key={folder.uuid} folder={folder} />)}
        </Container>
      </HContainer>
      <LoadingOverlay visible={fetching} />
    </Page>
  )
}

export default Tools
