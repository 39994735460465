import { createActions, createReducer } from 'reduxsauce'
import Immutable from 'seamless-immutable'
import { DateTime } from 'luxon'

const { Types, Creators } = createActions({
  searchAnalytics: ['dateRange', 'comparisonRange', 'properties'],
  searchAnalyticsSuccess: ['payload'],
  searchAnalyticsFailure: null,
})

export const AnalyticsTypes = Types
export default Creators

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  fetching: false,
  dateRange: {
    start: DateTime.now().minus({ days: 7 }).toJSDate(),
    end: DateTime.now().minus({ days: 1 }).toJSDate(),
  },
  totalViews: {
    results: null,
    compareResults: null,
  },
  viewsByCategory: {
    results: null,
    compareResults: null,
  },
  viewsByCountry: {
    results: null,
    compareResults: null,
  },
  smartTagClicksByCategory: {
    results: null,
    compareResults: null,
  },
  comparisonRange: null,
  selectedProperties: [],
})

const searchAnalytics = (state: any, { payload }: { payload: any }) =>
  state.merge({
    fetching: true,
    // dateRange: payload.dateRange,
    // comparisonRange: payload.comparisonRange,
    // selectedProperties: payload.properties,
    ...payload,
  })

const searchAnalyticsSuccess = (state: any, { payload }: { payload: any }) =>
  state.merge({
    viewsByCategory: payload.viewsByCategory,
    totalViews: payload.totalViews,
    viewsByCountry: payload.viewsByCountry,
    smartTagClicksByCategory: payload.smartTagClicksByCategory,
    fetching: false,
  })

const searchAnalyticsFailure = (state: any) => state.merge({ fetching: false })

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.SEARCH_ANALYTICS]: searchAnalytics,
  [Types.SEARCH_ANALYTICS_SUCCESS]: searchAnalyticsSuccess,
  [Types.SEARCH_ANALYTICS_FAILURE]: searchAnalyticsFailure,
})
