import styled from 'styled-components'

const HContainer = styled.div`
  padding: 0 2rem;
  max-width: 1536px;
  width: 100%;
  margin: 0 auto;
`

export default HContainer
