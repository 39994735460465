import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'
import { Typography, makeStyles, Theme, createStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { v4 as uuidv4 } from 'uuid'
import FileListItem from '../FileListItem'
import FileDropzone from '../FileDropzone'
import { DEFAULT_MAP_MARKER_URL } from '../../utils/constants'
import HelpTip from '../HelpTip'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      margin: '50px 0',
    },
    titleContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    useDefaultLink: {
      margin: '10px 20px',
      '&:hover': {
        cursor: 'pointer',
        textDecoration: 'underline',
      },
    },
  })
)

interface Props {
  marker?: FileAsset
  setMarker: Dispatch<SetStateAction<FileAsset | undefined>>
}

const MapMarkerSelector: React.FC<Props> = ({ marker, setMarker }) => {
  const { t } = useTranslation()
  const c = useStyles()

  const [defaultDeleted, setDefaultDeleted] = useState(false)

  const setDefaultMarker = useCallback(() => {
    const image = new Image()
    image.src = DEFAULT_MAP_MARKER_URL
    image.onload = () => {
      setMarker({
        id: uuidv4(),
        name: 'default_map_marker.png',
        // Size is unknonw for external url...
        size: 0,
        width: image.width,
        height: image.height,
        src: image.src,
      })
      setDefaultDeleted(false)
    }
  }, [setMarker])

  useEffect(() => {
    if (!marker && !defaultDeleted) setDefaultMarker()
  }, [defaultDeleted, marker, setDefaultMarker])

  const validateMapMarker = (src: string, file: any, callback: (acceptedFile?: FileAsset) => void) => {
    const image = new Image()
    image.src = src
    image.onload = () => {
      // No restrictions for map marker, just return a file asset:
      callback({
        id: uuidv4(),
        name: file.name,
        size: file.size,
        width: image.width,
        height: image.height,
        src,
        file, // Keep File object around for uploading it later
      })
    }
  }

  const handleDelete = () => {
    setMarker(undefined)
    setDefaultDeleted(true)
  }

  return (
    <div className={c.container}>
      <div className={c.titleContainer}>
        <Typography variant='h5'>
          {t('map_marker')}
          <HelpTip helpTextKey='mapMarker' />
        </Typography>
        <Typography className={c.useDefaultLink} variant='body2' color='primary' onClick={setDefaultMarker}>
          {t('use_default')}
        </Typography>
      </div>
      <Typography variant='body2'>{t('map_marker_info')}</Typography>

      {/* Render either image (if uploaded) or dropzone (if not): */}
      {marker ? (
        <FileListItem file={marker} onDelete={handleDelete} />
      ) : (
        <FileDropzone
          fileLimit={1}
          files={marker ? [marker] : []}
          setFile={setMarker}
          acceptedFormats={['image/png']}
          validate={validateMapMarker}
        />
      )}
    </div>
  )
}

export default MapMarkerSelector
