import { call, put, select } from 'redux-saga/effects'
import { AnyAction } from 'redux'
import AnalyticsActions from '../redux/AnalyticsRedux'

// TODO: Fix type!
export function* searchAnalytics(api: any, action: AnyAction) {
  const { token } = yield select((state) => state.user)
  const { dateRange, comparisonRange, selectedProperties } = yield select((state) => state.analytics)

  const { payload } = action

  // If some value is not defined in the action, use exising store value
  const options = {
    dateRange,
    comparisonRange,
    selectedProperties,
    ...payload,
  }

  // @ts-expect-error
  const response = yield call(api.searchAnalytics, token, options)

  if (response.ok) {
    yield put(AnalyticsActions.searchAnalyticsSuccess(response.data))
  } else {
    // TODO: handle
  }
}
