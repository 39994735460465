import React, { useState } from 'react'
import { Dispatch, AnyAction } from 'redux'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Redirect } from 'react-router-dom'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'

import UserActions from '../redux/UserRedux'
import Input from '../components/Input'
import Logo from '../components/Logo'
import { Typography } from '@material-ui/core'
import Spinner from '../components/Spinner'
import { PASSWORD_RESET_URL, PRICING_URL } from '../utils/constants'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      height: '100vh',
      backgroundImage: 'url("https://admin.360visualizer.com/images/ciudad-maderas-MXbM1NrRqtI-unsplash.jpg")',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      boxShadow: 'inset 0 0 0 4000px rgb(0 0 0 / 30%)',
    },
    logoWrapper: {
      backgroundColor: 'transparent',
      padding: '20px 0 0 20px',
    },
    contentWrapper: {
      display: 'flex',
      width: '100vw',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
    },
    contentContainer: {
      width: '40%',
    },
    title: {
      color: '#fff',
    },
    infoText: {
      color: '#fff',
      margin: '20px 0',
    },
    link: {
      color: '#fff',
    },
    formContainer: {
      width: '90%',
    },
    buttonsContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: '5px 0',
    },
    forgotPasswordLink: {
      fontSize: '14px',
      color: '#fff',
    },
    loginButton: {
      border: '2px solid #fff',
      fontWeight: 400,
      color: '#fff',
      fontSize: '20px',
      minWidth: '150px',
      borderRadius: '50px',
      padding: '6px 16px',
      lineHeight: 1.75,
      backgroundColor: 'transparent',
      textTransform: 'uppercase',
      cursor: 'pointer',
    },
  })
)

interface Props {
  login: (username: string, password: string) => AnyAction
  token: string | null
  fetching: boolean
}

const Login: React.FC<Props> = ({ login, token, fetching }) => {
  const { t } = useTranslation()
  const c = useStyles()

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  const [validationError, setValidationError] = useState(false)

  const validateInput = (input: string) => input.length > 0

  const handleLogin = () => {
    if (validateInput(username) && validateInput(password)) {
      login(username, password)
      setPassword('')
    } else {
      setValidationError(true)
    }
  }

  if (fetching) return <Spinner />

  if (token) return <Redirect to='/presentations' />

  return (
    <div className={c.container}>
      <div className={c.logoWrapper}>
        <Logo width='200px' />
      </div>
      <div className={c.contentWrapper}>
        <div className={c.contentContainer}>
          <Typography className={c.title} variant='h1'>
            {t('portal_login')}
          </Typography>
          <Typography className={c.infoText}>
            {t('portal_login_info')}{' '}
            <a className={c.link} href={PRICING_URL} target='_black'>{`${t('portal_login_info_link')}.`}</a>
          </Typography>
          <form className={c.formContainer}>
            <Input
              value={username}
              label={t('user_name')}
              name='username'
              labelSize='h5'
              setValue={setUsername}
              labelColor='white'
              highlight={validationError && !validateInput(username)}
              required
            />
            <Input
              value={password}
              label={t('password')}
              labelSize='h5'
              setValue={setPassword}
              labelColor='white'
              type='password'
              highlight={validationError && !validateInput(password)}
              required
            />
            <div className={c.buttonsContainer}>
              <a href={PASSWORD_RESET_URL} target='_black' className={c.forgotPasswordLink}>
                {t('forgot_your_password')}
              </a>
              <button className={c.loginButton} onClick={handleLogin}>
                {t('log_in')}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state: RootStore) => ({
  token: state.user.token,
  fetching: state.user.fetching,
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    login: (username: string, password: string) => dispatch(UserActions.login(username, password)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
