import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core'
import React from 'react'
import { Colors } from '../../theme/colors'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      margin: '30px 0 20px 0',
      width: '100%',
    },
    progressBar: {
      padding: 0,
      display: 'flex',
      justifyContent: 'space-evenly',
      alignItems: 'stretch',
      border: `1px solid ${Colors.grayLight}`,
      borderRadius: '6px',
    },
    progressBarItem: {
      listStyleType: 'none',
      padding: '5px 0',
      width: '100%',
      textAlign: 'center',
      color: Colors.gray,

      '&.active': {
        backgroundColor: Colors.primary,
        color: Colors.white,
        borderRadius: '0 40px 40px 0',
        '& > p': {
          fontWeight: 'bold',
        },
      },

      '&.done': {
        backgroundColor: Colors.primary,
        color: Colors.white,
      },

      '&.final': {
        backgroundColor: Colors.primary,
        color: Colors.white,
        '& > p': {
          fontWeight: 'bold',
        },
      },
    },
  })
)

interface Props {
  phase: number
  phases: string[]
}

const ProgressBar: React.FC<Props> = ({ phase, phases }) => {
  const c = useStyles()

  const getClassName = (i: number, phase: number, phases: number) => {
    if (i === phase && phase === phases - 1) return 'final'
    if (i === phase) return 'active'
    if (i < phase) return 'done'
    return ''
  }

  return (
    <div className={c.wrapper}>
      <div className={c.progressBar}>
        {phases.map((phaseText, i) => (
          <div key={`${i}_${phaseText}`} className={`${c.progressBarItem} ${getClassName(i, phase, phases.length)}`}>
            <Typography variant='body2' color='inherit'>
              {phaseText}
            </Typography>
          </div>
        ))}
      </div>
    </div>
  )
}

export default ProgressBar
