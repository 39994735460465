const emptyData: GraphData = [
  {
    name: '',
    data: [],
  },
]

/**
 * Re-organizes data so that when displayed in a barchart
 * the compareResults and results are shown next to each other.
 *
 * This is used in per country, per category, per smart tag click
 * graphs where the data is displayed in a similar chart and
 * the analytics data is in the same format.
 *
 * @param results analytics results for timeframe
 * @param compareResults comparison results for timeframe
 */
export const combineBarGraphResults = (results: any, compareResults: any) => {
  let newData: any[] = []

  if (results && compareResults) {
    const entities = Object.keys(results)

    // Reorganize data so that actual and comparison data
    // is shown next to each other for each property
    entities.forEach((entity) => {
      newData.push({
        name: entity,
        data: results[entity].data.reduce((acc: any[], curr: any, i: number) => {
          acc.push(results[entity].data[i])
          if (compareResults[entity]) {
            acc.push(compareResults[entity].data[i])
          }
          return acc
        }, []),
      })
    })
  } else if (results && !compareResults) {
    newData = Object.entries(results).map(([id, values]: [string, any]) => ({
      name: id,
      data: values.data,
    }))
  } else {
    // If no data is available assign empty data structure so that
    // apex charts is happy about the data its receiving
    newData = emptyData
  }

  return newData
}
