import React from 'react'
import styled from 'styled-components'
import { Colors } from '../../theme/colors'
import Logo from '../Logo'
import Nav from './Nav'
import UserArea from './UserArea'

const SidebarContainer = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 50px 0;
  background-color: ${Colors.primary};
  width: 300px; // Same as main containers margin!
  height: 100vh;
`

const Block = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Sidebar = () => (
  <SidebarContainer>
    <Block>
      <Logo width={'60%'} />
      <Nav />
    </Block>
    <Block>
      <UserArea />
    </Block>
  </SidebarContainer>
)

export default Sidebar
