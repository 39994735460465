import React, { Dispatch, SetStateAction, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { v4 as uuidv4 } from 'uuid'

import ImageFailList from './ImageFailList'
import FileDropzone from './FileDropzone'
import { getScaledDimensions, scaleImage } from '../utils/files'

interface Props {
  imageLimit?: number
  imageCount?: number
  minWidth: number
  recommendedWidth?: number
  maxWidth?: number
  shrinkOversizedFiles?: boolean
  imageRatio?: ImageRatio
  images: FileAsset[]
  setImages: Dispatch<SetStateAction<FileAsset[]>>
}

const UploadImages: React.FC<Props> = ({
  imageLimit,
  imageCount,
  minWidth,
  recommendedWidth,
  maxWidth,
  shrinkOversizedFiles = true,
  imageRatio,
  images,
  setImages,
}) => {
  const { t } = useTranslation()

  const [failedImages, setFailedImages] = useState<UploadFailImage[]>([])

  const isUndersized = (width: number, minWidth: number) => minWidth && width < minWidth
  const isOversized = (width: number, maxWidth: number) => maxWidth && width > maxWidth
  const isInvalidRatio = (height: number, width: number, imageRatio: ImageRatio) => {
    const validRatio = imageRatio[0] / imageRatio[1]
    const originalRatio = width / height
    const absoluteDifference = Math.abs(validRatio - originalRatio)
    return absoluteDifference >= 0.0001
  }

  const undersizedReason = (value: number) => t('image_width_below', { limit: minWidth, value })
  const oversizedReason = (value: number) => t('image_width_above', { limit: minWidth, value })
  const invalidRatioReason = (width: number, height: number) =>
    `${t('image_ratio_invalid', { width, height })} ${t('image_width_should_be_twice_its_height')}`

  const composeFailedImage = ({ name, size }: File, width: number, height: number, reasons: string[]) => ({
    name,
    size,
    width,
    height,
    reasons,
  })

  const composeImageAsset = (file: File, src: string, width: number, height: number): FileAsset => ({
    id: uuidv4(),
    name: file.name,
    size: file.size,
    width,
    height,
    src,
    file,
  })

  const validateImage = (src: string, file: File, callback: (acceptedFile?: FileAsset) => void) => {
    const image = new Image()
    image.src = src
    image.onload = () => {
      const failReasons: string[] = []

      if (isUndersized(image.width, minWidth)) {
        failReasons.push(undersizedReason(image.width))
      }
      if (maxWidth && !shrinkOversizedFiles && isOversized(image.width, maxWidth)) {
        failReasons.push(oversizedReason(image.width))
      }
      if (imageRatio && isInvalidRatio(image.height, image.width, imageRatio)) {
        failReasons.push(invalidRatioReason(imageRatio[0], imageRatio[1]))
      }

      if (failReasons.length) {
        // VALIDATION FAILED!
        setFailedImages((prevState) => [...prevState, composeFailedImage(file, image.width, image.height, failReasons)])
        callback(undefined)
      } else if (maxWidth && isOversized(image.width, maxWidth)) {
        // VALIDATION PASSED, but image is oversized and needs to be shrinked:
        const ratio = maxWidth / image.width
        const { width, height } = getScaledDimensions(image, ratio)
        scaleImage(image, ratio, file.name)
          .then(({ file, dataUrl }) => {
            callback(composeImageAsset(file, dataUrl, width, height))
          })
          .catch(() => {
            callback(undefined)
          })
      } else {
        // VALIDATION PASSED!
        callback(composeImageAsset(file, src, image.width, image.height))
      }
    }
  }

  const imageRestrictions = []
  if (recommendedWidth) imageRestrictions.push(t('image_restrictions.recommended_width', { limit: recommendedWidth }))
  else if (minWidth) imageRestrictions.push(t('image_restrictions.min_width', { limit: minWidth }))
  if (maxWidth) imageRestrictions.push(t('image_restrictions.max_width', { limit: maxWidth }))
  if (imageRatio) imageRestrictions.push(t('image_restrictions.ratio', { width: imageRatio[0], height: imageRatio[1] }))

  const getImageCountText = (count: number, limit?: number) => {
    // Dynamic text logic written below. For now, always display the total count of images
    return `Uploaded ${t('license_images_count', { count, limit })}.`

    // if (!limit) return ''
    // if (isLimitExceeded()) return t('all_images_uploaded', { count, limit })
    // if (count === 0) return t('image_count_text', { count: limit })
    // return t('image_count_text_more', { count: limit - count })
  }

  return (
    <>
      <FileDropzone
        infoText={imageCount !== undefined ? getImageCountText(imageCount, imageLimit) : undefined}
        files={images}
        acceptedFormats={['application/pdf', 'image/jpeg']}
        setFiles={setImages}
        fileRestrictions={imageRestrictions}
        validate={validateImage}
        onDropAction={() => setFailedImages([])}
      />
      <ImageFailList images={failedImages} setImages={setFailedImages} />
    </>
  )
}

export default UploadImages
