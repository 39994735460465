import React, { useState, ChangeEvent } from 'react'
import DatePicker from 'react-datepicker'
import styled from 'styled-components'
import { DateTime } from 'luxon'
import { Switch } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import breakpoints from '../theme/breakpoints'

const DatepickerWrapper = styled.div`
  position: relative;
  margin-bottom: 1rem;

  @media ${breakpoints.md} {
    margin-bottom: 0;
  }

  .selectedDates {
    border: 1px solid #ece9f1;
    padding: 0.5rem 0.75rem;
    border-radius: 0.25rem;
    font-size: 14px;
    background-color: #fff;
    border-radius: 0.5rem;
    box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.1);

    .title {
      font-weight: bold;
    }
  }

  .date-modal {
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 20;
    display: flex;
    background-color: #fff;
    padding: 1rem;
    border-radius: 0.5rem;
    box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.1);
    flex-direction: column;

    @media ${breakpoints.lg} {
      flex-direction: row;
    }
  }

  .controls {
    display: flex;
    flex-direction: column;
    padding-left: 1rem;
  }

  .date-range-wrapper {
    display: flex;
    margin-bottom: 0.5rem;
  }

  .date-range-title {
    white-space: nowrap;
    margin-right: 1rem;
    font-weight: bold;
  }

  .comparison-toggle-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
  }

  .range-type-selector {
    min-width: 150px;
  }

  .date-range-wrapper {
    min-width: 260px;
  }

  .button-container {
    border-top: 1px solid #efefef;
    padding-top: 1rem;
    margin-top: 1rem;
    justify-content: flex-end;
    display: flex;
  }

  .button {
    border-radius: 0.25rem;
    border: none;
    font-size: 14px;
    padding: 0.5rem 0.75rem;
    cursor: pointer;
    text-transform: uppercase;
  }

  .cancel-button {
    background: #fff;
    margin-right: 0.5rem;

    &:hover {
      background-color: #ececec;
    }
  }

  .apply-button {
    background-color: #34d399;
    color: #fff;

    &:hover {
      background-color: #1eb77f;
    }
  }

  .react-datepicker {
    @media ${breakpoints.md} {
      display: flex;
    }

    .react-datepicker__month-container {
      width: 100%;

      @media ${breakpoints.md} {
        width: auto;
      }
    }
  }

  .date-selector-wrapper {
    margin-bottom: 1rem;

    @media ${breakpoints.lg} {
      margin-bottom: 0;
    }
    .react-datepicker__day--in-range {
      background-color: #4f9df4;
    }

    .react-datepicker__day--highlighted {
      background-color: #de8436;
    }
  }

  .comparison-date-selector-wrapper {
    margin-bottom: 1rem;

    @media ${breakpoints.lg} {
      margin-bottom: 0;
    }

    .react-datepicker__day--in-range {
      background-color: #ff8821;
    }

    .react-datepicker__day--in-selecting-range {
      background-color: #d78844;
    }

    .react-datepicker__day--highlighted {
      background-color: #4f9df4;
    }
  }
`

const DateTextInputWrapper = styled.div<{ active: boolean; highlightColor: string; backgroundColor: string }>`
  display: flex;

  .react-datepicker-popper {
    display: none;
  }

  .react-datepicker__input-container {
    input {
      width: 100%;
      border-style: solid;
      padding: 0.25rem 0.5rem;
      border-radius: 0.25rem;
      border-width: 1px;
      background-color: ${(props) => (props.active ? props.backgroundColor : 'transparent')};
      color: ${(props) => (props.active ? '#fff' : '#000')};

      border-color: ${(props) => (props.active ? props.highlightColor : 'black')};
    }
  }
`
/**
 * Returns a human readable string representation of a
 * date selected from the datepicker
 * @param date Date object
 * @returns string, eg: 1.1.2001
 */
const displayDate = (date: Date | null): string => {
  if (!date) return ''
  return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`
}

enum DateRangeType {
  Custom = 'custom',
  Days7 = 'days7',
  Days30 = 'days30',
  Months12 = 'months12',
  PreviousPeriod = 'previousPeriod',
}

type DateRangeSelectorPropTypes = {
  onSelect: (selection: DateRangeSelection) => void
  dateRange: any
  comparisonRange: any
  maxAccessableHistoryLength: number | null
}

type SelectionTypeObject = {
  value: DateRangeType
  label: string
  dayAmount: number
}

type SelectionTypes = SelectionTypeObject[]

const DateRangeSelector: React.FC<DateRangeSelectorPropTypes> = ({
  onSelect,
  dateRange,
  comparisonRange,
  maxAccessableHistoryLength,
}) => {
  const [startDate, setStartDate] = useState<Date | null>(dateRange.start)
  const [endDate, setEndDate] = useState<Date | null>(dateRange.end)

  // dayAmount is used to filter selection types away when there are
  // subscription type based limitations
  const [startComparisonDate, setStartComparisonDate] = useState<Date | null>(null)
  const [endComparisonDate, setEndComparisonDate] = useState<Date | null>(null)

  const [showComparisonDateSelector, setShowComparisonDateSelector] = useState(false)
  const [compareActive, setCompareActive] = useState(false)

  const [comparisonSelectionType, setComparisonSelectionType] = useState<DateRangeType>(DateRangeType.PreviousPeriod)
  const [selectionType, setSelectionType] = useState<DateRangeType>(DateRangeType.Custom)

  const [modalVisible, setModalVisible] = useState<boolean>(false)

  const { t } = useTranslation()

  const selectionTypes = [
    {
      value: DateRangeType.Custom,
      label: t('analytics.custom'),
      dayAmount: 0, // custom should be always shown
    },
    {
      value: DateRangeType.Days7,
      label: t('analytics.last_7_days'),
      dayAmount: 7,
    },
    {
      value: DateRangeType.Days30,
      label: t('analytics.last_30_days'),
      dayAmount: 30,
    },
    {
      value: DateRangeType.Months12,
      label: t('analytics.last_12_months'),
      dayAmount: 365,
    },
  ]

  const compareSelectionTypes = [
    {
      value: DateRangeType.Custom,
      label: t('analytics.custom'),
      dayAmount: 0, // custom should be always shown
    },
    {
      value: DateRangeType.PreviousPeriod,
      label: t('analytics.previous_period'),
      dayAmount: 0,
    },
  ]

  const minDate = maxAccessableHistoryLength
    ? DateTime.now().minus({ days: maxAccessableHistoryLength }).toJSDate()
    : null
  const maxDate = maxAccessableHistoryLength ? DateTime.now().toJSDate() : null

  const updateComparisonDateRange = (start: any, end: any) => {
    // By default try to compare to similar range than start and end dates
    const dayAmount = dayAmountInBetween(start, end)
    setStartComparisonDate(
      DateTime.fromJSDate(start)
        .minus({ days: dayAmount + 1 })
        .toJSDate()
    )
    setEndComparisonDate(DateTime.fromJSDate(start).minus({ days: 1 }).toJSDate())
  }

  /**
   * TODO: fix typing
   *
   * onChange is typed incorrectly in the @types typings. In this case we always receive
   * an array with two values like so:
   * [Date | null, Date | null]
   */
  const onChange = (dates: any) => {
    const [start, end] = dates

    setStartDate(start)
    setEndDate(end)

    if (startComparisonDate && endComparisonDate && comparisonSelectionType === DateRangeType.PreviousPeriod) {
      updateComparisonDateRange(start, end)
    }
  }

  const handleStartTextInput = (date: any) => {
    setStartDate(date)
    if (startComparisonDate && endComparisonDate && comparisonSelectionType === DateRangeType.PreviousPeriod) {
      updateComparisonDateRange(date, endDate)
    }
  }
  const handleEndTextInput = (date: any) => {
    setEndDate(date)
    if (startComparisonDate && endComparisonDate && comparisonSelectionType === DateRangeType.PreviousPeriod) {
      updateComparisonDateRange(startDate, date)
    }
  }
  const handleStartComparisonTextInput = (date: any) => setStartComparisonDate(date)
  const handleEndComparisonTextInput = (date: any) => setEndComparisonDate(date)

  const onChangeComparison = (dates: any) => {
    const [start, end] = dates

    setStartComparisonDate(start)
    setEndComparisonDate(end)
  }

  const dayAmountInBetween = (start: Date, end: Date) => {
    if (!start || !end) return 0

    const startDate = DateTime.fromJSDate(start)
    const endDate = DateTime.fromJSDate(end)

    const { days } = endDate.diff(startDate, 'days').toObject()
    return days || 0
  }

  /**
   * Returns array of date objects for each day between start and end
   * including start and end dates
   * @param start Date object
   * @param end Date object
   * @returns Array of dates between start and end
   */
  const getDateRangeDates = (start: Date, end: Date): Array<Date> | undefined => {
    let dateRange = []

    const startDate = DateTime.fromJSDate(start)
    const endDate = DateTime.fromJSDate(end)

    const { days: daysAmount } = endDate.diff(startDate, 'days').toObject()

    dateRange.push(startDate.toJSDate())

    if (!daysAmount) return dateRange
    /**
     * Sometimes days amount would be 5.9999 instead
     * of 6. This could have something to do with the fact
     * that the selected dtes have timestamps in them.
     * Removing those could help with reliability.
     */
    const rounded = Math.round(daysAmount)

    for (let i = 1; i <= rounded; i++) {
      dateRange.push(startDate.plus({ days: i }).toJSDate())
    }

    return dateRange
  }

  const getHightlightDates = () => {
    if (!startComparisonDate || !endComparisonDate) return undefined
    return getDateRangeDates(startComparisonDate, endComparisonDate)
  }

  const getComparisonHightlightDates = () => {
    if (!startDate || !endDate) return undefined
    return getDateRangeDates(startDate, endDate)
  }

  const toggleComparison = () => {
    if (compareActive) {
      setStartComparisonDate(null)
      setEndComparisonDate(null)

      setCompareActive(false)
    } else {
      setCompareActive(true)
      updateComparisonDateRange(startDate, endDate)
    }
  }

  const handleComparisonSelectionTypeChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const rangeType = event.target.value

    if (!startDate) return

    if (rangeType === DateRangeType.Custom) {
      setComparisonSelectionType(DateRangeType.Custom)
    } else if (rangeType === DateRangeType.PreviousPeriod) {
      switch (selectionType) {
        case DateRangeType.Days7:
          setEndComparisonDate(DateTime.fromJSDate(startDate).minus({ days: 1 }).toJSDate())
          setStartComparisonDate(DateTime.fromJSDate(startDate).minus({ days: 7 }).toJSDate())
          break
        case DateRangeType.Days30:
          setEndComparisonDate(DateTime.fromJSDate(startDate).minus({ days: 1 }).toJSDate())
          setStartComparisonDate(DateTime.fromJSDate(startDate).minus({ days: 30 }).toJSDate())
          break
        case DateRangeType.Months12:
          setEndComparisonDate(DateTime.fromJSDate(startDate).minus({ days: 1 }).toJSDate())
          setStartComparisonDate(DateTime.fromJSDate(startDate).minus({ days: 365 }).toJSDate())
      }
      setComparisonSelectionType(DateRangeType.PreviousPeriod)
    }
  }

  const handleSelectionTypeChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const rangeType = event.target.value
    const compareShouldUpdate = compareActive && comparisonSelectionType === DateRangeType.PreviousPeriod

    switch (rangeType) {
      case DateRangeType.Custom:
        setSelectionType(DateRangeType.Custom)
        break
      case DateRangeType.Days7:
        setEndDate(DateTime.now().minus({ days: 1 }).toJSDate())
        setStartDate(DateTime.now().minus({ days: 7 }).toJSDate())
        if (compareShouldUpdate) {
          setEndComparisonDate(DateTime.now().minus({ days: 8 }).toJSDate())
          setStartComparisonDate(DateTime.now().minus({ days: 14 }).toJSDate())
        }
        setSelectionType(DateRangeType.Days7)
        break
      case DateRangeType.Days30:
        setEndDate(DateTime.now().minus({ days: 1 }).toJSDate())
        setStartDate(DateTime.now().minus({ days: 30 }).toJSDate())
        if (compareShouldUpdate) {
          setEndComparisonDate(DateTime.now().minus({ days: 31 }).toJSDate())
          setStartComparisonDate(DateTime.now().minus({ days: 60 }).toJSDate())
        }
        setSelectionType(DateRangeType.Days30)
        break
      case DateRangeType.Months12:
        setEndDate(DateTime.now().minus({ days: 1 }).toJSDate())
        setStartDate(DateTime.now().minus({ days: 365 }).toJSDate())
        if (compareShouldUpdate) {
          setEndComparisonDate(DateTime.now().minus({ days: 366 }).toJSDate())
          setStartComparisonDate(DateTime.now().minus({ days: 731 }).toJSDate())
        }
        setSelectionType(DateRangeType.Months12)
    }
  }

  /**
   * Renders dropdowns for date selection type and limits
   * the options based on a maxAccessableHistoryLength.
   * For example if the maximum history for a subscription
   * type is 7 days, the 30 day option is not selectable.
   *
   * @param types selectiontypes for daterange dropdown
   * @param maxAccessableHistoryLength limitation based on subscription
   * @returns option array
   */
  const renderSelectionTypes = (types: SelectionTypes, maxAccessableHistoryLength: number | null) => {
    return types
      .filter((t) => {
        if (!maxAccessableHistoryLength) return true
        return t.dayAmount <= maxAccessableHistoryLength
      })
      .map((type) => (
        <option key={type.value} value={type.value}>
          {type.label}
        </option>
      ))
  }

  const handleApply = () => {
    onSelect({
      dateRange: {
        start: startDate,
        end: endDate,
      },
      comparisonRange: {
        start: startComparisonDate,
        end: endComparisonDate,
      },
    })
    setModalVisible(false)
  }

  const handleCancel = () => {
    setStartDate(dateRange.start)
    setEndDate(dateRange.end)
    setModalVisible(false)
  }

  return (
    <DatepickerWrapper>
      <div className='selectedDates' onClick={() => setModalVisible(!modalVisible)}>
        <span className='title'>{t('analytics.date_range')}: </span>
        <span>
          {displayDate(startDate)} - {displayDate(endDate)}
        </span>
      </div>
      {modalVisible && (
        <div className='date-modal'>
          <div className='date-selector-wrapper'>
            {!showComparisonDateSelector && (
              <DatePicker
                focusSelectedMonth={true}
                minDate={minDate}
                maxDate={maxDate}
                dateFormat='dd.MM.yyyy'
                selected={startDate}
                onChange={onChange}
                startDate={startDate}
                endDate={endDate}
                highlightDates={getHightlightDates()}
                monthsShown={3}
                calendarStartDay={1}
                selectsRange
                disabledKeyboardNavigation
                inline
              />
            )}
          </div>
          <div className='comparison-date-selector-wrapper'>
            {showComparisonDateSelector && (
              <DatePicker
                focusSelectedMonth={true}
                dateFormat='dd.MM.yyyy'
                selected={startComparisonDate}
                onChange={onChangeComparison}
                startDate={startComparisonDate}
                highlightDates={getComparisonHightlightDates()}
                endDate={endComparisonDate}
                monthsShown={3}
                calendarStartDay={1}
                selectsRange
                disabledKeyboardNavigation
                inline
              />
            )}
          </div>
          <div>
            <div className='controls'>
              <div className='date-settings'>
                <div className='date-range-wrapper'>
                  <div className='date-range-title'>
                    <span>Date range</span>
                  </div>
                  <select value={selectionType} onChange={handleSelectionTypeChange} className='range-type-selector'>
                    {renderSelectionTypes(selectionTypes, maxAccessableHistoryLength)}
                  </select>
                </div>
                <DateTextInputWrapper
                  onClick={() => setShowComparisonDateSelector(false)}
                  active={!showComparisonDateSelector}
                  highlightColor='#3374C6'
                  backgroundColor='#4f9df4'
                >
                  <DatePicker selected={startDate} onChange={handleStartTextInput} dateFormat='dd.MM.yyyy' />
                  -
                  <DatePicker selected={endDate} onChange={handleEndTextInput} dateFormat='dd.MM.yyyy' />
                </DateTextInputWrapper>
              </div>
              <div className='comparison-toggle-wrapper'>
                <div>{t('analytics.compare')}</div>
                <Switch checked={compareActive} onChange={toggleComparison} />
              </div>
              {compareActive && (
                <div className='comparison-date-settings'>
                  <div className='date-range-wrapper'>
                    <div className='date-range-title'>
                      <span>{t('analytics.compare_to')}</span>
                    </div>
                    <select
                      value={comparisonSelectionType}
                      onChange={handleComparisonSelectionTypeChange}
                      className='range-type-selector'
                    >
                      {renderSelectionTypes(compareSelectionTypes, maxAccessableHistoryLength)}
                    </select>
                  </div>
                  <DateTextInputWrapper
                    onClick={() => setShowComparisonDateSelector(true)}
                    active={showComparisonDateSelector}
                    highlightColor='#DE8436'
                    backgroundColor='#ff8821'
                  >
                    <DatePicker
                      selected={startComparisonDate}
                      onChange={handleStartComparisonTextInput}
                      dateFormat='dd.MM.yyyy'
                    />
                    -
                    <DatePicker
                      selected={endComparisonDate}
                      onChange={handleEndComparisonTextInput}
                      dateFormat='dd.MM.yyyy'
                    />
                  </DateTextInputWrapper>
                </div>
              )}
              <div>
                <div className='button-container'>
                  <button className='button cancel-button' onClick={handleCancel}>
                    {t('analytics.cancel')}
                  </button>
                  <button className='button apply-button' onClick={handleApply}>
                    {t('analytics.apply')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </DatepickerWrapper>
  )
}

export default DateRangeSelector
