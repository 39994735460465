import React from 'react'
import AnalyticsChart from './AnalyticsChart'
import { useSelector } from 'react-redux'
import cloneDeep from 'lodash.clonedeep'
import { IMAGE_VIEWS_PER_COUNTRY_OPTIONS } from '../../utils/chartSettings'
import { combineBarGraphResults } from '../../utils/analyticsHelper'

const ViewsByCountryChart: React.FC<ChartWrapperProps> = (props) => {
  const storeData = useSelector((state: RootStore) => state.analytics.viewsByCountry)
  const { results, compareResults } = storeData

  const newData = combineBarGraphResults(results, compareResults)

  /**
   * ApexCharts doesn't like if it gets passed data or options
   * objects that are not cloned (they cannot have any references
   * to old values)
   */
  const clonedData = cloneDeep(newData)
  const clonedOpts = cloneDeep(IMAGE_VIEWS_PER_COUNTRY_OPTIONS)

  return <AnalyticsChart {...props} series={clonedData} options={clonedOpts} />
}

export default ViewsByCountryChart
