import { ApexOptions } from 'apexcharts'

// any has to be used to satisfy apexcharts
export const EMBEDDED_CHART_TYPES: { [key: string]: any } = {
  hcr_villas_line: 'line',
  hcr_villas_bar: 'bar',
}

const hcrVillasLineOptions: ApexOptions = {
  colors: ['#FD0000', '#757576'],
  dataLabels: {
    enabled: true,
    enabledOnSeries: [0],
    formatter: (v) => `${v}%`,
    offsetY: -5,
    style: {
      colors: ['#000'],
    },
    background: {
      enabled: false,
      dropShadow: {
        enabled: false,
      },
    },
  },
  xaxis: {
    labels: {
      rotateAlways: true,
    },
  },
  yaxis: {
    labels: {
      formatter: (v) => `${v}%`,
    },
  },
  stroke: {
    colors: ['#FD0000', '#757576'],
    width: [4, 3],
  },
  chart: {
    background: '#fff',
    toolbar: {
      show: false,
    },
  },
  grid: {
    padding: {
      right: 30,
    },
  },
  legend: {
    show: true,
    showForSingleSeries: true,
  },
}

const hcrVillasBarOptions: ApexOptions = {
  colors: ['#FD0000'],
  dataLabels: {
    enabled: true,
    enabledOnSeries: [0],
    formatter: (v) => `${v}%`,
    offsetY: -5,
    style: {
      colors: ['#000'],
    },
    background: {
      enabled: false,
      dropShadow: {
        enabled: false,
      },
    },
  },
  xaxis: {
    labels: {
      rotateAlways: true,
    },
  },
  yaxis: {
    labels: {
      formatter: (v) => `${v}%`,
    },
  },
  chart: {
    background: '#fff',
    toolbar: {
      show: false,
    },
  },
  grid: {
    padding: {
      right: 30,
    },
  },
  legend: {
    show: false,
  },
}

export const EMBEDDED_CHART_OPTIONS: { [key: string]: ApexOptions } = {
  hcr_villas_line: hcrVillasLineOptions,
  hcr_villas_bar: hcrVillasBarOptions,
}
