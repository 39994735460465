import React, { useEffect, useState, ChangeEvent, SetStateAction, useCallback } from 'react'
import { connect } from 'react-redux'
import { AnyAction, Dispatch } from 'redux'
import { useParams, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Typography, createStyles, makeStyles, Theme, Divider, FormControlLabel, Checkbox } from '@material-ui/core'

import PresentationActions from '../redux/PresentationRedux'
import CategoryActions from '../redux/CategoryRedux'
import Storage from '../utils/storage'
import Spinner from '../components/Spinner'
import Button from '../components/Button'
import Dialog from '../components/Dialog'
import AddNewDialog from '../components/Presentations/AddNewDialog'
import ImagePreviewDialog from '../components/ImagePreviewDialog'
import TextPreview from '../components/TextPreview'
import TranslationsCount from '../components/TranslationsCount'
import CategoryList from '../components/CategoryList'
import SubscriptionTag from '../components/SubscriptionTag'
import TranslationsEditDialog from '../components/TranslationsEditDialog'
import HelpTip from '../components/HelpTip'
import HContainer from '../components/Layout/HContainer'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contentContainer: {
      backgroundColor: '#fff',
      borderRadius: '1rem',
      padding: '1rem',
      marginTop: '1.5rem',
    },
    presentationContainer: {
      margin: '30px 0',
    },
    item: {
      margin: '20px 0 40px 0',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'start',
    },
    itemDetails: {
      width: '60%',
      '& > p': {
        marginTop: '20px',
      },
    },
    subtitle: {
      fontWeight: 700,
      '& > span.nowrap': {
        display: 'block',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        '& > a': {
          fontWeight: 300,
        },
      },
    },
    presentationsImageButtons: {
      margin: '20px 0 0 0',
    },
    buttonContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '& .sendConfirmation': {
        fontSize: '24px',
      },
    },
    buttonGroup: {
      marginTop: '10px',
      marginBottom: '50px',
      display: 'flex',
      justifyContent: 'center',
    },
  })
)

interface Props {
  presentation: FullPresentation | null
  fetching: boolean
  updating: boolean
  getPresentation: (id: string) => AnyAction
  setPresentation: (presentation: FullPresentation) => AnyAction
  clearPresentation: () => AnyAction
  sendToVisualizerCms: (
    payload: {
      id: string
      termsAccepted: boolean
    },
    onSuccess: Function | undefined
  ) => AnyAction
  updateCategory: (categoryType: EditableCategory) => AnyAction
}

const PresentationPreview: React.FC<Props> = ({
  presentation,
  fetching,
  updating,
  getPresentation,
  setPresentation,
  clearPresentation,
  sendToVisualizerCms,
  updateCategory,
}) => {
  const c = useStyles()
  const { t } = useTranslation()
  const history = useHistory()
  const { id } = useParams<{ id: string }>()

  const [initialLoad, setInitialLoad] = useState(true)
  const [termsAccepted, setTermsAccepted] = useState(false)

  const [meetingRooms, setMeetingRooms] = useState<Category[]>([])
  const [rooms, setRooms] = useState<Category[]>([])
  const [restaurants, setRestaurants] = useState<Category[]>([])
  const [publicAreas, setPublicAreas] = useState<Category[]>([])
  const [unsavedCategories, setUnsavedCategories] = useState(false)
  const [isDragging, setIsDragging] = useState(false)

  const setCategoryState = (setter: React.Dispatch<SetStateAction<Category[]>>) => (value: Category[]) => {
    setUnsavedCategories(true)
    setter(value)
  }

  const selectedLanguageCount = (languages: SelectableLanguage[]) => languages.filter((l) => l.isSelected).length

  // Fetch presentation from api:
  useEffect(() => {
    if (!presentation && id && !fetching && !updating) getPresentation(id)
  }, [fetching, getPresentation, id, presentation, updating])

  // Get category groups from presentation
  useEffect(() => {
    if (presentation) {
      const { categories } = presentation
      setMeetingRooms(categories.filter((c) => c.type === 'meeting-room'))
      setRooms(categories.filter((c) => c.type === 'room'))
      setRestaurants(categories.filter((c) => c.type === 'restaurant'))
      setPublicAreas(categories.filter((c) => c.type === 'public-area'))
      setInitialLoad(false)
    }
  }, [presentation])

  const saveCategoriesToApi = useCallback(() => {
    if (presentation) {
      setPresentation({
        ...presentation,
        categories: [...meetingRooms, ...rooms, ...restaurants, ...publicAreas],
      })
    }
  }, [meetingRooms, presentation, publicAreas, restaurants, rooms, setPresentation])

  useEffect(() => {
    if (!isDragging && unsavedCategories && meetingRooms && rooms && restaurants && publicAreas) {
      // Save reordered categories after two seconds (if there was no more changes during this timeout)
      const delayedSave = setTimeout(() => {
        saveCategoriesToApi()
        setUnsavedCategories(false)
      }, 2000)
      return () => clearTimeout(delayedSave)
    }
  }, [unsavedCategories, saveCategoriesToApi, meetingRooms, rooms, restaurants, publicAreas, isDragging])

  const saveTexts = (context: 'introTexts' | 'requestForProposal' | 'description' | 'name', categoryId?: string) => (
    texts: Translation[]
  ) => {
    const updateCategoryTexts = (categories: Category[], id: string) =>
      categories.map((category: Category) => {
        if (category.id !== id) return category
        return {
          ...category,
          name: context === 'name' ? texts : category.name,
          description: context === 'description' ? texts : category.description,
        }
      })

    if (presentation) {
      setPresentation({
        ...presentation,
        introTexts: context === 'introTexts' ? texts : presentation.introTexts,
        requestForProposal: context === 'requestForProposal' ? texts : presentation.requestForProposal,
        categories: !categoryId ? presentation.categories : updateCategoryTexts(presentation.categories, categoryId),
      })
    }
  }

  const handleSend = () => {
    sendToVisualizerCms(
      {
        id,
        termsAccepted,
      },
      () => history.push(`/presentations`)
    )
  }

  const handleCancel = () => {
    clearPresentation()
    history.push(`/presentations`)
  }

  const handleResumeDraft = () => {
    history.push(`/presentations/${id}`)
  }

  const handleDeleteCategory = (id: string | undefined, name?: string) => {
    if (id && presentation) {
      if (window.confirm(`${t('delete')}${name ? ` ${name}` : ''}?`)) {
        setPresentation({
          ...presentation,
          // Remove category from the categories list:
          categories: presentation?.categories.filter((category) => category.id !== id),
        })
      }
    }
  }

  const handleEditCategory = (id: string | undefined) => {
    if (!id) return null

    const category = presentation?.categories.find((category: Category) => category.id === id)

    if (category && presentation) {
      const { id, index, type, name, description, capacity, images, panoramaImages } = category

      updateCategory({
        id,
        index,
        type,
        name,
        description,
        capacity,
        images,
        panoramaImages,
      })

      // Clear session storage in case of an old draft
      Storage.sessionWrite(Storage.KEYS.CATEGORY_DRAFT, null)

      history.push(`/presentations/${presentation.id}/${category.type}`)
    }
  }

  const handleTermsAcceptedChange = (e: ChangeEvent<HTMLInputElement>) => {
    setTermsAccepted(e.target.checked)
  }

  const ActionButtons: React.FC = () => (
    <div className={c.buttonContainer}>
      <div className='sendConfirmation'>
        <FormControlLabel
          control={
            <Checkbox
              checked={termsAccepted}
              onChange={handleTermsAcceptedChange}
              name='send-confirmation-checkbox'
              color='primary'
            />
          }
          label={t('send_confirmation')}
        />
        <HelpTip helpTextKey='sendConfirmation' placement='top-end' />
      </div>
      <div className={c.buttonGroup}>
        <Button
          text={unsavedCategories ? <Spinner size='medium' /> : t('go_back')}
          color='default'
          variant='outlined'
          size='large'
          onClick={handleCancel}
          disabled={unsavedCategories}
        />
        {presentation && (
          <Dialog
            maxWidth='md'
            triggerButtonProps={{
              text: termsAccepted && unsavedCategories ? <Spinner size='medium' /> : t('send'),
              color: 'primary',
              size: 'large',
              disabled: !termsAccepted || unsavedCategories,
            }}
            dialogActionButton={
              <Button
                text={t('send')}
                color='primary'
                onClick={handleSend}
                disabled={!termsAccepted || unsavedCategories}
              />
            }
          >
            <div>
              <Typography>{t('send_alert')}</Typography>
            </div>
          </Dialog>
        )}
      </div>
    </div>
  )

  const PresentationBasicInfo: React.FC<FullPresentation> = ({
    name,
    licenseName,
    supportedLanguages,
    introTexts,
    licenseImages,
    mapMarker,
    floorplan,
    capacityChart,
    requestForProposal,
    videoLink,
    hygieneGuidelines,
  }) => {
    const selectedLanguages = supportedLanguages.filter((l) => l.isSelected)
    const licenseImageText = t('license_images_count', { count: licenseImages.count, limit: licenseImages.limit })

    return (
      <div className={c.item}>
        <div className={c.itemDetails}>
          {name && <Typography variant='h4'>{name}</Typography>}
          <SubscriptionTag subscription={licenseName} />

          <Typography variant='body2'>
            <span className={c.subtitle}>{`${t('images')}`}: </span>
            <span>{licenseImages.count ? licenseImageText : t('no_images')}</span>
          </Typography>

          {supportedLanguages && (
            <Typography variant='body2'>
              <span className={c.subtitle}>{t('supported_languages')}: </span>
              {selectedLanguages.map((lang, i) => (
                <span key={lang.code}>
                  <span>{t(`languages.${lang.code}`)}</span>
                  <span>{i === 0 ? ` (${t('default')})` : ''}</span>
                  <span>{i < selectedLanguages.length - 1 ? ', ' : '.'}</span>
                </span>
              ))}
            </Typography>
          )}

          {introTexts && introTexts.length > 0 && (
            <>
              <Typography variant='body2'>
                <TranslationsEditDialog
                  texts={introTexts}
                  onSave={saveTexts('introTexts')}
                  title={t('intro_text')}
                  isHtmlEditor
                >
                  <TextPreview text={introTexts[0].text}>
                    <span className={c.subtitle}>{t('intro_text')}:</span>
                  </TextPreview>
                  <TranslationsCount translations={introTexts} limit={selectedLanguages.length} />
                </TranslationsEditDialog>
              </Typography>
            </>
          )}

          {videoLink && (
            <Typography variant='body2'>
              <span className={c.subtitle}>
                <span className='nowrap'>
                  {`${t('video_link')}: `}
                  <a href={videoLink.url} target='_blank' rel='noopener noreferrer'>
                    {videoLink.url}
                  </a>
                </span>
              </span>
            </Typography>
          )}

          {hygieneGuidelines && (
            <Typography variant='body2'>
              <span className={c.subtitle}>{`${t('hygiene_guidelines')}: `}</span>
              <span>{hygieneGuidelines.name}</span>
            </Typography>
          )}

          {requestForProposal && requestForProposal.length > 0 && (
            <>
              <Typography variant='body2'>
                <TranslationsEditDialog
                  texts={requestForProposal}
                  onSave={saveTexts('requestForProposal')}
                  title={t('request_for_proposal')}
                  isHtmlEditor
                >
                  <TextPreview text={requestForProposal[0].text}>
                    <span className={c.subtitle}>{t('request_for_proposal')}:</span>
                  </TextPreview>
                  <TranslationsCount translations={requestForProposal} limit={selectedLanguages.length} />
                </TranslationsEditDialog>
              </Typography>
            </>
          )}

          <div className={c.presentationsImageButtons}>
            {mapMarker && <ImagePreviewDialog images={[mapMarker]} type='map-marker' />}

            {floorplan && <ImagePreviewDialog images={floorplan} type='floorplans' buttonColor='secondary' />}

            {capacityChart && (
              <ImagePreviewDialog images={capacityChart} type='capacity-charts' buttonColor='secondary' />
            )}
          </div>
        </div>
        <div>
          <Button
            text={unsavedCategories ? <Spinner size='small' /> : t('resume_draft')}
            onClick={handleResumeDraft}
            disabled={unsavedCategories}
          />
          <AddNewDialog
            hidePreviewButton
            presentationId={id}
            triggerButtonProps={{
              text: unsavedCategories ? <Spinner size='small' /> : t('add_new'),
              color: 'secondary',
              disabled: unsavedCategories,
            }}
          />
        </div>
      </div>
    )
  }

  return (
    <HContainer>
      <div className={c.contentContainer}>
        {updating && <Spinner isFullScreen progressText={t('saving')} />}

        <Typography variant='h2'>{`${t('preview')} & ${t('send')}`}</Typography>

        {initialLoad && <Spinner />}

        {presentation && (
          <>
            <div className={c.presentationContainer}>
              <Divider />
              <PresentationBasicInfo {...presentation} />
              <CategoryList
                categories={meetingRooms}
                title={t('meeting_rooms')}
                languageCount={selectedLanguageCount(presentation.supportedLanguages)}
                onEditItem={handleEditCategory}
                onDeleteItem={handleDeleteCategory}
                onEditText={saveTexts}
                setCategories={setCategoryState(setMeetingRooms)}
                setIsDragging={setIsDragging}
                unsavedChanges={unsavedCategories}
                helpTipKey='categoryList'
              />
              <CategoryList
                categories={rooms}
                title={t('rooms')}
                languageCount={selectedLanguageCount(presentation.supportedLanguages)}
                onEditItem={handleEditCategory}
                onDeleteItem={handleDeleteCategory}
                onEditText={saveTexts}
                setCategories={setCategoryState(setRooms)}
                setIsDragging={setIsDragging}
                unsavedChanges={unsavedCategories}
                helpTipKey='categoryList'
              />
              <CategoryList
                categories={restaurants}
                title={t('restaurants')}
                languageCount={selectedLanguageCount(presentation.supportedLanguages)}
                onEditItem={handleEditCategory}
                onDeleteItem={handleDeleteCategory}
                onEditText={saveTexts}
                setCategories={setCategoryState(setRestaurants)}
                setIsDragging={setIsDragging}
                unsavedChanges={unsavedCategories}
                helpTipKey='categoryList'
              />
              <CategoryList
                categories={publicAreas}
                title={t('public_areas')}
                languageCount={selectedLanguageCount(presentation.supportedLanguages)}
                onEditItem={handleEditCategory}
                onDeleteItem={handleDeleteCategory}
                onEditText={saveTexts}
                setCategories={setCategoryState(setPublicAreas)}
                setIsDragging={setIsDragging}
                unsavedChanges={unsavedCategories}
                helpTipKey='categoryList'
              />
            </div>

            <ActionButtons />
          </>
        )}
      </div>
    </HContainer>
  )
}

const mapStateToProps = (state: RootStore) => {
  return {
    presentation: state.presentation.content,
    fetching: state.presentation.fetching,
    updating: state.presentation.updating,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    getPresentation: (id: string) => dispatch(PresentationActions.getPresentation(id)),
    setPresentation: (presentation: FullPresentation) => dispatch(PresentationActions.setPresentation(presentation)),
    sendToVisualizerCms: (payload: { id: string; termsAccepted: boolean }, onSuccess: Function | undefined) =>
      dispatch(PresentationActions.sendToVisualizerCms(payload, onSuccess)),
    clearPresentation: () => dispatch(PresentationActions.clearPresentation()),
    updateCategory: (category: EditableCategory) => dispatch(CategoryActions.updateCategory(category)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PresentationPreview)
