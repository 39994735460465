/**
 * Analytics datepicker daterange is limited by the
 * lowest selected subscription tier. If 3 presentations
 * are selected and one has "Engage" tier, it will limit
 * the datepicker to 7 days for all the currently selected
 * presentations.
 *
 * Used to differentiate the "stengths" of different
 * subscription tiers so that we can determine the
 * weakest subscription of a set of subscriptions.
 */
export const SUBSCRIPTION_TIERS: { [name: string]: number } = {
  Engage: 2,
  'Engage / no index': 2,
  'Self Starter': 2,

  'Engage&Sell PRO': 2,
  'Engage&Sell': 2,
  'Engage&Sell / no index': 2,
  'Engage&Sell Pro': 2,
  'Engage&Sell Pro / no index': 2,
  Distribution: 2,
  Testing: 2,
  Demo: 2,
  Advanced: 2,
}

/**
 * Map of the maximum history length in days for datepicker
 * for particular subscription type.
 *
 * null means no limitations.
 */
export const MAX_HISTORY_LENGTH: { [name: string]: number | null } = {
  Engage: null,
  'Engage / no index': null,
  'Self Starter': null,

  'Engage&Sell PRO': null,
  'Engage&Sell': null,
  'Engage&Sell / no index': null,
  'Engage&Sell Pro': null,
  'Engage&Sell Pro / no index': null,
  Distribution: null,
  Testing: null,
  Demo: null,
  Advanced: null,
}
