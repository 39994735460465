import React, { Dispatch, SetStateAction, useState } from 'react'
import { Typography, makeStyles, Theme, createStyles } from '@material-ui/core'
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  DroppableProvided,
  DraggableProvided,
} from 'react-beautiful-dnd'
import CategoryListItem from './CategoryListItem'
import { Colors } from '../theme/colors'
import { useTranslation } from 'react-i18next'
import HelpTip from './HelpTip'
import Icons from '../theme/Icons'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      borderBottom: `1px solid ${Colors.white}`,
    },
    title: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      backgroundColor: Colors.secondary,
      color: Colors.white,
      padding: '12px 18px',
      cursor: 'pointer',
      opacity: 1,
      userSelect: 'none',

      '&:hover': {
        opacity: 0.85,
      },

      '& div': {
        display: 'flex',
        alignItems: 'center',
      },
    },
    list: {
      listStyleType: 'none',
      margin: 0,
      padding: 0,
    },
  })
)

const reorder = (list: Category[], startIndex: number, endIndex: number) => {
  const result = [...list]
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result
}

interface Props {
  categories: Category[] | undefined
  title: string
  languageCount: number
  onEditItem: (id: string | undefined) => void
  onDeleteItem: (id: string | undefined, name?: string) => void
  onEditText: (context: 'description' | 'name', categoryId: string) => (texts: Translation[]) => void
  setCategories: (value: Category[]) => void
  setIsDragging: Dispatch<SetStateAction<boolean>>
  helpTipKey?: string
  unsavedChanges?: boolean
}

const CategoryList: React.FC<Props> = ({
  categories,
  title,
  languageCount,
  onEditItem,
  onDeleteItem,
  onEditText,
  setCategories,
  setIsDragging,
  helpTipKey,
  unsavedChanges = false,
}) => {
  const { t } = useTranslation()
  const c = useStyles()

  const [isOpen, setIsOpen] = useState(false)

  const toggleList = () => {
    setIsOpen(!isOpen)
  }

  const onDragStart = () => {
    setIsDragging(true)
  }

  const onDragEnd = (result: DropResult) => {
    setIsDragging(false)
    if (!result.destination) return null
    if (result.destination.index === result.source.index) return null
    if (categories && setCategories) setCategories(reorder(categories, result.source.index, result.destination.index))
  }

  if (!categories || categories.length === 0) return null

  return (
    <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
      <div className={c.container}>
        <div className={c.title} onClick={toggleList}>
          <div>
            {isOpen ? <Icons.CaretDown /> : <Icons.CaretRight />}
            <Typography variant='h5' color='inherit'>
              {`${title} (${categories.length})`}
              {helpTipKey && <HelpTip helpTextKey={helpTipKey} color='white' />}
            </Typography>
          </div>
          {isOpen && categories.length > 1 && (
            <Typography variant='body2' color='inherit'>
              {t('arrange_by_dnd')}
            </Typography>
          )}
        </div>
        <Droppable droppableId='droppable'>
          {(provided: DroppableProvided) => (
            <ul ref={provided.innerRef} className={c.list} {...provided.droppableProps}>
              {isOpen &&
                categories.map((item, index) => {
                  if (!item.id) return null
                  else
                    return (
                      <Draggable key={item.id} draggableId={item.id} index={index}>
                        {(provided: DraggableProvided, snapshot) => (
                          <li key={item.id}>
                            {/* 
                              // @ts-ignore */}
                            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                              <CategoryListItem
                                item={item}
                                languageCount={languageCount}
                                onEdit={onEditItem}
                                onDelete={onDeleteItem}
                                onEditText={onEditText}
                                actionsDisabled={unsavedChanges}
                              />
                            </div>
                          </li>
                        )}
                      </Draggable>
                    )
                })}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </div>
    </DragDropContext>
  )
}

export default CategoryList
