import React from 'react'
import AnalyticsChart from './AnalyticsChart'
import { useSelector } from 'react-redux'
import cloneDeep from 'lodash.clonedeep'
import { SMART_TAG_CLICKS_PER_CATEGORY_OPTIONS } from '../../utils/chartSettings'
import { combineBarGraphResults } from '../../utils/analyticsHelper'

const SmartTagClicksByCategoryChart: React.FC<ChartWrapperProps> = (props) => {
  const storeData = useSelector((state: RootStore) => state.analytics.smartTagClicksByCategory)
  const { results, compareResults } = storeData

  const newData = combineBarGraphResults(results, compareResults)

  const clonedData = cloneDeep(newData)
  const clonedOpts = cloneDeep(SMART_TAG_CLICKS_PER_CATEGORY_OPTIONS)

  return <AnalyticsChart {...props} series={clonedData} options={clonedOpts} />
}

export default SmartTagClicksByCategoryChart
