import React from 'react'
import { useTranslation } from 'react-i18next'
import { Typography, Tooltip, IconButton, makeStyles, Theme, createStyles } from '@material-ui/core'
import TranslationsCount from './TranslationsCount'
import TextPreview from './TextPreview'
import ImagePreviewDialog from './ImagePreviewDialog'
import Dialog from './Dialog'
import Icon from '../theme/Icons'
import { Colors } from '../theme/colors'
import { appStyles } from '../theme/styles'
import CapacityInfoPreview from './CapacityInfoPreview'
import TranslationsEditDialog from './TranslationsEditDialog'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ...appStyles,
    item: {
      margin: '20px 0',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'start',
    },
    itemDetails: {
      width: '60%',
    },
    categoryContainer: {
      backgroundColor: 'rgba(10, 10, 10, .1)',
      borderTop: `1px solid ${Colors.white}`,
      padding: '5px 10px',
    },
    categoryTagsContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
    },
    categoryActionButtons: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      margin: '10px 0',
    },
  })
)

interface Props {
  item: Category
  languageCount: number
  onEdit: (id: string | undefined) => void
  onDelete: (id: string | undefined, name?: string) => void
  onEditText: (context: 'description' | 'name', categoryId: string) => (texts: Translation[]) => void
  actionsDisabled?: boolean
}

const CategoryListItem: React.FC<Props> = ({
  item,
  languageCount,
  onEdit,
  onDelete,
  onEditText,
  actionsDisabled = false,
}) => {
  const { t } = useTranslation()
  const c = useStyles()

  if (!item?.id) return null

  const CapacityInfoDialog: React.FC<{ capacity?: CapacityInfo[] }> = ({ capacity }) => {
    if (!capacity) return null
    return (
      <Dialog
        triggerButtonProps={{
          text: t('capacity'),
          variant: 'outlined',
          color: 'default',
        }}
      >
        <CapacityInfoPreview capacity={capacity} />
      </Dialog>
    )
  }

  return (
    <div key={item.id}>
      <div className={c.categoryContainer}>
        <div className={c.item}>
          <div className={c.itemDetails}>
            <TranslationsEditDialog texts={item.name} onSave={onEditText('name', item.id)} title={t('name')}>
              <Typography variant='h5' className={c.isNormalWeight}>
                {item.name[0].text}
              </Typography>
              <TranslationsCount translations={item.name} limit={languageCount} hideOnZero />
            </TranslationsEditDialog>

            <TranslationsEditDialog
              texts={item.description}
              onSave={onEditText('description', item.id)}
              title={t('description')}
              isHtmlEditor
            >
              <TextPreview text={item.description[0].text} />
              <TranslationsCount translations={item.description} limit={languageCount} hideOnZero />
            </TranslationsEditDialog>
          </div>

          <div className={c.categoryTagsContainer}>
            <div className={c.categoryActionButtons}>
              <Tooltip title={`${t('edit')}`} placement='top'>
                <IconButton onClick={() => onEdit(item.id)} disabled={actionsDisabled}>
                  <Icon.Edit color={!actionsDisabled ? 'secondary' : 'disabled'} />
                </IconButton>
              </Tooltip>
              <Tooltip title={`${t('delete')}`} placement='top'>
                <IconButton onClick={() => onDelete(item.id, item.name[0].text)} disabled={actionsDisabled}>
                  <Icon.Delete color={!actionsDisabled ? 'error' : 'disabled'} />
                </IconButton>
              </Tooltip>
            </div>
            <div>
              {/* TODO: CAPACITY INFO DISABLED -> UNCOMMENT WHEN READY FOR LIVE */}
              {/* {item.type === 'meeting-room' && <CapacityInfoDialog capacity={item.capacity} />} */}
              <ImagePreviewDialog images={item.images} type='still-images' />
              <ImagePreviewDialog images={item.panoramaImages} type='panoramas' />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CategoryListItem
