export const Colors = {
  primary: '#67BD50',
  secondary: '#00BBBE',
  white: '#FFF',
  black: '#000',
  text: '#575756',
  gray: '#cccccc',
  grayLight: '#eaeaea',
  error: '#bd5151',
}
