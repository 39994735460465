import { takeLatest, all } from 'redux-saga/effects'
import API from '../services/api'

/* ------------- Types ------------- */

// import { UserTypes } from '../redux/UserRedux'
import { UserTypes } from '../redux/UserRedux'
import { PresentationsTypes } from '../redux/PresentationsRedux'
import { PresentationTypes } from '../redux/PresentationRedux'
import { AnalyticsTypes } from '../redux/AnalyticsRedux'
import { AdminToolsTypes } from '../redux/AdminToolsRedux'

/* ------------- Sagas ------------- */

import { login, getRole, logout, acceptTerms } from './UserSagas'
import {
  getPresentations,
  getPresentation,
  setPresentation,
  sendToVisualizerCms,
  downloadPresentation,
  restorePresentation,
  revertPresentation,
  deletePresentation,
} from './PresentationSagas'

import { searchAnalytics } from './AnalyticsSagas'
import {
  getEmbeddedCharts,
  createEmbeddedChartFolder,
  createEmbeddedChart,
  editEmbeddedChart,
  deleteEmbeddedChart,
  deleteEmbeddedChartFolder,
} from './AdminToolsSagas'

/* ------------- API ------------- */

// The API we use is only used from Sagas, so we create
// it here and pass along to the sagas which need it.
const api = API.create()

/* ------------- Connect Types To Sagas ------------- */

function* root() {
  yield all([
    takeLatest(UserTypes.LOGIN, login, api),
    takeLatest(UserTypes.GET_ROLE, getRole, api),
    takeLatest(UserTypes.LOGOUT, logout, api),
    takeLatest(UserTypes.ACCEPT_TERMS, acceptTerms, api),
    takeLatest(PresentationsTypes.GET_PRESENTATIONS, getPresentations, api),
    takeLatest(PresentationTypes.GET_PRESENTATION, getPresentation, api),
    takeLatest(PresentationTypes.SET_PRESENTATION, setPresentation, api),
    takeLatest(PresentationTypes.SEND_TO_VISUALIZER_CMS, sendToVisualizerCms, api),
    takeLatest(PresentationTypes.DOWNLOAD_PRESENTATION, downloadPresentation, api),
    takeLatest(PresentationTypes.RESTORE_PRESENTATION, restorePresentation, api),
    takeLatest(PresentationTypes.REVERT_PRESENTATION, revertPresentation, api),
    takeLatest(PresentationTypes.DELETE_PRESENTATION, deletePresentation, api),
    takeLatest(AnalyticsTypes.SEARCH_ANALYTICS, searchAnalytics, api),
    takeLatest(AdminToolsTypes.GET_EMBEDDED_CHARTS, getEmbeddedCharts, api),
    takeLatest(AdminToolsTypes.CREATE_EMBEDDED_CHART_FOLDER, createEmbeddedChartFolder, api),
    takeLatest(AdminToolsTypes.CREATE_EMBEDDED_CHART, createEmbeddedChart, api),
    takeLatest(AdminToolsTypes.EDIT_EMBEDDED_CHART, editEmbeddedChart, api),
    takeLatest(AdminToolsTypes.DELETE_EMBEDDED_CHART, deleteEmbeddedChart, api),
    takeLatest(AdminToolsTypes.DELETE_EMBEDDED_CHART_FOLDER, deleteEmbeddedChartFolder, api),
  ])
}

export default root
