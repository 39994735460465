import React from 'react'
import Button from '../Button'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { makeStyles, Theme, createStyles } from '@material-ui/core'
import { Phase } from '../../containers/AddCategory'
import AddNewDialog from '../Presentations/AddNewDialog'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actionButtonsContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: '30px 0',
    },
  })
)

interface Props {
  onSave: (node: HTMLElement) => void
  onCancel: () => void
  onNext: () => void
  onPrev: () => void
  curPhase: Phase
  disableNext: boolean
}

const ActionButtons: React.FC<Props> = ({ onSave, onCancel, onNext, onPrev, curPhase, disableNext }) => {
  const c = useStyles()
  const { t } = useTranslation()
  const { id } = useParams<{ id: string }>()

  return (
    <div className={c.actionButtonsContainer}>
      <Button text={t('cancel')} variant='outlined' onClick={onCancel} />

      <div>
        {/* Can't go back from start: */}
        {curPhase > 0 && <Button text={t('go_back')} onClick={onPrev} />}

        {/* Show SAVE button when at preview and NEXT button otherwise: */}
        {curPhase >= Phase.Preview ? (
          <AddNewDialog
            presentationId={id}
            triggerButtonProps={{
              text: t('save'),
              color: 'primary',
            }}
            onTriggerButtonClick={onSave}
            disableClose
          />
        ) : (
          <Button text={t('next')} color='primary' onClick={onNext} disabled={disableNext} />
        )}
      </div>
    </div>
  )
}

export default ActionButtons
