import React from 'react'
import ZoomInOutlinedIcon from '@material-ui/icons/ZoomInOutlined'
import Chart from 'react-apexcharts'
import styled from 'styled-components'

const MAIN_CHART_HEIGHT = 450

const ChartContainer = styled.div`
  .apexcharts-legend-text::first-letter {
    text-transform: uppercase;
  }
`

const AnalyticsChart: React.FC<AnalyticsChartProps> = ({ options, size, onSwap, series, type, title }) => {
  const combinedOptions = {
    ...options.common,
    ...options[size],
  }

  return (
    <ChartContainer>
      {size === 'small' && (
        <div className='header'>
          <span>{title}</span>
          <div className='icon-wrapper' onClick={onSwap}>
            <ZoomInOutlinedIcon />
          </div>
        </div>
      )}
      <Chart
        options={combinedOptions}
        series={series}
        type={type}
        width='100%'
        height={size === 'hero' ? MAIN_CHART_HEIGHT : 'auto'}
      />
    </ChartContainer>
  )
}

export default AnalyticsChart
