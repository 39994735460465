import apisauce from 'apisauce'

const API_URLS = {
  local: 'http://localhost:1337/',
  stage: 'https://visualizer-api.stage.geniem.io/',
  prod: 'https://visualizer-api.production.geniem.io/',
}

const MULTIPART_HEADERS = {
  'Content-Type': 'multipart/form-data',
  Accept: 'application/json',
}

let baseURL = API_URLS.prod
if (process.env.NODE_ENV === 'development') baseURL = API_URLS.local
if (window.location.hostname.includes('.stage.geniem.io')) baseURL = API_URLS.stage

const auth = (token: string) => ({
  headers: { Authorization: `Bearer ${token}` },
})

const create = () => {
  const api = apisauce.create({
    baseURL,
    timeout: 30000,
  })

  const login = (username: string, password: string) => {
    return api.post('auth/login', { email: username, password }, {})
  }

  const getRole = (token: string) => {
    return api.get('auth/role', {}, auth(token))
  }

  const logout = (token: string) => {
    return api.get('auth/logout', {}, auth(token))
  }

  const acceptTerms = (token: string) => {
    return api.post('auth/accept-terms', {}, auth(token))
  }

  const getPresentations = async (token: string) => {
    return await api.get('presentation', {}, auth(token))
  }

  const getPresentation = async (id: string, token: string) => {
    return await api.get(`presentation/${id}`, {}, auth(token))
  }

  const updatePresentation = async (
    id: string,
    presentation: any, // Todo: Type = PresentationFromApi?
    token: string
  ) => {
    return await api.put(`presentation/${id}`, presentation, auth(token))
  }

  const uploadImage = async (image: File, token: string) => {
    const form = new FormData()
    form.append('image', image)
    return await api.post(`asset`, form, {
      timeout: 0,
      headers: {
        ...auth(token).headers,
        ...MULTIPART_HEADERS,
      },
    })
  }

  const sendToVisualizerCms = async (id: string, termsAccepted: boolean, token: string) => {
    return await api.post(`presentation/${id}/send`, { termsAccepted }, auth(token))
  }

  const downloadPresentation = async (id: string, token: string) => {
    return await api.get(`presentation/${id}`, {}, auth(token))
  }

  const restorePresentation = async (id: string, token: string) => {
    return await api.delete(`presentation/${id}`, {}, auth(token))
  }

  const revertPresentation = async (id: string, token: string) => {
    return await api.post(`presentation/${id}/revert`, {}, auth(token))
  }

  const deletePresentation = async (id: string, token: string) => {
    return await api.post(`presentation/${id}/delete`, {}, auth(token))
  }

  type SearchAnalyticsOptions = {
    dateRange: DateRange
    comparisonRange: DateRange
    selectedProperties: string[]
  }

  const searchAnalytics = async (token: string, options: SearchAnalyticsOptions) => {
    const { dateRange, comparisonRange, selectedProperties } = options
    return await api.post('analytics/search', { dateRange, comparisonRange, selectedProperties }, auth(token))
  }

  const getEmbeddedCharts = async (token: string) => {
    return api.get('admin-tools/embedded-chart', {}, auth(token))
  }

  const getEmbeddedChartDataByUuid = async (uuid: string) => {
    return await api.get(`admin-tools/embedded-chart/${uuid}`)
  }

  const createEmbeddedChartFolder = async (token: string, folderName: string) => {
    return api.post('admin-tools/embedded-chart/folder', { folderName }, auth(token))
  }

  const createEmbeddedChart = async (file: File, name: string, folderUuid: string, token: string) => {
    const form = new FormData()
    form.append('file', file)
    form.append('name', name)
    form.append('folderUuid', folderUuid)
    return await api.post('admin-tools/embedded-chart', form, {
      timeout: 0,
      headers: {
        ...auth(token).headers,
        ...MULTIPART_HEADERS,
      },
    })
  }

  const editEmbeddedChart = async (file: File, name: string, folderUuid: string, chartUuid: string, token: string) => {
    const form = new FormData()
    form.append('file', file)
    form.append('name', name)
    form.append('folderUuid', folderUuid)
    form.append('chartUuid', chartUuid)
    return await api.put(`admin-tools/embedded-chart/${chartUuid}`, form, {
      timeout: 0,
      headers: {
        ...auth(token).headers,
        ...MULTIPART_HEADERS,
      },
    })
  }

  const deleteEmbeddedChart = async (chartUuid: string, token: string) => {
    return api.delete(`admin-tools/embedded-chart/${chartUuid}`, auth(token))
  }

  const deleteEmbeddedChartFolder = async (folderUuid: string, token: string) => {
    return api.delete(`admin-tools/embedded-chart/folder/${folderUuid}`, auth(token))
  }

  return {
    login,
    getRole,
    logout,
    acceptTerms,
    getPresentations,
    getPresentation,
    updatePresentation,
    uploadImage,
    sendToVisualizerCms,
    downloadPresentation,
    restorePresentation,
    revertPresentation,
    deletePresentation,
    searchAnalytics,
    createEmbeddedChart,
    getEmbeddedCharts,
    createEmbeddedChartFolder,
    getEmbeddedChartDataByUuid,
    editEmbeddedChart,
    deleteEmbeddedChart,
    deleteEmbeddedChartFolder,
  }
}

export default {
  create,
}
