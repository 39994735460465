import React, { useState, useEffect } from 'react'
import { Dispatch, AnyAction } from 'redux'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useParams, Redirect, useHistory } from 'react-router-dom'
import { Typography, makeStyles, Theme, createStyles } from '@material-ui/core'

import Layout from '../components/Layout'
import CategoryActions from '../redux/CategoryRedux'
import PresentationActions from '../redux/PresentationRedux'
import ActionButtons from '../components/Category/ActionButtons'
import { Colors } from '../theme/colors'
import BasicInformation from '../components/Category/BasicInformation'
import Preview from '../components/Category/Preview'
import Storage from '../utils/storage'

import CONSTANT from '../utils/constants'
import { validateTranslations, validateImages } from '../utils/validations'
import ImageUploadZone from '../components/ImageUploadZone'
import ProgressBar from '../components/Category/ProgressBar'
import Spinner from '../components/Spinner'
import HContainer from '../components/Layout/HContainer'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    phaseContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      border: `1px solid ${Colors.grayLight}`,
      borderRadius: '5px',
      margin: '0 0 30px 0',
      padding: '20px 30px',
      height: '60vh',
      minHeight: '600px',
      overflowY: 'auto',
    },
    contentContainer: {
      backgroundColor: '#fff',
      borderRadius: '1rem',
      padding: '1rem',
      marginTop: '1.5rem',
    },
  })
)

export enum Phase {
  BasicInfo,
  StillImages,
  PanoramaImages,
  Preview,
  Finished,
}

const CATEGORIES: CategoryType[] = ['meeting-room', 'room', 'restaurant', 'public-area']

const INITIAL_CAPACITY: CapacityInfo[] = [
  { name: 'theatre', value: undefined },
  { name: 'classroom', value: undefined },
  { name: 'boardroom', value: undefined },
  { name: 'u-table', value: undefined },
  { name: 'round-tables', value: undefined },
  { name: 'banquet', value: undefined },
  { name: 'coctail', value: undefined },
]

interface Props {
  loading: boolean
  presentation: FullPresentation | null
  categoryStore: EditableCategory | null
  getPresentation: (id: string) => AnyAction
  setPresentation: (presentation: FullPresentation) => AnyAction
  updateCategory: (category: EditableCategory) => AnyAction
  clearCategory: () => AnyAction
}

const AddNewCategory: React.FC<Props> = ({
  loading,
  presentation,
  categoryStore,
  getPresentation,
  setPresentation,
  updateCategory,
  clearCategory,
}) => {
  const c = useStyles()
  const history = useHistory()
  const { t } = useTranslation()
  const { id, category } = useParams<{ id: string; category: CategoryType }>()

  const [curPhase, setCurPhase] = useState(Phase.BasicInfo)
  const [prevPhase, setPrevPhase] = useState(Phase.BasicInfo)

  const [index, setIndex] = useState<number | undefined>()
  const [nameTexts, setNameTexts] = useState<Translation[]>([])
  const [descriptions, setDescriptions] = useState<Translation[]>([])
  const [capacity, setCapacity] = useState<CapacityInfo[]>(INITIAL_CAPACITY)

  const [images, setImages] = useState<FileAsset[]>([])
  const [panoramaImages, setPanoramaImages] = useState<FileAsset[]>([])

  const init = () => {
    setCurPhase(Phase.BasicInfo)
    setPrevPhase(Phase.BasicInfo)
  }

  // If category changed -> initialize (new category is being added):
  useEffect(() => {
    if (category && CATEGORIES.includes(category)) init()
    else history.push(`/presentations`)
  }, [category, history])

  // If categoryStore.init is true -> initialize (new category is being added):
  useEffect(() => {
    if (categoryStore?.init) init()
  }, [categoryStore])

  useEffect(() => {
    if (!presentation && id) getPresentation(id)

    // Get data from session storage (if available):
    const categoryDraft = Storage.sessionRead(Storage.KEYS.CATEGORY_DRAFT)
    if (categoryDraft) {
      setIndex(categoryDraft.index)
      setNameTexts(categoryDraft.name)
      setDescriptions(categoryDraft.description)
      setCapacity(categoryDraft.capacity || INITIAL_CAPACITY)
      // Todo: Set images if editing a draft
      // (How to handle since images are too bit to session storage?)
    }

    // Populate values from store (if editing existing category)
    // or else set empty values
    else if (categoryStore && presentation) {
      const selectedLanguages = presentation.supportedLanguages.filter((l) => l.isSelected).map((l) => l.code)

      setIndex(categoryStore?.index)

      setNameTexts(
        selectedLanguages.map((lang) => ({
          lang,
          text: categoryStore?.name?.find((t) => t.lang === lang)?.text || '',
        }))
      )

      setDescriptions(
        selectedLanguages.map((lang) => ({
          lang,
          text: categoryStore?.description?.find((t) => t.lang === lang)?.text || '',
        }))
      )

      setCapacity(categoryStore?.capacity || INITIAL_CAPACITY)

      if (categoryStore.images) setImages(categoryStore.images)

      if (categoryStore.panoramaImages) setPanoramaImages(categoryStore.panoramaImages)
    }
  }, [category, categoryStore, getPresentation, id, presentation])

  if (!category) {
    return <Redirect to={`/presentations/${id}`} />
  }

  if (!presentation) return null

  const getImageCount = (licenceImagesCount: number, categoryId?: string) => {
    if (categoryId) {
      // Editing existing category that might have some images already
      const categoryInApi = presentation.categories.find((category) => category.id === categoryId)
      const currentImageCount = categoryInApi?.images.length ?? 0
      const currentPanoramaCount = categoryInApi?.panoramaImages.length ?? 0

      return licenceImagesCount + (images.length - currentImageCount) + (panoramaImages.length - currentPanoramaCount)
    }
    return licenceImagesCount + images.length + panoramaImages.length
  }

  const basicInformationProps = {
    type: category,
    nameTexts,
    setNameTexts,
    descriptions,
    setDescriptions,
    capacity,
    setCapacity,
  }

  const imageProps = {
    imageLimit: presentation.licenseImages.limit,
    imageCount: getImageCount(presentation.licenseImages.count, categoryStore?.id),
  }

  const stillImageProps = {
    ...imageProps,
    minWidth: CONSTANT.STILL_IMAGE_MIN_WIDTH,
    maxWidth: CONSTANT.STILL_IMAGE_MAX_WIDTH,
    recommendedWidth: CONSTANT.STILL_IMAGE_RECOMMENDED_WIDTH,
    images,
    setImages,
  }

  const panoramaImageProps = {
    ...imageProps,
    minWidth: CONSTANT.PANORAMA_IMAGE_MIN_WIDTH,
    maxWidth: CONSTANT.PANORAMA_IMAGE_MAX_WIDTH,
    imageRatio: CONSTANT.PANORAMA_IMAGE_RATIO,
    images: panoramaImages,
    setImages: setPanoramaImages,
  }

  const handleUpdateCategory = () => {
    const categoryDraft = {
      init: false,
      type: category,
      index,
      name: nameTexts,
      description: descriptions,
      capacity,
      images,
      panoramaImages,
    }

    // Save draft to redux store...
    updateCategory(categoryDraft)

    // ...and also (translations) in session storage
    Storage.sessionWrite(Storage.KEYS.CATEGORY_DRAFT, {
      type: category,
      index,
      name: nameTexts,
      description: descriptions,
      capacity,
      // Note: Images are too big to session storage!
    })
  }

  // Save category to api:
  const saveCategory = () => {
    if (presentation) {
      const categoryCount = presentation.categories.length || 0
      const indexValue = index !== undefined ? index : categoryCount
      setPresentation({
        ...presentation,
        categories: [
          // If categoryStore has id, it means existing category is being edited.
          // -> The category is filtered from the list since it's replaced by a new one.
          // Also, category is placed in corresponding position based on its index value:
          ...presentation.categories.filter((c) => c.id !== categoryStore?.id && c.index <= indexValue),
          {
            id: categoryStore?.id,
            index: indexValue,
            type: category,
            name: nameTexts,
            description: descriptions,
            capacity: category === 'meeting-room' ? capacity : undefined,
            images: images,
            panoramaImages: panoramaImages,
          },
          ...presentation.categories.filter((c) => c.id !== categoryStore?.id && c.index > indexValue),
        ],
      })
    }
    clearCategory()
    setImages([])
    setPanoramaImages([])
    Storage.sessionWrite(Storage.KEYS.CATEGORY_DRAFT, null)
  }

  // Cancel process
  const handleCancel = () => {
    clearCategory()
    Storage.sessionWrite(Storage.KEYS.CATEGORY_DRAFT, null)
    history.push(`/presentations/${id}/preview`)
  }

  const goToNextPhase = (update = true) => {
    if (curPhase < Phase.Finished) {
      if (update) handleUpdateCategory()
      setPrevPhase(curPhase)
      setCurPhase(curPhase + 1)
    }
  }

  const goToPrevPhase = (update = true) => {
    if (curPhase > 0) {
      if (update) handleUpdateCategory()
      setPrevPhase(curPhase)
      setCurPhase(curPhase - 1)
    }
  }

  const skipPhase = () => {
    if (prevPhase <= curPhase) goToNextPhase(false)
    else goToPrevPhase(false)
    return null
  }

  const phases = [t('basic_information'), t('still_images'), t('panorama_images'), `${t('preview')} & ${t('save')}`]

  const currentPhase = () => {
    switch (curPhase) {
      case Phase.BasicInfo:
        return <BasicInformation {...basicInformationProps} />
      case Phase.StillImages:
        return <ImageUploadZone {...stillImageProps} />
      case Phase.PanoramaImages:
        return <ImageUploadZone {...panoramaImageProps} />
      case Phase.Preview:
        return <Preview />
      case Phase.Finished:
        return <Redirect to={`/presentations/${id}/preview`} />
      // Just an example how to use skipPhase (if ever needed anymore...)
      // case Phase.SomePhase:
      //   if (category !== 'someCategoryType') return skipPhase()
      //   return <Component... />
      default:
        return null
    }
  }

  const disableNext = () => {
    switch (curPhase) {
      case Phase.BasicInfo:
      // return (
      //   !validateTranslations(nameTexts) ||
      //   !validateTranslations(descriptions)
      // )
      case Phase.StillImages:
      // return !validateImages(images)
      case Phase.PanoramaImages:
      case Phase.Preview:
      default:
        return false
    }
  }

  return (
    <HContainer>
      <div className={c.contentContainer}>
        {loading && <Spinner isFullScreen progressText={t('saving')} />}
        <Typography variant='h3'>{`${t('add-new-areas--prefix')} ${t(category.replace('-', '_'))}`}</Typography>
        <ProgressBar phase={curPhase} phases={phases} />

        <div className={c.phaseContainer}>{currentPhase()}</div>

        <ActionButtons
          onSave={saveCategory}
          onCancel={handleCancel}
          onNext={goToNextPhase}
          onPrev={goToPrevPhase}
          curPhase={curPhase}
          disableNext={disableNext()}
        />
      </div>
    </HContainer>
  )
}

const mapStateToProps = (state: RootStore) => {
  return {
    loading: state.presentation.updating,
    presentation: state.presentation.content,
    categoryStore: state.category,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    updateCategory: (category: EditableCategory) => dispatch(CategoryActions.updateCategory(category)),
    clearCategory: () => dispatch(CategoryActions.clearCategory()),
    getPresentation: (id: string) => dispatch(PresentationActions.getPresentation(id)),
    setPresentation: (presentation: FullPresentation) => dispatch(PresentationActions.setPresentation(presentation)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddNewCategory)
