import React, { ChangeEvent, Dispatch, SetStateAction } from 'react'
import classNames from 'classnames'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Colors } from '../theme/colors'
import { Typography } from '@material-ui/core'
import HelpTip from './HelpTip'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      margin: '15px 0',
    },
    label: {
      display: 'block',
      marginBottom: '5px',
      textIndent: '15px',
      fontSize: '20px',
      lineHeight: '29px',
    },
    title: {},
    labelWhite: {
      color: '#fff',
    },
    infoText: {
      marginBottom: '12px',
    },
    input: {
      display: 'block',
      width: '100%',
      padding: '10px 10px',
      backgroundColor: Colors.grayLight,
      border: `1px solid ${Colors.gray}`,
      borderRadius: '5px',
      fontSize: '20px',

      '&:focus': {
        outline: `2px solid ${Colors.primary}`,
        outlineStyle: 'auto',
      },
      '&.highlight': {
        outline: `2px solid ${Colors.error}`,
        outlineStyle: 'auto',
      },
    },
  })
)

interface Props extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  label?: string
  labelSize?: 'h4' | 'h5' | 'h6'
  infoText?: string
  setValue?: Dispatch<SetStateAction<string>>
  highlight?: boolean
  helpTipKey?: string
  labelColor?: 'white'
}

const Input: React.FC<Props> = ({
  label,
  labelSize,
  infoText,
  setValue,
  highlight,
  helpTipKey,
  labelColor,
  ...props
}) => {
  const c = useStyles()

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (setValue) setValue(e.currentTarget.value)
  }

  const labelClasses = classNames(c.title, {
    [c.labelWhite]: labelColor === 'white',
  })

  return (
    <div className={c.container}>
      <label>
        <Typography variant={labelSize} className={labelClasses}>
          {label}
          {helpTipKey && <HelpTip helpTextKey={helpTipKey} />}
        </Typography>
        <Typography variant={'body2'} className={c.infoText}>
          {infoText}
        </Typography>
      </label>
      <input className={`${c.input} ${highlight ? 'highlight' : ''}`} onChange={handleChange} {...props} />
    </div>
  )
}

export default Input
